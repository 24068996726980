@import "../../../../../../../../_variables";

.AmountButtonsInputContainer{
  display: flex;
  flex-direction: column;
  padding: 20px 0 15px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);


  >div{
    display:inline-flex;
    justify-content: space-between;
    align-items: center;

    label{
      flex:0.85;
    }

    >div{
      width: 220px;
    }
  }

  #info{
    margin-top:10px;
    font-weight:500;
    color:@grey;
    font-size:0.72rem;
  }

  &.roomsInfo{
    padding: 10px 0 10px 0;
    border-bottom: none;

    label{
      font-size: @14px;
      color:@grey6;
      font-weight: 700;
    }
  }
}
