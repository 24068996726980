@import "../../../../../../../../../../../../../_variables";

.OverviewCalendar {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    display: none;
  }

  p {
    font-size: 12px;
  }

  label {
    font-size: @14px;
    font-weight: 700;
  }

  .OverviewCalendar__info {
    margin: 20px 0;
    display: inline-flex;
    align-items: flex-start;
    width: 250px;

    svg {
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }

    time {
      font-weight: bold;
    }


  }

  .OverviewCalendar__Datepicker {
    width: fit-content;

    .react-datepicker {
      border: none;

      .react-datepicker__month-container {
        border: none;
      }

      .react-datepicker__header {
        background-color: @grey2;
        border: none;
        padding: 15px 25px;

        .react-datepicker__day-names {
          margin-top: 10px;

          .react-datepicker__day-name {
            width: 36px;
            height: 30px;
            margin: 0px;
          }
        }
      }

      .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
        margin-top: 8px;
      }

      .react-datepicker__navigation--previous {
        margin-left: 12px;
      }

      .react-datepicker__navigation--next {
        margin-right: 12px;
      }

      .react-datepicker__current-month {
        text-transform: capitalize;
        font-size: @14px;
        color: @grey6;

      }

      .react-datepicker__month {
        margin-top: 15px;
      }

      .react-datepicker__week {
        overflow: hidden;

        &:hover {
          cursor: default !important;
        }
      }

      .react-datepicker__day {
        background-color: rgba(87, 180, 242, 0.2);
        color: @grey5;
        margin: 0px;
        width: 36px;
        height: 30px;

        &:hover {
          background-color: rgba(87, 180, 242, 0.2);
          cursor: default;
          border-radius: 0;
        }
      }

      .react-datepicker__day--disabled {
        color: @grey5;
        background-color: rgba(254, 210, 210, 0.3);

        &:hover {
          background-color: rgba(254, 210, 210, 0.3);
          cursor: default;
          border-radius: 0;
        }
      }

      .react-datepicker__day--keyboard-selected {
        border-radius: 0;
      }

      .react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
      }

      .passed_day {
        background-color: rgba(254, 210, 210, 0.3);

        &:hover {
          background-color: rgba(254, 210, 210, 0.3);
        }
      }

    }

    .OverviewCalendar__DatePicker__info {
      width: 100%;
      display: inline-flex;
      justify-content: space-around;
      margin-top: 30px;
      margin-bottom: 40px;

      > div {
        display: inline-flex;
        align-items: center;

        p {
          font-size: @12px;
          color: black;
        }
      }


      #OverviewCalendar__DatePicker__info--available {
        > div {
          background-color: #D2EBFE;
        }
      }

      #OverviewCalendar__DatePicker__info--unavailable {
        > div {
          background-color: #FED2D2;
        }
      }

      .OverviewCalendar__DatePicker__info--icon {
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }


}
