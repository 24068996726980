@import "../../../../../../_variables";

.CancellationTimeline {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  .CancellationTimeline__header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    color: #284F83;

    h5 {
      margin: 0;
      color: #284F83;
    }

    svg {
      fill: #284F83;
    }
  }

  .CancellationTimeline__content {
    display: flex;
    gap: 1.5rem;
    width: 100%;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
    }

    .CancellationTimeline__content__timeline {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: space-between;

      .timeline__top-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 0 2.5rem;

        @media screen and (max-width: @mobileWidth) {
          padding: 0 1rem;
        }
      }

      .timeline-identifier {
        display: flex;
        align-items: center;
        gap: 1rem;

        .timeline-identifier__number {
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          align-items: center;
          justify-content: center;
          background: #284F83;
          color: white;
          border-radius: 100%;

          span {
            font-weight: 700;
            font-size: 0.875rem;
          }
        }

        svg.separator {
          display: flex;
          height: 0.5rem;
          width: 100%;
          border-radius: 0.5rem;
          flex: 1;
        }
      }

      .timeline__bottom-section {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: flex-start;

        .timeline__info {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          flex: 1;
          justify-content: space-between;
          gap: 0.5rem;

          span {
            color: #434A5F;
            text-align: center;
            font-size: 0.75rem;
            font-weight: 500;
          }

          p {
            color: #284F83;
            font-size: 0.75rem;
            font-weight: 700;
          }
        }
      }
    }

    .CancellationTimeline__content__warning {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: fit-content;
      height: 100%;
      padding: 0.5rem;
      border-radius: 0.25rem;
      background-color: rgba(40, 79, 131, 0.1);

      .warning__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .warning__title {
          color: #434A5F;
          text-align: center;
          font-size: 0.75rem;
          font-weight: 500;
        }
      }

      .warning__value {
        color: #284F83;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
      }
    }
  }
}
