@import "../../../../../../../../../../_variables";

.RoomDetailsHeader{
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px dashed @grey3;
  margin-bottom: 30px;

  .title{
    border-bottom: none !important;
  }

  .RoomDetailsHeader__center{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 5px;
    padding-right: 5px;

    >:first-child{
      display: inline-flex;
      flex: 1;
      >label{
        min-width: 40%;
        font-size: @18px;
        padding: 10px;
      }

      span{
        margin: 0 40px 0 80px;
        min-width: 100px;
        height: 100px;
        display: block;
        background-size: cover;
        background-position: center;
        border-radius: 6px;
      }
    }

    .CloneInformation{
      width: 30%;
      .Select{
        width: 100%;
      }
    }
  }

  .StepperBar{
    margin:30px 0px;
    width: 80%;
  }

  .CloneInformation{
    display: flex;
    flex-direction: column;
  }
}
