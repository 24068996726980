@import "../../../../../../../../../../../_variables";

  .PropertyDetails1{
  width: 100%;

  .PropertyDetails1__regions{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    overflow-y: scroll;

    .PropertyDetails1__regions-region{
      width: 48%;
      height: 80px;
      margin-right: 2%;
      margin-bottom:1%;
      border:1px solid @greyMiddle;
      display: inline-flex;
      align-items: center;
      padding-left:20px;
      background-color: white;
      border-radius: 8px;

      &:hover {
        background-color: @blueSuperSoft;
      }

      &.active{
        background-color:@blueSoft;
      }


      &:not(.active) {
        cursor: pointer;
      }

      .PropertyDetails1__regions-region__icon{
        width:48px;
        height:48px;
        border-radius: 10px;
        background-size: cover;
        background-position: 50%;
        margin-right: 16px;
      }

      p{
        font-size:1rem;
        margin-left:10px;
        font-weight:600;
      }
    }
  }

}
