@import "../../../../../../../../../_variables";

.Photographs{
  overflow-y: visible;
  padding-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .scrollable{
  }

  .title{
    width: 100%;
  }

  .Photographs__header{
    display:inline-flex !important;
    width:100%;

    div{
      flex:0.35;

      &:first-child{
        flex:1;


      }

      .Photographs__header-choose{
        flex:1;
      }


    }
  }

  .Photographs__header-choose {
    .ChooseOnePhoto {
      width: 100%;
      height: fit-content;

      > .ChooseOnePhoto__dropzone {
        > button {
          width: 100%;
          height: 90px;
          flex-direction: row;

          > label.ChooseOnePhoto__change-image__title {
            flex: 0.66;
            margin-bottom: 0;
          }

          > div.ChooseOnePhoto__change-image__placeholder {
            flex-direction: row;
          }
        }
      }

    }
  }

  .Photographs__content{
    flex:1;
    display: inline-flex !important;
    flex-wrap: wrap;
    --gap: 25px;
    margin: 0 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap) * 2);

    .ChooseImages{
      width: calc(100% - var(--gap) * 2);
      margin-left: var(--gap);
      margin-top:var(--gap);
      max-width:700px;
    }
  }

  .ProgressBarComponent{
    margin: 20px 0;
  }
}
