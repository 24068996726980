@import "../../../../../../../../../../../_variables";

.PropertyDetails3{

  form{
    .scrollable {
      > div {
        width: 90%;
      }
    }

    .input_container_double{
      width: 100%;

      label{
        min-width: 240px;
      }
    }

    #distances{
      label{
        width: 220px;
      }
    }


    .ResidenceInfo, .ApartmentRoomsInfo{
      .input_container_double{
        width: 100%;
      }
    }

    .input_container {
      .no_margin {
        margin-bottom: 0;
      }

      p{
        margin-top: 0;
        font-size: @12px;
      }
    }
  }

}
