@import "../../../../../../../../_variables";

.Buttons{
  width: calc(100vw - 450px);
  max-width: 650px;
  display: inline-flex;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  padding: 20px 10px 20px 0;
  background-color: @background;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    max-width: unset;
    min-width: unset;
    left: 10px;
  }

  button{
    flex: 1;
    font-size:0.85rem;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding:0px;

    &:not(:first-child){
      margin-left: 25px;
    }
  }
}
