@import "../../_variables";

.MainNavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 60px;
  max-height: 60px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  transition: background-color 0.8s, border-bottom 0.8s, min-height 0.5s, color 0.5s;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #DFE3E6;
  font-size:0.9rem;
  font-weight:600;
  color:@grey;

  @media screen and (max-width: @mobileWidth) {
    max-width: 100vw!important;
  }

  &.hide-border {
    border-bottom: unset;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .language-dropdown_Container{
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }

    .navbar__container {
      display: inline-flex;
      align-items: center;
      height:100%;
    }

    .navbar__title-container {
      width:45%;
      color:black;

      .navbar__title {
        display: flex;
        align-items: center;
        height: 35px;

        > p {
          margin-left:10px;
          font-size:@12px;
          font-weight: 700;
          color: #5A6872;
          line-height: 20px;
        }

        > img {
          width: 65px;
          @media screen and (max-width: @mobileWidth) {
            width: 55px;
          }
        }
      }
    }



    .navbar__aux{
      font-weight:400;
      font-size:@12px;
      width: fit-content;


      > div {
        margin-left: 10px;
        min-width: 25px;

        &.navbar__nav{
          font-weight:400;
          width:fit-content;
          font-size:@12px;

          @media screen and (max-width: 768px) {
            display: none;
          }

          a, .nav_link {
            color:@grey;
            width:fit-content;
            padding: 0 12px;
            text-align: center;
          }

          .nav_link {
            &.is-active {
              color: @blue;
            }
          }

          a:last-child {
            padding-right: 20px;
          }

          #feedback{
            svg{
              margin-right: 2px;
              width: 13px;
              height: 14px;
              margin-bottom: -3px;
              stroke-width: 0.5;
            }
          }
        }

        &.language{
          display:inline-flex;
          justify-content:space-between;
          min-width:50px;
          font-weight:400;
          margin-right:20px;
          height: 60px;

          p{
            padding-top:3px;
            border-bottom: 3px transparent solid;
            margin-top: auto;
            padding-bottom: 15px;
            font-size: 13px;
            cursor: pointer;

            &.active{
              border-bottom-color: @blue;
              color: #202020;
            }
          }
        }

        &.notifications {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          width:30px;
          margin-right: 8px;
          cursor: pointer;

          svg{
            width: 22px;
            height: 22px;
            color:@grey;
          }
        }

        .new-features {
          font-size: @14px;
          color: @blue2;
          line-height: 20px;
          font-weight: 400;
          cursor: pointer;
        }


        &.login_button {
          button {
            justify-content: center;
            @media screen and (max-width: @mobileWidth) {
              width: 75px;
            }
          }
        }
      }



      a{
        &.active{
          .avatar{
            border:4px solid @blue;
          }
        }
      }
    }
  }




  a, .nav_link {
    cursor: pointer;
    &:link,&:hover, &:focus, &:active, &:visited, &link {
      text-decoration: none;
      color: @grey;
      font-weight:400;
    }
  }

  .dropdown-menu {
    display: flex;
    align-items: center;
    .logged_user{
      display:inline-flex;
      align-items: center;
      min-width: fit-content;

      *{
        display:inline-flex;
        align-items: center;
        margin-left: 5px;
      }

      .avatar {
        min-width: 40px;
        height: 40px;
        background-size: cover;
        border-radius:50%;
        @media screen and (max-width: @mobileWidth) {
          background-image: url(images/menuIconDarkGrey.svg) !important;
          min-width: 18px;
          background-size: cover;
          background-position: center;
          border-radius: 0;
          height: 10px;
          border: none!important;
        }
      }
    }
  }

}


.NavBar__menu-container {
  display: flex;
  flex-direction: column;
  color: @grey5;
  height: 100%;
  background-image: url("../../components/MainNavBar/images/bg_nav_menu.svg");
  background-position-x: 144px;
  background-position-y: -22px;
  background-repeat: no-repeat;
  @media screen and (max-width: @mobileWidth) {
    background-image: none;
  }

  .NavBar__menu-body__photo{
    width: 90%;
    justify-content: space-between;
    align-items: center;
    display: none;
    margin: 1rem 5%;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }

    .NavBar__menu-body__photo-avatar{
      width: 40px;
      height: 40px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: @grey2;
      border-radius: 50%;
    }

    .DropdownInput{
      @media screen and (max-width: @mobileWidth) {
        flex: none;
      }
    }
  }

  &.desktop-menu {
    width: 300px;
    @media screen and (max-width: @mobileWidth) {
      width: 100vw;
    }
  }

  .NavBar__menu-header {
    height: 65px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;

    > a > img {
      height: 34px;
    }

    .NavBar__menu-close {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: @grey2;
      width: 40px;
      height: 40px;
      border-radius: 20px;

      i {
        color: @blue2;
        font-size: 20px;
      }
    }

  }

  .NavBar__menu-body {
    flex-grow: 1;
    padding: 0px 16px 0px 16px;

    @media screen and (max-width: @mobileWidth) {
      color: @textSecondary;
      font-weight: 400;
      font-size: @14px;
    }

    &.desktop{
      @media screen and (max-width: @mobileWidth) {
       display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: @mobileWidth) {
        display: block;
      }

      .isMyAccount a {
        font-weight: 700;
        color: #5A6872;
      }

      p {
        font-weight: 700;
        font-size: @14px;
        line-height: 20px;
        margin-bottom: 22px;
        color: #5A6872;

        .isMyAccount {
          font-weight: 200;
          font-size: @14px;
          line-height: 20px;
          color: @textSecondary;
        }
      }

      .active{
        &:before {
          @media screen and (max-width: @mobileWidth) {
            content: " ";
            width: 3px;
            height: 29px;
            background-color: @blue;
            display: block;
            position: absolute;
            left: 0;
            transform: translateY(-3.5px);
          }
        }
      }
    }

    .menu-language {
      margin: 30px 0 35px 0;
      display: flex;
      align-items: center;

      .lang-sep {
        width: 2px;
        height: 18px;
        margin: 0 15px;
        background-color: @grey5;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      border: 0.2px solid #000000;
      opacity: 0.1;
      margin: 5px 0;

    }
    .logout-button {
      font-size: @14px;
      font-weight: 500;
      color: #FF0000;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 22px 0;
      >img {
        margin-right: 9px;
        color:#FF0000;
      }
    }

    p.active {
      color: @blue2;
      font-weight: 700;
    }

    >a:first-child {
      margin-top: 12px;
    }


    a {
      font-size: @14px;
      font-weight: 600;
      color: @grey4;
      display: block;
      width: 100%;
      line-height: 18px;
      text-align: left;
      text-decoration: none;
      padding: 0.75rem 0;

      &.active {
        color: @blue2;
      }
    }
  }

  .NavBar__menu-footer {
    text-align: right;
    padding: 10px 25px;
  }
}

.notifications-dropdown {
  overflow: hidden;
  padding: 32px 0 12px 0 !important;

  @media screen and (max-width: @mobileWidth) {
    width: 100%;
    margin-top: 60px;
    border-radius: 0!important;
    height: 100%;
    transform: none!important;
  }

  .clear-button {
    display: flex;
    border-radius: 3px;
    text-align: center;
    font-size: 0.688rem;
    cursor: pointer;
    background: #FFFFFF;
    padding: 12px 19px 0 29px;
  }
}

.Notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 19px 0 13px;
  height: fit-content;
  width: 320px;
  min-height: 70px;
  background: #FFFFFF;
  border-radius: 8px;
  max-height: ~"min(calc(100vh - 170px), 700px)";
  overflow-y: scroll;
  @media screen and (max-width: @mobileWidth) {
    width: 100%;
    min-width: 100%;
  }


  >div {
    margin-bottom: 20px;
    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      min-width: 100%;
    }
  }

  .no-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    .bell-wrapper {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      background-color: @greySoft;
      filter: grayscale(1);
    }
    .text {
      display: flex;
      flex-direction: column;
      >span:first-child {
        font-size: @14px;
        color: @grey6;
        margin-bottom: 8px;
      }
      >span:last-child {
        font-size: @12px;
        color:@grey4;
      }
    }
  }

}

.notifications-bell {
  display: flex;
  margin-left: 10px;
  .notifications-number {
    position: absolute;
    left: 20px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    color:@grey1;
    background-color: @blue3;
    font-weight: 700;
    font-size: 8px;
    line-height: 20px;
  }
}


.nav-language {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  color: @grey5;


  >div:not(:last-child) {
    margin-bottom: 26px;
  }
  >div {
    display: flex;
    align-items: center;
    cursor: pointer;

    .selected-bar {
      height: 29px;
      width: 3px;
      border-radius: 100px;
      &.active {
        background-color: @blue2;
      }
    }

    .flag {
      width: 17px;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 23px;
    }
    .country-name{
      margin-left: 16px;
      font-size: @14px;
      color: @grey5;

      &.active {
        font-weight: 700;
        color: @blue2;
      }
      &:hover {
        color: @blue2;
      }
    }
  }

}


.VideoDialog {
  .MuiPaper-root {
    max-width: 970px;
    height: fit-content;

    iframe {
      width: 90vw;
      max-width: 970px;
    }

    @media screen and (max-width: @mobileWidth) {
      margin: 0;
      border-radius: 0;

      iframe {
        width: 100vw!important;
        height: 70vw!important;
      }
    }
  }
}