@import "../../_variables.less";

.tooltip__wrapper {
  display: flex;
  position: relative;

  .tooltip-tip {
    position: absolute;
    border-radius: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    color: white;
    background: #434a5f;
    font-size: 0.75rem;
    font-weight: 400;
    width: 250px;
    z-index: 9000;

    &::before {
      display: block;
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: calc(6px * -1);
    }
  }

  .top {
    /* top: calc(55px * -1); */
    bottom: calc(100% + 0.5rem);

    &::before {
      top: 100%;
      border-top-color: #434a5f;
    }
  }

  .right {
    left: calc(100% + 5px);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: calc(5px * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: #434a5f;
    }
  }

  .bottom {
    bottom: calc(45px * -1);

    &::before {
      bottom: 100%;
      border-bottom-color: #434a5f;
    }
  }

  .left {
    left: auto;
    right: calc(100% + 5px);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: auto;
      right: calc(5px * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: #434a5f;
    }
  }
}
