@import "../../_variables";

.FiltersContainer__Container {

  @media screen and (max-width: @mobileWidth) {
    overflow: scroll;
    flex: 1;
    height: 45px;
    position: relative;
    box-shadow: 0 2px 5px rgba(240, 243, 246, 0.25);
    white-space: nowrap;
    margin-left: -5%;
    margin-right: -5%;
    background-color: @white;
  }

  .FiltersContainer {
    display: flex;
    height: fit-content;
    
    /* @media screen and (max-width: 1024px) {
      span {
        display: none;
      }
    } */

    @media screen and (max-width: @mobileWidth) {
      display: flex;
      position: absolute;
    }

    &.horizontal {
      flex-direction: column;
      width: 15rem;
      background: @white;
      border-radius: 8px;

      @media screen and (max-width: 1024px) {
        width: 100%;

        span {
          display: none;
        }
      }

      > a {
        height: 56px;
        color: @grey6;

        &.active {
          border-bottom: none;
        }
      }

      > a:not(:last-child) {
        margin-right: 0;
      }
    }

    > a {
      display: flex;
      gap: 1rem;
      text-decoration: none;
      align-items: center;
      font-size: 0.875rem;
      color: @charcoal;
      font-weight: 500;

      @media screen and (max-width: @mobileWidth) {
        white-space: nowrap;
        height: 45px;
        padding: 0 10px;
        flex-shrink: 0;
        font-size: @12px;
      }

      &:hover {
        color: @dodger-blue;

        svg {
          fill: @dodger-blue;
        }
      }

      svg {
        fill: @charcoal;
      }

      svg.active {
        fill: @dodger-blue;
      }

      &.active {
        color: @dodger-blue;
        font-weight: 700;
        border-bottom: solid 2px #1D9AFA;
      }
    }


    > a:not(:last-child) {
      margin-right: 24px;
      @media screen and (max-width: @mobileWidth) {
        margin-right: 0;
      }
    }

    .active {
      color: @blue2;

      @media screen and (max-width: @mobileWidth) {
        border-bottom: 4px solid @blue2;
      }
    }

    .no-border {
      border-bottom: unset;
    }
  }
}