@import "../../../../_variables";

.PotentialTenantContainer {
  position: relative;

  @media screen and (max-width: @mobileWidth) {
    width: 97% !important;
    left: 1.5%;
    box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);
  }

  &.ListItem {
    @media screen and (max-width: @mobileWidth) {
      height: unset;
    }
  }

  .PotentialTenant__label {
    display: none;

    @media screen and (max-width: @mobileWidth) {
      position: absolute;
      display: flex;
      height: 20px;
      left: 20px;
      font-size: @12px;
      color: @grey2 !important;
      background: @green;
      border-radius: 100px;
      padding: 0 15px;
      text-align: center;
      top: 15px;
    }
  }

  .ListItem__content {
    padding: 0 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      padding: 1rem 1.5rem;
      gap: 0;
    }

    .TenantInfo {
      @media screen and (max-width: @mobileWidth) {
        display: flex;
        flex-direction: row;
        min-width: 100%;
        max-width: 100%;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #DFE3E6;
      }

      .TenantName {
        @media screen and (max-width: @mobileWidth) {
          color: @grey6;
        }
      }

      .tenant-details_Container {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }


      .RequestDates {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      .small {
        &.mobile {
          display: none;
          @media screen and (max-width: @mobileWidth) {
            display: flex;
          }
        }

        @media screen and (max-width: @mobileWidth) {
          color: @grey5;
          font-size: @12px;
          font-weight: 700;
        }

        span {
          @media screen and (max-width: @mobileWidth) {
            color: @grey5;
            font-size: @12px;
            font-weight: 400;
          }
        }
      }

      .TenantPhoto {
        @media screen and (max-width: @mobileWidth) {
          margin-right: unset !important;
        }
      }
    }
  }

  /* &.potentialTenant__noProperties {
    background-color: #fbfbfb !important;
    opacity: 0.9 !important;
  } */

  .PotentialTenantsDetails_Container-mobile {
    display: none;
    @media screen and (max-width: @mobileWidth) {
      display: block;
    }
  }

  .PotentialTenantsDetails_Container {
    display: flex;
    width: 53%;
    justify-content: space-between;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .PotentialTenantsDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80px;
    gap: 0.25rem;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: unset;
      justify-content: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid #DFE3E6;
      gap: 1rem;
    }

    > div {
      display: inline-flex;
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        /* margin: .2rem; */
      }

      .spaced-icon {
            &.cityIcon{
              width: 15px;
              margin-right: 15px;
            }
          @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      .small {
        gap: 0.25rem;
        
        @media screen and (max-width: @mobileWidth) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 0.875rem;
          color: #434A5F;
          line-height: 20px;
        }

        &.mobile {
          display: none;
          @media screen and (max-width: @mobileWidth) {
            display: flex;
          }
        }

        span {
          @media screen and (max-width: @mobileWidth) {
            font-weight: 500;
            font-size: 0.875rem;
            color: #5A6872;
          }
        }
      }
    }
  }

  .PotentialTenantsBudget {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      padding: 0.5rem 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #DFE3E6;
      gap: 1rem;
    }

    p {
      color: #434A5F;
      font-size: 0.875rem;
      font-weight: 700;
    }

    h4 {
      color: #57B4F2;
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  .MoveInInfo {
    gap: 0.25rem;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      min-height: unset;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #DFE3E6;
      padding: 0.5rem 0;
      gap: 1rem;
    }

    > div {
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        /* margin: .2rem; */
      }

      .spaced-icon {
        max-width: 10.67px;
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      img {
        @media screen and (max-width: @mobileWidth) {
          max-width: 10.67px;
        }
      }
    }

    .small {
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 20px;
        color: #434A5F;
      }

      span {
        @media screen and (max-width: @mobileWidth) {
          font-weight: 500;
          font-size: 0.875rem;
          color: #5A6872;
        }
      }
    }
  }

  .Actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      padding-top: 0.5rem;
      align-items: center;
    }

    a {
      text-decoration: none;
    }

    .btn {
      width: 100%;
      text-align: center;
    }

    .Actions__buttons {
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        flex-direction: row-reverse;
      }
    }
  }
}

