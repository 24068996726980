@import "../../_variables";

.PriceRange {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: 0 10px;

  .rc-slider {
    padding: 0;

    .rc-slider-handle {
      border-radius: 2px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3) !important;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      margin-top: -10px;

      &.rc-slider-handle-1 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.rc-slider-handle-2 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .handle-bar {
        height: 10px;
        width: 2px;
        background-color: #cbd0d6;
        display: block;
      }
    }

    .rc-slider-track {
      background-color: #1D9AFA;
    }

    .rc-slider-rail {
      background-color: #cbd0d6;
    }
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;

    input {
      width: 70px;
      padding: 0.5rem 1rem;
      line-height: 0;
      border-radius: 0.5rem;
      border: solid 1px #b5c4d6;
      color: #434A5F;
      font-size: 0.875rem;
    }
  }

  .Select {
    margin-top: 14px;
  }

  .PriceRangeChart {
    margin-top: 15px;
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: stretch;

    > span {
      flex: 1;
      background: #CBD0D6;
      display: block;
      margin: 0 1px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      height: 100%;
      transition: max-height 0.3s ease-in-out;

      &.active {
        background: #1D9AFA;
      }
    }
  }

  .buttons {
    text-align: right;
    margin-top: 15px;
    margin-bottom: 10px;

    .btn {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
