.CreateNewAdContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin-top: -48px; */

  .section_content {
    .LandlordRules2{
      width: 100%;
    }
  }
}
