@import "../../../_variables";

.applied-filters {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    .selected-filter {
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
      gap: 0.5rem;
      text-transform: capitalize;
      font-weight: 400;
      border-radius: 1.9375rem;
      background: #E0EAF0;
      box-shadow: 0px 6px 30px 0px rgba(140, 155, 165, 0.15);
      color: #0F4D7D;
      font-size: 0.75rem;
      font-weight: 500;
      cursor: pointer;
    }
}
