@import '../../../../../../_variables';

.PublishedAds{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;

  >:not(:last-child){
    margin-bottom: 15px;
  }
}
