@import "../../../../../../../../../../../_variables";

.Advanced {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 10px;

  .Advanced__section {
    display: flex;
    flex-direction: column;

    .Advanced__section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      >span:first-child {
        font-size: @16px;
        font-weight: 700;
        color: @grey6;
      }
      >span:last-child:not(:last-child) {
        font-size: @12px;
        font-weight: 400;
        color: @grey6;
      }

    }

    .Advanced__section-content {
      margin-bottom: 15px;
      overflow: scroll;
      position: relative;
      max-height: calc(100vh - 480px);
      min-height: 115px;

      .Resident {
        display: flex;
        box-shadow: @box_shadow;
        padding: 24px 22px 26px 24px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 25px;

        .Resident__photo {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border:  2px solid @blue3;
          box-sizing: border-box;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        }

        .Resident__info {
          display: flex;
          flex-direction: column;
          .Resident__info-label {
            font-size: @13px;
            color: #404955;
            font-weight: 700;
            margin-bottom: 3px;
          }
          .Resident__info-value {
            font-size: @12px;
            color: @grey4;
            font-weight: 400;
          }
        }
        .Resident__actions {
          display:flex;
          flex-direction: column;
          >a {
            margin-top: 5px;
            align-self: center;
          }
        }
        .Resident__button {
          cursor: pointer;
          &:hover {
            box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
          }

        }
      }

      .Resident__edit {
        flex-direction: column;
        .Loading {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .Resident__edit-dates {
          display: flex;
          width: 100%;
            .Resident__photo {
              cursor: pointer;
              position: relative;
              >input {
                display: none;
              }
              &:hover {
                &:after {
                  position: absolute;
                  content: "Edit";
                  width: 61px;
                  height: 61px;
                  left: -1px;
                  border-radius: 50%;
                  font-size: @12px;
                  background-color:rgba(0, 0, 0, 0.4);
                  color: @white;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                }
              }
            }

          .DatePickerBackground {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }

          .Resident__edit-dates__inputs {
            display: flex;
            margin-left: 60px;
            border: 1px solid @grey3;
            box-shadow: @box_shadow;
            border-radius: 4px;
            padding: 8px 44px 11px 44px;
            align-items: center;
            width: 100%;
            justify-content: space-around;
            @media (max-width: 768px) {
              padding: 8px 10px 11px 10px;
            }
            @media (max-width: 580px) {
              flex-direction: column;

            }
            .arrows-container {
              background-color: @grey2;
              height: 36px;
              width: 36px;
              border-radius: 50%;
              flex-direction: row;
              padding: 6px;
              >img {
                filter: brightness(0.4);
              }
            }


            >div {
              display: flex;
              flex-direction: column;
              >label {
                font-size: @12px;
                color: @grey5;
                font-weight: 400;
                margin-bottom: 3px;
              }
              .CustomDatePicker {
                .CustomInput {
                  display: flex;
                  font-weight: 700;
                  cursor: pointer;
                  min-width: 141px;
                  font-size: @14px !important;
                  >img {
                    margin-left: 25px;
                    position: unset;
                    height: 19px;
                  }
                }
              }
            }
          }
        }

        .Resident__edit-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 30px;

          .info {
            display: flex;
            flex-direction: column;

            >label{
              font-weight: 700;
              margin-bottom: 10px;
              font-size: @14px;
            }

            .occupation-options {
              display: flex;
              flex-wrap: wrap;
              >div {
                border: 1px solid #DFE3E6;
                cursor: pointer;
                margin-right: 10px;
                padding: 10px 26px;
                font-size: @13px;
                white-space: nowrap;
                margin-bottom: 7px;
                &:hover:not(.selected) {
                  background-color: @blueSoft;
                }
              }
              .selected {
                background-color: @blue2;
                color: @white;
              }
            }

            .input-select {

              .input-select__control {
                border-radius: 8px;
              }
              .input-select__control--is-focused {
                border: 1px solid @blueFocus;
                box-shadow: none;
              }

              .flag-control-wrapper {
                width: 200px;
                display: flex;
                align-items: center;
                padding-left: 5px;
                >img {
                  max-height: 16px;
                  max-width: 16px;
                }

              }
              .input-select__menu {
                .flag-option-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  >img {
                    position: unset;
                    max-height: 11px;
                    max-width: 16px;
                  }
                }
              }
            }

          }
        }
        .info-personal {
          @media screen and (max-width: 840px) {
            flex-direction: column;
            .info {
              width: fit-content;
              margin-bottom: 10px;
            }
          }
        }

        .Resident__edit-buttons {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 25px;
          .input-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            >input {
              width: 18px;
              height: 18px;
              background-color: white;
              border: 1px solid @grey5;
              appearance: auto;
            }
            >label {
              margin-left: 6px;
              font-size: @12px;
              color: @grey4;
            }

          }
          .buttons {
            display: flex;
            >div:not(:last-child) {
              margin-right: 10px;
            }
          }

        }

      }

    }
    .Advanced__add-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      width: fit-content;
      margin-left: auto;
      >svg {
        margin-left: 17px;
      }
      &:hover {
        >svg {
          scale: 1.2;
          animation: ease-in 0.2s;
        }
      }
    }

    .inactivate_container--btn{
      margin-top: 2rem;
      margin-left: 1rem;
      width: 135px;
    }
  }
}
