@import "../../../../../../../../_variables.less";

.PotentialTenantsList__popOver {
  position: fixed;
  z-index: 1000;
  background-color: @white;
  width: 27rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: @mobileWidth) {
    width: 90%;
    background-color: @background;
  }

  .popOver--header {
    border-radius: 0.5rem;
    width: 100%;

    &:first-child {
      content: "";
      height: 72px;
      width: 100%;
      background-color: rgba(29, 154, 250, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 2.5px solid @background;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .popOver--content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    h4 {
      color: #284F83;
      font-size: 1.125rem;
      font-weight: 700;
    }

    p {
      font-weight: 400;
      font-size: @12px;
      line-height: 20px;

      &:before {
        content: "";
        height: 8px;
        width: 8px;
        background-color: @grey3;
        display: inline-block;
        border-radius: 100%;
      }

      &:not(:last-child):after {
        content: "";
        height: 14.5px;
        width: 2px;
        background-color: @grey3;
        border-radius: 100%;
        display: block;
      }
    }
  }

  .popOver--action {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-around;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
    }

    .btn {
      width: 100%;
    }

    /* @media screen and (max-width: @mobileWidth) {
      width: 50%;
    } */
  }
}

.PotentialTenantsList__formPopOver {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 1000;
  background-color: @white;
  width: 90%;
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 1120px;
  padding: 1.5rem;

  @media screen and (max-width: @mobileWidth) {
    background-color: @background;
    min-width: 100vw;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    transform: none;
    border-radius: 0;
    gap: 1rem;
  }

  .popover__tenant-offer {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #EEF7FE;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      gap: 1rem;
    }

    .tenant-offer__avatar {

    }

    .tenant-offer__info {
      flex: 1;

      h4 {
        color: #1D9AFA;

        @media screen and (max-width: @mobileWidth) {
          font-size: 1rem;
        }
      }

      p {
        font-weight: 500;

        @media screen and (max-width: @mobileWidth) {
          font-size: 0.75rem;
        }
      }

    }
  }

  .popOver--footer {
    content: " ";
    height: 1rem;
    background-color: @white;
    width: 100%;
    border-radius: 5px;
  }
  .popOver--secondHeader {
    display: flex;
    width: 100%;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }


    .adds {
      width: 40%;
    }

    .prices {
      width: 30%;
    }
  }

  .popOver--propertySelectedActions {
    background: @white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #E6ECEE;
    padding-top: 1.5rem;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      flex-direction: column;
      font-size: @10px;
      line-height: 22px;
      color: @grey5;
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
      padding: 1rem;
      gap: 0.5rem;
    }

    .current-property-selected {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      padding: 0 0.5rem;
      background: #EEF7FE;
      border-radius: 0.5rem;
      align-items: center;

      .check-icon {
        width: 1.875rem;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 0.25rem;
        border-radius: 100%;
        background: #1D9AFA;
      } 

      .current-property-info {
        display: flex;
        padding: 0.5rem;
        gap: 1rem;

        div {
          display: flex;
          gap: 0.25rem
        }

        p {
          color: #1D9AFA;
          font-size: 0.875rem;
          font-weight: 700;
        }
        
        span {
          color: #69747C;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    /* .info {
      display: inline-flex;
      white-space: nowrap;
      height: 100%;
      align-items: center;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      &.info-error{
       color: @redError;
      }
    } */

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      min-width: fit-content;
      max-width: 50%;

      @media screen and (max-width: @mobileWidth) {
        max-width: 100%;
        bottom: 0;
        width: 100%;
      }

      .actions__action{
        @media screen and (max-width: @mobileWidth) {
          flex-direction: row-reverse;
          justify-content: flex-end;
          width: 100%;
        }

        .btn {
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            font-size: 0.875rem;
          }
        }
        /* p{
          @media screen and (max-width: @mobileWidth) {
            margin-left: 1rem;
          }
        } */
      }

      .actions__switch{
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      > div {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

        .btn {
          @media screen and (max-width: @mobileWidth) {
            font-size: @10px;
            line-height: 22px;
          }
        }

        > p{
          @media screen and (max-width: @mobileWidth) {
            font-size: @10px;
            line-height: 22px;
            color: @grey5;
          }
        }
        .actions--info{
          font-size: @9px;
          text-align: right;
          line-height: @14px;
        }

        .Switch {
          .react-switch-bg {
            div {
              p {
                @media screen and (max-width: @mobileWidth) {
                  font-size: @10px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
      &:last-child{

        .conditions{
          margin: 0;
          padding: 0;
          min-height: 29px;
          padding-left: .5rem;
          padding-right: .2rem;
          border-radius: 13px;
          p{
            transition: all .5s;
            font-weight: bold;
          }
          &:after{
            background-color: @white;
            content: " ";
            height: 24px;
            width: 24px;
            border-radius: 100%;
            transition: all .5s;
          }
        }
        .conditions__initial{
          margin: 0;
          padding: 0;
          min-height: 29px;
          padding-left: .7rem;
          border-radius: 13px;
          background-color: @grey4;
          text-align: left;
          p{
            transition: all .5s;
            transform: translateX(100%);
            font-weight: bold;
          }
          &:after{
            background-color: @white;
            content: " ";
            height: 24px;
            width: 24px;
            border-radius: 100%;
            transition: all .5s;
            transform: translateX(-150%);
          }
        }
      }
    }
  }

  .popOver--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    h3 {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #1D9AFA;
      font-size: 1.375rem;
      font-weight: 700;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: currentColor;
      }
    }

    svg {
      cursor: pointer;
    }

    @media screen and (max-width: @mobileWidth) {
      /* flex-direction: column-reverse; */
      margin-top: 0;
    }

    /* .popOver--back {
      height: 14px;
      cursor: pointer;
    } */

    /* .popOver--header_title {
      display: flex;
      width: 46%;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        justify-content: flex-start;
      }

      h4 {
        max-width: 50%;
        @media screen and (max-width: @mobileWidth) {
          max-width: 100%;
          font-size: @16px;
        }
      }

      button {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      p {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }
    } */

    .popOver--header_actions {
      display: flex;
      flex-wrap: nowrap;
      width: 53%;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        flex-direction: column-reverse;
      }

      .sort{
        display: flex;
        align-items: center;
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }

        &.roomList{
        }

        .sortIcon{
          width: 25px;
          height: 25px;
          transition: all .5s ease-in-out;
          cursor: pointer;

          &.asc{
            transform: rotateX(180deg);
          }
        }

        img, p{
        }
        .css-yk16xz-control,.css-26l3qy-menu, .css-1pahdxg-control{
          font-size: @14px;
          width: 165px;
        }
        .css-1wa3eu0-placeholder{
          white-space: nowrap;
        }
        .css-1hb7zxy-IndicatorsContainer{
          display: none;
        }
      }

      p {
        display: flex;

        img {
        }
      }

      .css-2b097c-container {
        width: 150px;
      }

      .SearchFilter {
        width: 235px;
        @media screen and (max-width: @mobileWidth) {
          width: 100%;
        }
      }

      .popOver--header_actions--close {
        width: 14px;
        height: 14px;
        cursor: pointer;
        @media screen and (max-width: @mobileWidth) {
          align-self: flex-end;
        }
      }
    }
  }

  .popOver--propertyList {
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media screen and (max-width: @mobileWidth) {
      min-height: unset;
      max-height: unset;
      height: 65vh;
    }

    @media screen and (max-height: 1110px) {
      padding-bottom: 70px;
    }
  }
}
