@import "../../../../../../../../../../../_variables";

.LandlordRules2 {
  .scrollable {
    > div {
      width: 80%;
    }
  }


  .LandlordRules_container {

    &:not(:first-child) {
      margin-top: 10px;
    }

  }

  .FieldSetCheckBoxes fieldset {
    min-height: 10px;
  }

  .SwitchContainer {
    margin-bottom: 15px;

    .SwitchContainer-label {
      display: inline-flex;
      width: 50%;

      label {
        font-weight: bold;
        font-size: @13px;
      }
    }


  }


  //a dashed border to some input_containers
  .input_container {
    &.dashed {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }


  .FieldSetCheckBoxes {
    fieldset {
      > label {
        border: none;
        padding-left: 0px;

        .check_mark {
          margin-left: -5px;
        }

        label {
          margin-left: 10px;
        }
      }
    }
  }
}