@import "../../../../../../../../../_variables.less";

.popup_dialog_modal {
  max-width: 600px !important;

  p {
    margin-bottom: unset !important;
  }

  .editModal {

    .input_container_double > div {
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
      }
    }

    .input_container_double {
      width: 100%;

      .input_container > div {
        @media screen and (max-width: @mobileWidth) {
          width: 100%;
        }
      }

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }
    }
  }
}

.roomResume {
  /* cursor: pointer;
  min-width: 100%;
  min-height: 120px;
  border-bottom: 1px solid @grey3;
  display: flex;
  align-items: center;
  transition: border .5s; */
  width: calc(50% - 1.5rem);
  min-width: 45%;
  max-width: 50%;
  height: 225px;
  flex: 1;
  /* min-height: 450px;
  max-height: 225px; */
  /* border-bottom: 1px solid @grey3;
  margin-left: 1%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);

  &.selected {
    border-bottom: unset;
    background: #EEF7FE;
  }

  @media screen and (max-width: @mobileWidth) {
    background: #FFFFFF;
    /* margin: 1rem 0; */
    max-height: unset;
    min-width: 100%;
    flex-direction: column;
    gap: 1rem;
    height: unset;
  }

  .room-resume__photo {
    position: relative;
    min-width: 10rem;
    min-height: 100%;
    border-radius: unset;
    border-radius: 0.5rem 0 0 0.5rem;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      height: 12.5rem;
      border-radius: 0.5rem 0.5rem 0 0;
      min-height: unset;
    }

    .room__blocked {
      position: absolute;
      padding: 0.5rem;
      bottom: 0;

      .status {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.25rem;
        background-color: #BE0F05;
        color: white;
        font-size: 0.75rem;
        font-weight: 700;
        padding: 0.15rem 0.25rem;

        svg {
          fill: currentColor;
        }
      }
    }

    &.desktop {
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }
  }

  .room-resume__content {
    width: 100%;
    padding: 1rem 1.5rem 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      padding: 0 1rem 1rem 1rem;
      gap: 1rem;
    }

    .room-resume__price {
      display: flex;
      flex-direction: column;
  
      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
        gap: 0.25rem;
      }
  
      div {
        display: flex;
        gap: 0.25rem;

        @media screen and (max-width: @mobileWidth) {
          justify-content: space-between;
        }
  
        p {
          font-size: 0.875rem;
          font-weight: 500;
          color: #5A6872;
    
          @media screen and (max-width: @mobileWidth) {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 20px;
            color: @grey5;
          }
        }
    
        h5 {
          margin: unset;
          font-size: 0.875rem;
          font-weight: 700;
          color: #434A5F;
    
          @media screen and (max-width: @mobileWidth) {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 20px;
            color: @grey5;
          }
        }

        .availability {
          color: #BE0F05;
        }
      }
    }

    h5, p {
      margin: unset;
      font-size: 0.875rem;
    }

    .roomResume--title {
      justify-content: space-between;
      width: 100%;

      p {
        color: #5A6872;
        font-size: 0.75rem;
        font-weight: 500;

        @media screen and (max-width: @mobileWidth) {
          font-size: @10px;
        }
      }

      h5 {
        margin: unset;
        color: #284F83;
        font-size: 1rem;
        font-weight: 700;
      }

      .roomResume--rent_container {
        display: flex;
        justify-content: space-between;

        .rent {
          p {
            color: @darkblue1;
          }

          div {
            display: flex;

            h4 {
              color: @darkblue1;
              margin-right: 10px;
            }

            p {
              color: @grey4;
            }
          }
        }

        .available {
          color: @green;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: @14px;

        .detailsSection {
          display: flex;
          flex-wrap: wrap;
        }
      }

      p {
        font-size: @10px;
        color: @grey4;
        display: flex;
        align-items: center;
      }
    }

    .room-resume__info {
      display: flex;
      flex-direction: column;

      .info__price {
        display: flex;
        gap: 0.25rem;
      }

      .info__availability {
        display: flex;
        gap: 0.25rem;

        h5 {
          color: #BE0F05;
        }
      }
    }
  }

  &.offer {
    background: @white;
    border: 1px solid @grey3;
    cursor: auto;
    min-height: 170px;
    max-height: 170px;
    width: 100%;
    max-width: unset;
    border-radius: 8px;
    justify-content: space-between;

    .rent {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    div {
      text-wrap: none;

      p {
        display: flex;
        align-items: center;

        img {
          max-height: 14px;
        }
      }

      /* img, strong {
        margin-right: 5px;
      } */
    }

    &:hover {
      background: @white;
    }

    .roomResume--adds {
      /* margin-left: 0; */
      min-height: 170px;
      max-height: 170px;
      width: 75%;
      align-items: unset;

      .roomResume--photo__mobile {
        display: none;
        @media screen and (max-width: @mobileWidth) {
          display: block;
        }

        .roomResume--photo {
          @media screen and (max-width: @mobileWidth) {
            display: block;
          }
        }
      }
    }

    .status {
      min-height: 100%;
      display: flex;
      justify-content: space-between;
      min-width: 20%;

      div:not(:last-child) {
        /* margin-right: 2rem; */
        align-items: center;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          font-size: @10px;
          /* margin-bottom: 1rem; */
          color: @grey4;
        }
      }

      span {
        border-radius: 24px;
        padding: 7px 50px;
        font-size: @14px;
      }

      .accepted {
        background-color: @blue2;
        color: @white;
      }

      .declined {
        color: @grey5;
        background-color: unset;
        border: 1px solid @blue2;
      }
    }
  }

  .roomResume--adds {
    width: 40%;
    /* margin-left: 1rem; */
    display: flex;
    align-items: center;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      margin-left: unset;
    }

    .roomResume--photo {
      /* width: 144px; */
      height: 100%;
      padding: 3.25rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
      @media screen and (max-width: @mobileWidth) {
        padding: 3rem;
        width: unset;
        height: unset;
      }
    }

    .roomResume--title {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* margin-left: 1rem; */

      p {
        font-size: @14px;
        color: @grey5;
        @media screen and (max-width: @mobileWidth) {
          font-size: @10px;
        }
      }

      h5 {
        margin: unset;
        /* margin-bottom: .2rem; */
      }
    }
  }

  .roomResume--prices {
    width: 30%;
    display: flex;
    align-items: center;
    position: relative;
    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      color: @grey5;
      font-weight: 700;
      /* margin-left: 14rem; */
      font-size: @14px;
    }

    .availability {

      .available_from{
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      img {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      .availability-date {
        display: flex;
        align-items: center;

        .CustomDatePicker {
          width: 0;
          @media screen and (max-width: @mobileWidth) {
            display: none;
          }

          .react-datepicker__day--excluded, .react-datepicker__day--disabled, .passed_day {
            background-color: #ffe7e7;
            color: #8b8b8b;
            border-radius: 4px;
          }

          .react-datepicker {
            border: 1px solid #e3e6e9;

            .react-datepicker__header {
              background-color: #fcfcfc;
              border-bottom: 1px solid #e3e6e9;
            }
          }

          .react-datepicker__input-container {
            display: none;
          }

          .react-datepicker__triangle {
            display: none;
          }
        }
      }
    }

    .price_component--prices {
      display: flex;
      align-items: center;
      img{
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }
    }

    .roomResume--price_component {
      flex-direction: column;
      /* margin-right: 2rem; */
      height: 100%;
      min-width: 34%;

      @media screen and (max-width: @mobileWidth) {
        min-width: unset;
        height: unset;
        /* margin-right: 1rem; */
      }


      .price_component--input {
        display: flex;
        align-items: center;
        flex-direction: row;

        input {
          width: 4rem;
          border: 1.5px solid @blueSoft;
          border-radius: 11px;
          transition: background-color .2s ease-in-out;
          text-transform: unset;
          text-align: center;
        }

        input:focus, input:active {
          background-color: @blueFocus;
          text-transform: unset;
          text-align: center;
        }

        button {
          justify-content: center;
          align-items: center;
          /* margin-left: 1rem; */
        }
      }
    }

    .blueIcon {
      /* margin-left: .2rem; */
      width: 15px;
      height: 15px;
      /* margin-right: 10px; */
      cursor: pointer;
      z-index: 1;
      filter: invert(1) sepia(1) saturate(5) hue-rotate(171deg); //azul!
    }

    > p {
      color: @grey5;
      font-size: @16px;
    }
  }

  .roomResume--actions {
    display: flex;
    gap: 1rem;
    height: 100%;
    color: @grey5;
    position: relative;
    width: 100%;

    .btn {
      flex: 1;
    }

    @media screen and (max-width: @mobileWidth) {
      display: flex;
      flex-direction: column;
      height: unset;
      width: 100%;
    }

    .totalStay {
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }

    div {
      /* margin-bottom: 1rem; */
      p{
        @media screen and (max-width: @mobileWidth) {
          font-size: @12px;
        }
      }
    }

    .available {
      padding-top: 1rem;
      color: @green;
      display: flex;
      @media screen and (max-width: @mobileWidth) {
        margin-right: 1rem;
        margin-left: 0.5rem;
      }

      img {
        /* margin-left: 1rem; */
        width: 17.67px;
        height: 17.67px;
      }
    }

    .unavailable {
      /* width: 213px; */
      height: 36px;
      background-color: @grey1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      /* margin-top: 1rem; */
      cursor: pointer;

      @media screen and (max-width: @mobileWidth) {
        width: unset;
        margin: 0 1rem 0 0.5rem;
        padding: 0 0.5rem;
        cursor: unset;

      }

      p {
        display: flex;
        align-items: center;
        color: @grey4;
        img{
          @media screen and (max-width: @mobileWidth) {
            display: none;
          }
        }
      }

      .info {
        width: 17.67px;
        height: 17.67px;
        @media screen and (max-width: @mobileWidth) {
         display: none;
        }
      }
    }

    .reasons {
      transition: all .3s ease-in-out;
      min-width: 285px;
      min-height: 50px;
      background-color: @grey1;
      z-index: 999;
      position: absolute;
      border-radius: 4px;
      box-shadow: @box_shadow;
      @media screen and (max-width: @mobileWidth) {
        min-width: unset;
        transform: translateY(36px);
        max-width: 50vw;
      }

      h5 {
        padding: .5rem;
        margin: unset;
        @media screen and (max-width: @mobileWidth) {
          font-size: @12px;
        }
      }

      p {
        padding: .5rem;
      }
    }
  }
}

.simple-datepicker-revamped {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #DFE3E6;
  background: #FFF; 
  max-width: 10rem;
  overflow: hidden;
  box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);

  &:active {
    box-shadow: 0px 0px 6px 0px rgba(29, 154, 250, 0.20);
    border: 1px solid #1D9AFA;
  }

  .react-datepicker-wrapper {
    flex: 1;
    width: 6rem;
  }

  .react-datepicker-wrapper input {
    border: none;
    width: 100%; /* Ensure the input takes the available width */
  }
}
