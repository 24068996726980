@import "../../../_variables.less";

.dropdown-select {
  position: relative;

  .dropdown__container {
    position: relative;
    width: fit-content;

    .dropdown__header {
      display: flex;
      padding: 0.5rem 1rem;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 0.5rem;
      background: white;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: 500;
      color: #434A5F;
      text-transform: capitalize;
    }

    .dropdown__header.active {
      border: 1px solid #1D9AFA;
      box-shadow: 0px 0px 6px 0px rgba(24, 160, 251, 0.20);
    }

    .dropdown-list__container {
      position: absolute;
      max-height: 224px;
      min-width: 112px;
      width: fit-content;
      overflow-y: scroll;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 0.5rem;
      z-index: 999;
      box-shadow: 0px 4px 14px 0px rgba(128, 128, 128, 0.35);

      .dropdown-list {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        text-wrap: nowrap;

        .dropdown-list__item {
          list-style: none;
          color: #5A6872;
          font-size: 0.75rem;
          font-weight: 500;
          padding: 0.5rem 1rem;
          cursor: pointer;

          &:hover {
            background: #EEF7FE;
          }
        }
      }
    }
  }
}
