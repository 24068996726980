@import "../../../../../../../../../../../_variables";

.LandlordRules5{

  .LandlordRules_container {

    .input_container {
      &.has-booking-window-input {
        margin-bottom: 16px;
      }

      .booking-window-info {
        margin-bottom: 10px;
      }
    }

  }

  .FieldSetCheckBoxes{
    margin-top: 10px;
  }
}

.UpdateBookingWindowModal.ActionDialog {
  .MuiDialog-container .MuiPaper-root {
    width: 90vw;
    max-width: 600px;
    border-radius: 10px;
    min-height: unset;
  }
  .ActionDialog__content {
    border-radius: 10px;

    .title {
      font-size: @22px;
      color: @darkblue1;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      margin-top: 20px;
    }

    .small-desc {
      font-size: @14px;
      color: @grey6;
      text-align: justify;
      padding: 16px 0;
    }

    .buttons_container {
      display: flex;
      gap: 15px;
      width: 100%;
      padding-top: 20px;
      margin-bottom: -15px;

      > .btn {
        flex: 1;
      }
    }

    .apply-input-radio {
      .apply-input-radio_label {
        font-size: @16px;
        line-height: @22px;
        font-weight: 700;
        color: @charcoal;

        .MuiFormControlLabel-root {
          margin-bottom: 10px;
        }
      }
    }
  }
}
