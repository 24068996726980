@import "../../../../_variables";

.Dashboard{
  >:nth-child(2n+3) {
    background-color: white;
  }
}

.Dashboard__header {
  margin-top: 1rem;
  justify-content: space-between;
  width: 100%;

  h3 {
    margin: 30px 0;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }
}

.DashboardNavContainers{
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

//DashboardNavContainer
.DashboardNavContainer{
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 93px;
  justify-content: space-between;
  width: 32%;
  text-decoration: none;

  @media screen and (max-width: @mobileWidth) {
    padding: 15px 8px;
    height: unset;
  }

  &:hover {
    background-color: @grey1;
  }
  .DashboardNavContainer__desktop{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .DashboardNavContainer__mobile {
    display: none;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }

    h5 {
      margin: unset;
      font-size: @12px;
      font-weight: 700;
      line-height: 16px;
    }

    .DashboardNavContainer__mobile-title {
      display: flex;
      justify-content: space-between;
      padding: .3rem;
    }
  }

  &.blue {
    &.active {
      @media screen and (max-width: @mobileWidth) {
        border-bottom: solid 4px @blue2;
      }
    }

    .DashboardNavContainer__count {
      border-bottom-color: @blue2;
      color:@blue2;
    }
  }

  &.purple {
    &.active {
      @media screen and (max-width: @mobileWidth) {
        border-bottom: solid 4px @purple;
      }
    }

    .DashboardIcon {
      background-color: rgba(@purple, 0.1);
    }

    .DashboardNavContainer__count {
      border-bottom-color: @purple;
      color: @purple;
    }
  }

  &.green {
    &.active {
      @media screen and (max-width: @mobileWidth) {
        border-bottom: solid 4px @green;
      }
    }

    .DashboardIcon {
      background-color: rgba(@green, 0.1);
    }

    .DashboardNavContainer__count {
      border-bottom-color: @green;
      color: @green;
    }
  }

  h5{
    flex:0.8;
  }
}

.DashboardIcon{
  align-items: center;
  background-color: rgba(@blue2,0.1);
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;

}

.DashboardNavContainer__count{
  border-bottom-width: 2.5px;
  border-bottom-style: solid;
  padding-bottom: 3px;
  width: fit-content;

  &.unActive {
    @media screen and (max-width: @mobileWidth) {
      color: #DADADA !important;
      border-bottom-color: #DADADA !important;
    }
  }

  p {
    font-size: 1.5rem !important;
    font-weight: 700;
    font-family: 'Inter',sans-serif;
    line-height: 24px;
  }
}

//Sections
section{
  padding: 40px 0;
  min-height: fit-content;
}

.DashboardList{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ListItem{
    margin-bottom: 10px;
  }
}


/*             Welcome Section                   */
.WelcomeSection{
  h2{color:@blue;}
}

.WelcomeSectionButtons{
  display: inline-flex;
  margin:15px 0 0 0;

  button{
    &:not(:first-child){
      margin-left: 10px;
    }

    img{
      margin-right: 7px;
    }
  }
}

.EmptySectionContainer {
  margin-top: 30px;

  .EmptySection {
    display: inline-flex;
    padding: 40px 40px;
    width: 100%;
    justify-content: space-between;

    .DashboardIcon {
      height: 40px;
      width: 40px;

      img {
        height: 24px;
        width: 24px;
      }
    }

    &.blue {
      img {
        margin-left: 6px;
      }
    }

    &.purple {
      .DashboardIcon {
        background-color: rgba(@purple, 0.1);
      }
    }

    &.green {
      .DashboardIcon {
        background-color: rgba(@green, 0.1);

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.WelcomeSectionContainerInfo{
  flex:0.93;
}


/*             DashboardSections             */
.DashboardSections {
  .DashboardSections__sections, .DashboardSections__sections-mobile {
    > :nth-child(2n) {
      background-color: white;
    }

    .PotentialTenantsList .basic_container {
      margin-top: 0;
    }
  }
  .DashboardSections__sections{
    padding-bottom: 3rem;

    @media screen and (max-width: @mobileWidth) {
     display: none;
    }
  }
  .DashboardSections__sections-mobile{
    display: none;
    @media screen and (max-width: @mobileWidth) {
      display: block;
    }
    #visitRequest{
      margin-top: 0!important;
    }
  }
}

.SectionHeader{
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;

  @media screen and (max-width: @mobileWidth) {
    h4{
      font-weight: 700;
      font-size: @18px;
      line-height: 24px;
    }
  }

  .link {
    font-size: @14px;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }
}

//Top Landlord Advantages
.TopLanlordAdvantages{
  padding: 30px 0;

  h4{
    margin-bottom: 40px;
  }

  >div{
    display: inline-flex;
    justify-content: space-between;
    @media screen and (max-width: @mobileWidth) {
      display: flex;
      flex-direction: column;
    }

    >div{
      width: 19%;
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}

.potentialTenants-container {
  padding-bottom: 0;
}

