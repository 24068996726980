.CommonAreaComponent{
  .CommonAreaComponent__header{
    margin-bottom: 30px;

    .CommonAreaComponent__header-title{
      display: inline-flex;
      justify-content:space-between;
      width:100%;

      .title{
        font-size: 1.1rem;
        flex: 0.9;
        height: 50px;
      }

      .header-image {
        background-size: cover;
        background-position: center;
        border-radius: 6px;
        width: 100px;
        height: 100px;
      }

    }

    .CommonAreaComponent__header-subtitle{
      margin-top:15px;
    }
  }

}
