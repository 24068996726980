//Switch
.Switch{
  >div{

    >div{
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:first-child{
        padding-left: 5px;
      }

      &:last-child{
        padding-right: 5px;
      }

      p{
        color:white;
        font-size:0.9rem;
        font-weight:600;
      }
    }
  }
}