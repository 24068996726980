@import "../../../../_variables";

.TenantInfo {
  /* min-width: 32%;
  max-width: 32%;
  display: inline-flex;
  align-items: center; */

  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .tenant__photo {
    height: 4.375rem;
    width: 4.375rem;
    border: none;
    box-shadow: none;
    margin: unset;
    padding: unset;
    cursor: pointer;

    @media screen and (max-width: @mobileWidth) {
      width: 3rem;
      height: 3rem;
    }
  }

  &.fullIcon {
    &.noMaxWidth{
      max-width: unset;

      img{
        cursor: pointer;
      }
    }
  }

  &.noMaxWidth{
    max-width: unset;

    img{
      cursor: pointer;
    }
  }

  .tenant__info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .tenant__info__name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #284F83;
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
      }
  
      .tenant__info__icons {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.25rem;
          background-color: rgba(40, 79, 131, 0.1);
          border-radius: 100%;
          cursor: pointer;
        }
      }
    }

    .tenant__personal-details {
      color: #5A6872;
      font-size: 0.75rem;
      font-weight: 500;
    }

    .tenant__offers-sent {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      color: #5A6872;
      font-size: 0.75rem;
      font-weight: 500;

      svg {
        width: 1rem;
        height: 1rem;
        fill: #284F83;
      }
    }

    .tenant__offers-sent.sent {
      color: #284F83;
      font-weight: 700;
    }

    .RequestDates {
      p {
        color: #434A5F;
      }
    }
  }

  /* .tenant__photo {
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    height: 48px;
    margin-right: 15px;
    position: relative;
    width: 48px;
  } */

}

.RoundedCircleFlag {
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: inline-flex;
  height: 29%;
  justify-content: center;
  position: absolute;
  right: 35%;
  width: 29%;
  top: 80%;


  > div {
    background-position: center;
    background-size: cover;
    width: 80%;
    height: 80%;
    border-radius: 50%;
  }
}
/* 
.TenantInfo {
  min-width: 32%;
  max-width: 32%;

  &.fullIcon {
    &.noMaxWidth{
      max-width: unset;
      img{
        cursor: pointer;
      }
    }
    .tenant__photo {
      height: 4.375rem;
      width: 4.375rem;
      border: none;
      box-shadow: none;
      margin: unset;
      padding: unset;
    }

    .TenantName{
      img{
        visibility: visible;
      }
    }
  }
}

 */
