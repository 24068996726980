.MoveInInfo{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 50px;

  >div{
    display: inline-flex;

    img{
      margin-right: 10px;
    }
  }
}