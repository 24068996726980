@import "../../../../../../_variables";

.billing-details {
  display: flex;
  gap: 1.5rem;

 /*  .AccountDetails__container {
    display: none;
  } // to work on smaller screen
 */
  .billing-details__container {
    display: flex;
    width: 100%;
    gap: 1.25rem;

    @media screen and (max-width: @mobileWidth) {
      margin-right: 0;
      flex-direction: column;
      padding-top: 1.5rem;
      height: 100%;
    }

    .billing-details__container__card {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-right: 0.25rem;
      max-height: 100%;
      max-width: 480px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 0.5rem;

      @media screen and (max-width: @mobileWidth) {
        max-width: 100%;
      }
    }
  }
}

.billing-details-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media screen and (max-width: @mobileWidth) {
    margin-right: 0;
  }

  .billing-details-form__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .billing-details-form__header__title {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      color: #284F83;
    }

    .billing-details-form__header__default {
      color: #9fa4aa;
      font-size: 14px;
    }

    .billing-details-form__header__close-button {
      margin-left: 10px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:hover {
        background-color: @redBackGround;
      }
    }

    .billing-details-form__header__not-default {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  }

  .billing-details-form__container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    gap: 1rem;
  }

  .file-input {
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 6px;
    overflow-x: hidden;

    > label {
      width: fit-content;
      white-space: nowrap;
    }

    > span {
      margin-left: 10px;
      white-space: nowrap;
    }

    > input {
      display: none;
    }
  }

  &.empty {
    .billing-details-form__container {
      .empty-card {
        display: flex;

        .details-icon-wrapper {
          width: 40px;
          min-width: 40px;
          margin-right: 10px;
          height: 40px;
          border-radius: 50%;
          background-color: #e9f5ff;

          display: flex;
          justify-content: center;
          align-items: center;

          > svg {
            width: 20px;
            height: 20px;
          }
        }

        .details-info {
          h5 {
            margin: 0;
          }

          p {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.save-info-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  .btn {
    flex: 1;
    height: 100%;
    text-wrap: nowrap;
  }

  @media screen and (max-width: @mobileWidth) {
    width: 100%;
    flex-direction: column;
    text-wrap: none;

    button {
      width: 100%;
    }
  }

  &.double {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.add-more {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;

  > button {
    margin-left: 16px;
  }
}

.form-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .checkbox__label {
    font-weight: 500;
    font-size: 0.75rem;
    color: @charcoal;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    color: #284F83;

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #5A6872;
    }
  }

  .form-field-container__input {
    position: relative;
    width: 100%;

    input:focus {
      + .form-field-container__input__icon-wrapper > svg {
        fill: #284F83;
      }
    }

    input {
      width: 100%;
      height: 40px;
      border: 1px solid #DFE3E6;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      padding-right: calc(25% + 1rem);
      font-size: 0.875rem;
      font-weight: 500;
      color: #434A5F;
      background-color: white;

      &::placeholder {
        color: #8C9BA5;
      }

      .error {
        box-shadow: 0px 0px 6px 0px rgba(242, 180, 87, 0.30);
      }
    }

    .error input:active {
      box-shadow: 0px 0px 6px 0px rgba(242, 180, 87, 0.30);
    }

    .form-field-container__input__icon-wrapper {
      padding-left: 8px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      gap: 13px;

      > svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: #8C9BA5;
      }
    }
  }

  .form-field-container__field {
    display: flex;
    align-items: center;
    gap: 1rem;

    > div {
      padding-bottom: 0;
    }

    .form-field-container__field__country-select {
      position: relative;
      height: 40px;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      font-size: 14px;
      color: #434A5F;
      background-color: white;
      padding: 0px;
      border: 1px solid #DFE3E6;
      display: flex;
      align-items: center;

      .ReactFlagsSelect-module_selectBtn__19wW7 {
        font-weight: 500;
        padding: 0 1rem;

        .ReactFlagsSelect-module_selectValue__152eS {
          margin: 0;
          padding: 0;
        }
      }

      > button {
        border-radius: 4px;
        border: none;
      }

      > ul {
        top: 40px;
        width: 100px;
        height: 300px;
        border-radius: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid #DFE3E6;

        > div {
          padding: 0;


          > input {
            border: none;
            margin: 0;
            padding: 0.5rem;
            font-weight: 500;
          }
        }
      }

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  .form-field-container__validate-vat {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .form-field-container__properties {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      font-weight: 700;
      font-size: 14px;
      color: #57B4F2;
    }
  }
}

.form-field-label {
  color: #284F83;
  font-size: 0.875rem;
  font-weight: 700;
}

.file-drop-container {
  position: relative;
  height: 4.125rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  background: #FFFFFF;
  border: 1px dashed #D4DAF4;
  border-radius: 0.25rem;
  cursor: pointer;

  .file-drop-container__upload-box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .file-drop-container__details {
      display: flex;
      align-items: center;
      gap: 30px;

      span {
        color: rgba(27, 44, 66, 0.72);
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .file-drop-container__action {
      display: flex;
      align-items: center;
      gap: 30px;

      .btn:not(:hover) {
        background: none;
      }
    }
  }

  .file-drop-container__input {
    display: none;
  }

  &.file-submitted {
    background-color: #F0F3F6;
  }
}

.modal {
  overflow: hidden;
  height: 100%;

  .modal__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;

    .input-container .input_container {
      margin-bottom: 0;
    }

    .modal__container__content {
      /* padding: 0 16px; */
      overflow: auto;
    }
  }

  /* .select-all-associated {
    width: 100%;
    padding: 0 1rem;

    .CheckBox {
      > label {
        font-size: 14px;
        font-weight: 700;
        color: #284F83;
      }
    }
  } */

  .select-associated {
    width: 100%;

    .Select-menu-outer {
      background-color: red;
    }

    .Select {
      background-color: red;
    }

    .Select-control {
      background-color: red;
      border-color: #d9d9d9 #ccc #b3b3b3;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #333;
      cursor: default;
      display: table;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .input_container {
      position: relative;
      margin: 0;

      @media screen and (max-width: @mobileWidth) {
        margin-bottom: 0;
      }

      .css-26l3qy-menu {
        display: block;
        padding: 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        color: #434A5F;
      }


      &:focus {
        input {
          color: @grey6;
        }
      }

      input {
        color: @grey6;
      }

      img {
        top: 22%;
        position: absolute;
        height: 21px;
        right: 3%;
      }
    }

    .input-field {


      img, svg {
        width: 1em;
        height: 1em;
        margin-left: 1em;
        margin-right: 1em;
      }

      .css-2b097c-container {
        min-width: 100%;

        .flag-control-wrapper {
          min-width: 100%;
          display: flex;
          align-items: center;
        }

        .css-7x2mut-menu {
          div > div {
            white-space: nowrap;
          }

          img, svg {
            margin-right: .5em;
            margin-left: 0;
          }
        }
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .associated-properties {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: scroll;

    .associated-properties__card {
      display: flex;
      width: 100%;
      height: 120px;
      border-radius: 8px;
      height: 7.5rem;

      .associated {
        background-color: #EEF7FE;
        border-radius: 0 8px 8px 0;
      }

      .associated-properties__card__photo {
        width: 115px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 8px 0 0 8px;
        }
      }

      .associated-properties__card__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        gap: 24px;

        .associated-properties__card__content__info {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: space-between;
          text-align: left;

          > h5 {
            height: 3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #284F83;
          }

          > p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #5A6872;
          }
        }

        .btn {
          /* min-width: 124px; */

          svg {
            fill: currentColor;
          }
        }
      }
    }
  }

  .info-validated {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .info-validated__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      > h3 {
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        color: #284F83;
      }

      > p {
        font-weight: 400;
        font-size: 14px;
        color: #8C9BA5;

        > strong {
          color: #1D9AFA;
          font-weight: 600;
        }
      }
    }
  }
}
