@import "../../_variables";

.Card {
  position: relative;
  background-color: white;
  box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12.5rem;

  @media screen and (max-width: @mobileWidth) {
    flex-direction: column;
    height: unset;
  }

  .Card__actions-status__mobile-container {
    display: none;
    position: absolute;
    font-size: @12px;
    line-height: 20px;
    text-align: center;
    height: 24px;
    color: @grey2;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }

    .Card__actions-status_mobile {
      margin-left: 15px;
      border-radius: 100px;
      padding: 0 .5rem;
      margin-top: 10px;

      p {
        white-space: nowrap;
      }

      &.blue {
        background: @blue3;
      }

      &.grey {
        background: @grey4;
      }
    }
  }

  .Card__image {
    position: relative;
    width: 10rem;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      margin-right: 0;
      min-height: 115px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
    }

    .card__overlay {
      position: absolute;
      padding: 0.5rem;
      bottom: 0;
      
      .expiration-timestamp {
        padding: 0.15rem 0.25rem;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: white;
        font-size: 0.625rem;
        font-weight: 700;
        border-radius: 0.25rem;
        background: rgba(67, 74, 95, 0.20);
        backdrop-filter: blur(2px);
      }
    }

    .Card__image__overlay {
      position: relative;
      top: -18px;
      right: 27px;
      height: 36px;
      width: 36px;
      border: 1px solid @grey3;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-shadow: @box_shadow;
    }

    .Card__image-type_tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      border-radius: 12px;
      font-size: 10px;
      color: white;
      padding: 0 8px;

      &.instant {
        background-color: @purple;
      }

      &.booking {
        background-color: @blue3;
      }

      > span {
        display: block;
        width: 100%;
        text-align: center;
      }

      > img {
        margin-right: 5px;
      }
    }
  }

  .Card__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 2.5rem 1rem 1.5rem;
    height: 100%;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      width: 100%;
      padding: 1rem 1.5rem;
    }

    .Card__property-info {
      max-width: 20rem;
      width: 20rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h5 {
        margin: unset;
        color: #284F83;
        font-size: 1rem;
        font-weight: 700;
      }

      @media screen and (max-width: @mobileWidth) {
        max-width: 100%;
        width: 100%;
      }

      > p {
        font-size: @12px;
        font-weight: 700;
        line-height: 15px;
        max-width: 247px;
      }

      .property-details {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        svg {
          fill: #434A5F;
        }

        > span {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: #5A6872;
          font-size: 0.75rem;
          font-weight: 500;

          /* &:not(:last-child) {
            margin-bottom: 5px;
          } */

          /* > img {
            margin-right: 10px;
            width: 8px;
            max-height: 10px;
          } */

          /* > strong {
            margin-left: 5px;
            color: @grey6;
          } */
        }
      }
    }

    .compareArrowsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      background-color: rgba(40, 79, 131, 0.1);
      border-radius: 100%;
      
      svg {
        fill: #284F83;
      }
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }

    .Card__tenant-info {
      display: flex;
      align-items: center;
      min-width: 256px;
      gap: 1rem;

      @media screen and (max-width: @mobileWidth) {
        border-top: 1px solid #DFE3E6;
        border-bottom: 1px solid #DFE3E6;
        width: 100%;
        min-width: 100%;
        padding: 1rem 0;
        margin: 1rem 0;
      }

      .avatar-container {
        margin: unset;
        border: none;
      }

      .tenant-details {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: #5A6872;
        font-size: 0.625rem;
        font-weight: 500;

        h5 {
          margin: unset;
          color: #284F83;
          font-size: 1rem;
          font-weight: 700;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          > p {
            font-size: @12px;
            font-weight: 700;
          }

          > span {
            display: flex;
            align-items: center;

            /* > img {
              width: 12px;
              margin-right: 5px;
            } */

            /* > strong {
              margin-right: 3px;
              font-weight: 400;
              color: @grey6;
            } */
          }
        }

        /* > div:not(:last-child) {
          margin-bottom: 8px;
        } */
      }
    }

    .Card__dates {
      display: flex;
      flex-direction: column;
      max-width: 280px;

      > p {
        font-size: @12px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .dates-container {
        display: flex;
        flex-direction: column;
        min-height: 88px;

        .date-box {
          background-color: #f8f9fc;
          border-radius: 6px;
          height: 26px;
          width: 100%;
          padding: 0 10px;
          font-size: @11px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          > span {
            color: @grey4;
          }

          > strong {
            color: @grey6;
            font-weight: 400;
          }
        }
      }
    }

    .tenant-dates-info, .tenant-dates-info-mobile {
      display: flex;
      color: #434A5F;
      font-size: 0.75rem;
      font-weight: 700;

      strong {
        color: #5A6872;
        font-size: 0.75rem;
        font-weight: 500;
      }

      .dates-info {
        display: flex;
        flex-wrap: wrap;
        >p {
          color: #434A5F;
          font-size: 0.75rem;
          font-weight: 700;

          strong {
            color: #5A6872;
            font-size: 0.75rem;
            font-weight: 500;
          }
        }

      }

      /* span {
        display: flex;

        img {
          @media screen and (max-width: @mobileWidth) {
            margin-right: 1rem;
          }
        }

        strong {
          @media screen and (max-width: @mobileWidth) {
            margin-left: .5rem;
          }
        }
      } */
    }

    .tenant-dates-info {
      @media screen and (max-width: @mobileWidth) {
        display: none!important;
      }
    }

    .tenant-dates-info-mobile {
      display: none;
      @media screen and (max-width: @mobileWidth) {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: unset;
      }
    }

    .Card__actions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      align-items: flex-end;
      min-height: unset;
      width: 186px;

      .expiration {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.75rem;
        font-weight: 500;

        span {
          font-weight: 700;
        }
      }

      &.Actions{
        align-items: center;
      }

      @media screen and (max-width: @mobileWidth) {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1rem;
      }

      .ActionsExpires {
        @media screen and (max-width: @mobileWidth) {
          width: unset;
        }
      }

      .Card__actions-cancellation-request {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFAF2;
        border-radius: 30px;
        font-size: 0.75rem;
        font-weight: 700;
        color: #B68741;
        padding: 5px 16px;
        white-space: nowrap;
        max-width: 186px;

        .tooltip__wrapper {

          .top {
            left: 80px;
            max-width: 300px;
          }

          > svg {
            margin-right: 8px;
          }

          > div {
            padding: 16px;
            width: 348px;
            line-height: 20px;
            white-space: break-spaces;
          }
        }

        @media screen and (max-width: @mobileWidth) {
          width: 100%;
          max-width: unset;
        }
      }

      > .Actions__buttons {
        min-height: unset;
        width: 100%;
        a {
          text-decoration: none;
          width: 100%;
        }

        @media screen and (max-width: @mobileWidth) {
          display: flex;
          align-items: stretch;
        }

        > img {
          margin: unset;
        }
      }

      .Card__actions-status {
        font-size: 12px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      .expires-in {
        display: flex;
        margin-bottom: 10px;
        color: @grey4;
        font-size: @10px;
        font-weight: 500;

        > img {
          margin-right: 5px;
        }
      }

      > button.btn {
        font-size: 12px;

        > img {
          margin-right: 10px;
        }
      }

    }

    .reject-visit {
      color: @grey4;
      font-size: @10px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 10px;
    }

    .Card__summary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        border: 1px solid @grey3;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;

        > span {
          font-size: @11px;
        }
      }
    }
  }
}

