@import "../../../../../../../../../../../_variables";

.Resume2 {

  .acceptedTerms {
    display: inline-flex;
    align-items: center;

    .checkbox__label {
      margin-left: 25px;
      line-height: 22px;
      font-size: 0.875rem;
    }
  }

  >:first-child {
    width: 85%;

    .Resume__header {
      display: inline-flex;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 30px;

      img {
        width: 13px;
        height: 13px;
        margin-right: 15px;
      }
    }

    .Resume2__textContainer {
      border-bottom: @dashed_bottom;
      padding-bottom: 10px;
      margin-bottom: 20px;

      > * {
        margin-bottom: 20px;
      }
    }
  }

  .Buttons {
    #with_verification_button {
      min-width: 320px;
    }
  }

  .link{
    font-size: @14px;
  }
}
