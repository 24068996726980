@import "../../../../../../../../../../../_variables";

.RoomDetails {

  .scrollable {
    > div {
      &:not(.RoomDetailsHeader) {
        width: 70%;
      }
    }
  }

  form {

    .input_containers {
      width: 100%;

      > div {
        padding: 40px 0px;
        border-bottom: 1px dashed @greyMiddle;
        margin-bottom: 0px;

        &:last-child {
          border-bottom: none;
        }
      }
    }


  }

  .FieldSetCheckBoxes {
    margin-top: 10px;

    fieldset {
      min-height: 0px;

      label {
        padding-left: 0px;

        &.checkbox__label {
          margin-left: 0px;
        }
      }
    }

  }


}