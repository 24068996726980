@import '../../../../../../_variables';

.AdsList{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .page__header__title h3 {
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .AdsList_noAds{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }


}

.CreateAndSearchProperty{
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;


  /* > button.btn {
    font-size: @14px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    width: 182px;

    p {
      margin-top: 1px;
      margin-left: 3px;
      text-align: center;
      width: 100%;
    }
  } */

  .ConfirmAllAvailabilitiesContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-top: 7px;
    padding-right: 20px;
    max-width: 620px;
    @media screen and (max-width: @mobileWidth) {
      padding-bottom: 7px;
      padding-right: unset;
    }


    > p {
      font-size: @14px;
      text-decoration: none;
      display: inline;
      margin-top: 2px;
      min-width: 238px;

      &.link:hover {
        text-decoration: underline;
      }
    }
  }
}

.ConfirmAvailabilitiesDialog .MuiDialog-container .MuiPaper-root {
  height: 100%;
  max-height: 380px;
  width: 100%;
  max-width: 540px;
  padding: 30px;
  margin: 0;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  .ConfirmAvailabilities__ProgressContainer {
    width: 98%;
    margin-top: 35px;

    p {
      padding-top: 5px;
    }
  }

  .ConfirmAvailabilities__Success {
    position: absolute;
    top: calc(50% - 21px);
    right: calc(50% - 23px);
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    &.finished {
      opacity: 1;
    }

    > svg {
      width: 42px;
      height: 42px;
      color: @green;
    }
  }

  .ConfirmAvailabilities__Actions {
    display: flex;
    column-gap: 15px;
  }
}

.AdsList {
  .CreateAndSearchProperty {
    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
  }
}
