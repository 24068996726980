@import "../../../../../../../../../../../../../_variables";

.AvailabilitySlots {
  min-height: 150px;
  height: fit-content;
  padding: 16px 18px;
  display: flex;
  flex-direction: column;

  p {
    font-size: @14px;
  }

  .basic_container {
    display: flex;
  }

  .AvailabilitySlots__selectors {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    .AvailabilitySlots__selector {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 16px;
      width: 100%;

      p {
        font-weight: bold;
      }

      .AvailabilitySlots__selector__container--datepicker {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > p, .tooltip__wrapper > p {
          color: #284F83;
          font-weight: 700;
          display: flex;
          gap: 5px;

          .infoIcon {
            width: 12px;
          }
        }

        .button {
          height: 36px;
        }
      }

      .AvailabilitySlots__selector__container--reactselect {
        &.input_field {
          border: none;
        }

        .value-display {
          font-size: 11px;
          line-height: 36px;
          white-space: nowrap;
        }

        > .Select--is-disabled {
          font-size: 11px;

          & > .Select__control.Select__control--is-disabled > .Select__indicators {
            display: none;
          }
        }

        flex: 1.5;
      }

      .btn {
        width: 90px;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .error_message {
    width: 100%;
    margin-top: 16px;
  }

  .success_message {
    margin-top: 16px;
  }

  .AvailabilitySlots__add-availability {
    height: 56px;
    padding: 18px;
    background-color: #EEF7FE;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #1D9AFA;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    > img {
      color: #1D9AFA;
    }
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-read-view--selected-month {
    font-weight: 700;
    font-size: 20px;
  }

  .react-datepicker__month-read-view {
    display: flex;
    align-items: center;
  }

  .react-datepicker__month-read-view--down-arrow {
    top: auto;
  }

  .date-picker-range .react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    color: lightgray;
    opacity: 1;
    pointer-events: all;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  min-width: 90px;

  &.add {
    background-color: #1D9AFA;
    color: white;

    &:hover {
      background-color: #1D9AFA;
      opacity: 0.7;
    }
  }

  &.remove {
    background-color: #F0F3F6;
    color: #FA392E;

    &:hover {
      background-color: #F0F3F6;
      opacity: 0.7;
    }
  }
}
