@import "../../_variables";

.NavBarGoBackContainer{
  /* margin: 1rem; */
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  &.fixed {
    position: fixed;
  }

  .NavBarGoBackContainer__button {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    padding: 6px;
    background-color: white;
  }

  p{
    color: @blue;
    margin-left: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: @12px;
    line-height: 20px;
  }
}
