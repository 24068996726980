@import "../../../../../../../../_variables";

.ChooseImages {
  width: 500px;
  border: 1px dashed @grey;
  border-radius:5px;
  height: 215px;
  display: flex;
  flex-direction: column;
  background-color: white;

  &.error_container{
    border: 1px dashed red;
    background-color:@redBackGround;
  }

  &.no-images{
    width: 200px !important;
  }

  ///* Dropzone */
  .ChooseImages__dropzone {
    width:100%;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ChooseImages__change-image {
      width: 100%;
      height: 215px;
      display: flex;
      flex:1;
      flex-direction:column;
      padding:15px;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      font-weight: 600;
      border:none;
      color: @grey5;

      &:hover{
        cursor: pointer;
      }

      p {
        margin-left: 5px;
      }

      .ChooseImages__change-image__title-container {
        .ChooseImages__change-image__title {
          font-size: 1rem;
          margin-bottom: 10px;
        }
        .ChooseImages__change-image__info{
          font-size: 0.65rem;
          text-align:center;
          font-weight:500;
        }
      }

      .ChooseImages__change-image__placeholder{
        flex:1;
        display:flex;
        flex-direction: column;
        justify-content:space-between;

        .ChooseImages__change-image__placeholder--icon{
          display:inline-flex;
          align-items:flex-end;
          margin-bottom:20px;
          flex:1;
        }

        &:hover{
          img{
            filter:invert(0.1);
          }
        }

        .ChooseImages__change-image__info{
          label{
            font-size:0.9rem;
            font-weight:700;
          }

          p{
            font-weight:400;
            font-size:0.82rem;
          }
        }
      }
    }


  }

  //Contaier that contains images
  .ChooseImages__change-image-full{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 215px;
    max-width: 665px;
    padding:20px 40px;

    .ChooseImages__change-image-full__title{
      display: inline-flex;
      justify-content:space-between;
      align-items: center;

      >label{
        color:black;
        font-size:1rem;
        font-weight:bold;
      }

      >button{
        color:@grey4;

        >label{
          margin-left:5px;
          font-size:0.85rem;
          font-weight:600;
        }
      }
    }

    .ChooseImages__change-image-full__center{
      display: inline-flex;
      align-items: center;
      width: 100%;

      &.empty{
        justify-content: center;

        .ChooseImages__image-list{
          display: none;
        }

        .ChooseImages__dropzone{
          flex: 1;
        }
      }

      .ChooseImages__image-list{
        overflow-y: visible;
        margin:5px 0;
      }

      .ChooseImages__dropzone{
        width: 50px;
        margin-left: 20px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content:center;
        cursor: pointer;

        &:hover{
          img{
            filter:invert(0.1);
          }
        }
      }
    }


    .ChooseImages__change-image-full__info{
      display: inline-flex;
      align-items: center;
      justify-content:center;

      p{

        &:first-child {
          color:@grey6;
          font-size:0.85rem;
        }

        &:last-child {
          color:@grey4;
          margin-left:5px;
          font-size:0.8rem;
        }
      }
    }


  }

  /* Images */

  .ChooseImages__image-list{
    flex:1;
    margin: 20px 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;

    &::-webkit-scrollbar {
      height: 5px!important;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: @blue;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: @blue;
    }
  }

  .file-item {
    width: 82px;
    height: 82px;
    margin: 10px;
    cursor: grab;
    position: relative;
    flex: 0 0 auto;

    &:first-child {
      margin-left: 0;
    }

    &:hover{
      .file-item__buttons{
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .file-img {
      width: 80px;;
      height: 80px;;
      display: block;
      background-size: cover;
      background-position: center;
      box-shadow: 0 0 5px 1px #7272722b;
      border-radius: 3px;
    }

    .file-item__buttons{
      opacity: 0;
      display: inline-flex;
      position: absolute;
      margin-top: 2px;
      right: -12px;
      top: -12px;

      > .MUIMenu {
        position: unset;
      }

      .btn{
        border-radius: 50%;
        width: 25px;
        min-height: 25px;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        color: white;
        border: none;
      }

    }

    .remove-img {
      background: url(images/removeIcon.svg) no-repeat;
    }


  }




  .file-img {
    width: 82px;
    height: 82px;
  }

  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }

    .file-item {
      width: 82px;
      height: 82px;
    }
  }

  /* Mobiles */
  @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }

    .file-item {
      width: 82px;
      height: 82px;
    }
  }
}

.ChooseImages__Lightbox {
  .MuiDialog-container .MuiPaper-root {
    background: unset;
    box-shadow: unset;
    justify-content: center;
    align-items: center;
    max-width: fit-content;

    > .ChooseImages__Lightbox-image {
      border-radius: 6px;
      border: 2px solid rgba(255, 255, 255, 0.77);
      max-height: 80vh;
    }
  }
}
