@import "../../_variables";

.Footer{
  width: 100%;
  min-height: @footerheight;
  background-color:white;
  z-index:100;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  flex:0;
  margin-top: auto;
  @media screen and (max-width: @mobileWidth) {
    min-height: fit-content;
    padding-top: 1rem;
  }

  &.footer_mobile{
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }


  .Footer__container{
    width:100%;
    display:inline-flex;
    justify-content:space-between;
    align-items:center;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
    }

    .Footer__container-main{
      display:inline-flex;
      justify-content:space-between;

      @media screen and (max-width: @mobileWidth) {
        width: 100%;
      }

      img{
        max-width: 75px;
      }

      p{
        margin-top:5px;
        font-size:0.6rem;
        color:@grey;
        white-space: nowrap;
      }

      .Footer__container-main-cities{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }

        h5{
          font-weight:400;
          font-size:0.7rem;
          color:@grey4;
          margin: .5rem;
        }
      }
    }

    .Footer__container-links{
      display:inline-flex;
      width:fit-content;
      @media screen and (max-width: @mobileWidth) {
        padding: .5rem 0;
        display: flex;
        flex-wrap: wrap;
      }

      a{
        font-size: 0.7rem;
        color:@grey4;
        @media screen and (max-width: @mobileWidth) {
          white-space: nowrap;
          margin-right: 10px;
        }

        &:not(:first-child){
          margin-left:30px;
          @media screen and (max-width: @mobileWidth) {
            margin-left: 0;
          }
        }
      }


      div:last-child{
        display:inline-flex;
      }
    }



    div{
      width:fit-content;
      margin:0;
      img{
        @media screen and (max-width: @mobileWidth) {
          width: 50px;
        }
      }
    }
  }
}
