@import "../../../_variables";

@border-radius: 4px;

.PropertyResume {
  width: 100%;

  > div {
    width: 100%;
  }

  .PropertyResume__header {
    display: inline-flex;
    align-items: center;
    border-radius: @border-radius;

    label {
      font-weight: bold;
    }
  }

  //Property Resume Header
  .PropertyResume__header {
    height: 120px;
    background-color: white;
    box-shadow: @box_shadow;
    padding: 20px 50px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .PropertyResume__avatar__overlay {
      position: relative;
      top: -28px;
      right: 42px;
      height: 36px;
      width: 36px;
      border: 1px solid @grey4;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-shadow: @box_shadow;
    }

    &.type_small {
      justify-content: unset;
      padding: 0;
      background-color: #F5F7FA;
    }

    .PropertyResume__featured-photo {
      width: 112px;
      height: 112px;
      margin: auto 25px auto 8px;

      .PropertyResume__photo {
        background-size: cover;
        border-radius: 4px;
        height: 100%;
        width: 100%;
      }
    }

    .PropertyResume__header-main {
      width: 250px;

      .header-address {
        max-height: 42px;
        overflow: hidden;
      }

      h5 {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }

    .PropertyResume__header-complementary {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .PropertyResume__header-tags {
        display: inline-flex;
        flex: 0.75;
        justify-content: space-between;
        max-width: 350px;
      }


    }

    .PropertyResumeButtons {

      .PropertyResumeButtons__openRooms {
        display: inline-flex;
        align-items: center;
        @media screen and (max-width: @mobileWidth) {
          margin: 1rem 0;
        }

        img {
          margin-left: 20px;
        }
      }

      .PropertyResumeButtons__editProperty {
        display: inline-flex;
        align-items: center;

        @media screen and (max-width: @mobileWidth) {
          justify-content: space-between;
          width: 100%;
        }

        > button > img {
          margin-right: 2px;
        }

        > img {
          margin-left: 20px;
        }
      }
    }


    .PropertyResume__header-roomsInfo {
      font-size: @14px;
    }
  }

  .PropertyResume__content {
    display: flex;
    flex-direction: column;

    &.hide_rooms {
      display: none;
    }
  }

  &.Integrations {
    .RoomResume__main {
      width: 500px;
    }

    .full_link {
      width: 100%;
      margin-right: 10px;
      text-decoration: none;
      color: @blue2;
    }
  }

  &.My_Ads, &.Integrations {
    .PropertyResume__header {
      background-color: #E5E9F1;
      height: 100px;
      padding: 15px 50px;

      @media screen and (max-width: @mobileWidth) {
        height: unset;
        padding: 12px 10px;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        text-align: center;
        gap: 1rem;
      }

      .PropertyResume__header-main {
        width: 420px;

        @media screen and (max-width: @mobileWidth) {
          width: 100%;
        }

        > h5 > small {
          font-size: 14px;
          color: @grey5;
          @media screen and (max-width: @mobileWidth) {
            text-align: center;
          }
        }

        .PropertyResume__header-complementary {
          @media screen and (max-width: @mobileWidth) {
            max-width: 100%;
          }
        }

        .PropertyResume__header-icons {
          display: inline-flex;
          @media screen and (max-width: @mobileWidth) {
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
          }

          .verified-tag p:hover {
            text-decoration: underline;
          }

          > div {
            display: inline-flex;
            min-width: 170px;
            @media screen and (max-width: @mobileWidth) {
              min-width: unset;
              white-space: nowrap;
              padding: 0.5rem 0;
            }

            .icon {
              align-items: center;
              background-color: @grey4;
              border-radius: 50%;
              display: inline-flex;
              height: 18px;
              justify-content: center;
              margin-right: 10px;
              min-width: 18px;
              @media screen and (max-width: @mobileWidth) {
                margin-right: 5px;
              }

              img {
                height: 12px;
                width: 12px;
              }
            }

            &:not(:first-child) {
              margin-left: 20px;
              @media screen and (max-width: @mobileWidth) {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .PropertyResume__header-roomsInfo {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: @mobileWidth) {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media screen and (max-width: @mobileWidth) {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }


      //Buttons componente
      .PropertyResumeButtons {
        /* width: 170px; */
        display: grid;
        justify-content: end;

        @media screen and (max-width: @mobileWidth) {
          width: 100%;
          display: flex;
          gap: 0.5rem;
          justify-content: space-between;
        }

        .PropertyResumeButtons__editProperty {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          width: 100%;

          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            display: flex;
            gap: 0.5rem;
            justify-content: center;
          }

          > img {
            @media screen and (max-width: @mobileWidth) {
              margin-left: unset;
              padding-right: unset;
            }
          }

          .btn_white {
            color: @blue !important;
            @media screen and (max-width: @mobileWidth) {
              display: none;
            }

            p {
              margin-left: 3px;
            }
          }
        }
      }
    }

    .RoomResume:not(:last-child){
      @media screen and (max-width: @mobileWidth) {
        border-radius: 4px;
        border-bottom: 1px solid @grey3;
      }
    }

    .RoomResume {
      @media screen and (max-width: @mobileWidth) {
        height: unset;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin-top: .5rem;
        border-radius: 4px;
      }

      .RoomResume__main {
        @media screen and (max-width: @mobileWidth) {
          display: flex;
          width: 100%;
          max-width: 100%;
        }

        .RoomResume__photo {
          @media screen and (max-width: @mobileWidth) {
            min-width: 80px;
            margin: auto 9px auto 0;
          }
        }

        .RoomResume__main-content {
          @media screen and (max-width: @mobileWidth) {
            max-width: 70%;
          }

          .RoomResume__info {
            @media screen and (max-width: @mobileWidth) {
              max-width: 100%;
            }

            > div {
              @media screen and (max-width: @mobileWidth) {
                font-size: @12px;
              }

              label {
                @media screen and (max-width: @mobileWidth) {
                  font-size: @14px;
                }
              }

              .RoomResume__info-title {
                @media screen and (max-width: @mobileWidth) {
                  width: unset;
                }
              }
            }
          }
        }
      }

      .RoomResume__complementary {
        @media screen and (max-width: @mobileWidth) {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1rem;
        }

        .PriceAndAvailabilityComponent {

          > div {
            @media screen and (max-width: @mobileWidth) {
              row-gap: unset;
              width: unset;
              height: unset;
              flex-direction: row;
              white-space: nowrap;
              flex-wrap: wrap;
            }

            > div {
              &:first-child {
                @media screen and (max-width: @mobileWidth) {
                  margin-right: 15px;
                }
              }
            }

            .AvailabilityComponent {
              @media screen and (max-width: @mobileWidth) {
                white-space: nowrap;
              }

              img {
                @media screen and (max-width: @mobileWidth) {
                  display: none;
                }
              }
            }
          }

          .prices-interval {
            @media screen and (max-width: @mobileWidth) {
              font-size: @14px;
            }
          }

          .PopupState {
            @media screen and (max-width: @mobileWidth) {
              display: none;
            }
          }
        }

        .ResumeButtons {
          @media screen and (max-width: @mobileWidth) {
            width: unset;
          }

          .btn{
            @media screen and (max-width: @mobileWidth) {
              display: none;
            }
          }
        }

        .MUIMenu {
          @media screen and (max-width: @mobileWidth) {
            top: 20px;
          }
        }
      }
    }
  }
}


