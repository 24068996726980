@import "../../../../../../_variables.less";

.tenant-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  section {
    padding: unset;
    min-height: unset;
  }

  .tenant-details__header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .NavBarGoBackContainer {
      @media screen and (max-width: @mobileWidth) {
        margin-top: 1rem;
      }
    }
  }

  h3 {
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    color: #284F83;
  }
  
  .tenant-details__card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .tenant-details__stay {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .stay__details {
        padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        font-size: 0.875rem;

        @media screen and (max-width: @mobileWidth) {
          padding: unset;
        }

        p {
          color: #434A5F;
        }

        span {
          font-weight: 700;
        }
      }

      .stay__details.budget {
        p {
          color: #434A5F;
          font-weight: 700;
        }

        span {
          font-size: 1.125rem;
          font-weight: 700;
          color: #57B4F2;
        }
      }
    }

    .tenant-details__statistics {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .statistic__details {
        padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        font-size: 0.875rem;

        @media screen and (max-width: @mobileWidth) {
          padding: unset;
        }

        p {
          color: #434A5F;
        }

        span {
          font-weight: 700;
        }
      }

      .statistic__details.registration {
        p {
          color: #434A5F;
          font-weight: 700;
        }

        span {
          font-size: 1.125rem;
          font-weight: 700;
          color: #57B4F2;
        }
      }
    }

    .tenant-details__meet {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1.5rem;

      .tenant-details__about, .tenant-details__video {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        video {
          width: 100%;
          border-radius: 1rem;
          object-fit: fill;
        }
      }
    }
  }

  .card {
    width: 100%;
    padding: 1.5rem 2.5rem;
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);

    @media screen and (max-width: @mobileWidth) {
      padding: 1.5rem;
    }

    h5 {
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      color: #284F83;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: #5A6872;
    }
  }

  .tenant-details__container {
    height: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: 1rem;

    
    .tenant-details__container__header h3 {
      color: #1D9AFA;
    }

    .tenant-details__container__header {

    }

    .tenant-details__container__main-content {
      display: flex;
      gap: 1.5rem;
      height: 100%;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .tenant-details__tenant-info {
        display: flex;
        gap: 1.5rem;
        align-items: center;

        .avatar-container {
          width: 6.75rem;
          height: 6.75rem;
          margin: unset;
        }

        .tenant-info {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .info {
            h2 {
              font-size: 1.5rem;
              font-weight: 700;
              color: #284F83;
            }
          
            p {
              font-size: 0.875rem;
              font-weight: 500;
              color: #5A6872;
            }
          }
        }
      }

      .tenant-details__tenant-actions {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .tenant-actions__message {
          display: flex;
          padding: 0.5rem 1rem;
          border-radius: 0.25rem;
          background: #CCDCE6;
          color: #434A5F;
          font-size: 0.75rem;
          font-weight: 500;

          div {
            display: flex;
            flex-direction: column;
          }

          strong, a {
            font-weight: 700;
            color: #284F83;
            text-decoration: none;
          }

          &.warning {
            background: #FFFAF2;
            color: #9D7437;
            font-weight: 700;
            .warning-icon {
              width: 14px;
              min-width: 14px;
              height: 14px;
              margin-right: 15px;
              margin-top: 3px;
              overflow: auto;
              > svg {
                width: 100%;
                height: 100%;
              }
            }

          }
        }

        .tenant-actions__buttons {
          display: flex;
          gap: 1rem;
          width: 100%;

          @media screen and (max-width: @mobileWidth) {
            flex-direction: column;
          }

          .btn {
            flex: 1;
          }
        }
      }
    }

    .tenant-details__wrapper {
      display: flex;
      gap: 1.5rem;
      width: 100%;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .tenant-details__content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
      }
    }

    .tenant-details__footer {
      display: none;

      @media screen and (max-width: @mobileWidth) {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #DFE3E6;
    margin: 0;
  }

  .offer-dialog__opacity {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .55);
    z-index: 999;
    content: " ";
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}
