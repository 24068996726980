@import "../../../../../../../../_variables";

.PriceComponent{
  height:36px;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  border:1px solid @greyMiddle;
  background-color:white;
  font-size: 0.9rem;
  padding-left: 0px !important;
  margin-bottom: 5px;

  &.input_field {
    padding-top: 2px;
  }

  *{
    border:none;
  }

  .error_message{
    margin-top: 5px;
  }

  >div{
    display:inline-flex;
    align-items: center;
    flex:0.5;

    input{
      align-items: center;
      display: inline-flex;
      text-align: left;
      height:32px;
      background-color:transparent;
      margin-left: 10px;

      &.input_error{
        border:none;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

  }

  &.focus{
    border: 1px solid @blueFocus !important;
  }
  .euro-tag {
    line-height: unset;
    font-size: 0.9rem;
  }

}
