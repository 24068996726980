@import "../../../../_variables";

.MyAds {
  margin-bottom: 2.5rem;

  .CreateNewAd{
    margin-top: 0;
    width: 100%;
    padding-top: 40px;
    //height: calc(100% + 40px);

    .textbox {
      font-size: @12px;
      margin-bottom: 20px;
      color: @grey;

      &.tips {
        display: inline-flex;
        align-items: center;
        width: fit-content;

        p {
          width: fit-content;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          flex: unset;
          filter: brightness(0.5);
        }
      }
    }
  }
}
