@import "../../../../../../../../_variables";

.MuiFormGroup-root{
  align-items: center;
  display: inline-flex;


  .MuiFormControlLabel-root{
    margin-left: 0px;
  }

  >.withIcons {
    align-items: center;
    background-color: white;
    border: 1px solid #DFE3E6;
    display: inline-flex;
    height: 59px;
    justify-content: space-between;
    padding: 5px 10px 5px 15px;
    width: 48%;

    .MuiFormControlLabel-root{
      margin-left: 0px;
      flex:1 ;
    }

    .makeStyles-icon-2{
      background: rgba(255, 255, 255, .85);
    }

    &:hover{
      cursor: pointer;

      .makeStyles-icon-2{
        background: rgba(24, 160, 251,.3);
      }
    }

    .icon{
      border-radius: 4px;
      height: 100%;
      width: 72px;
    }
  }
}

.basic_container{
  &.fullImage{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: center;
    width: 250px;

    &:hover{
      background: #F9FCFF;
    }

    &.active{
      border: 2px solid @blue2;
      background: #F9FCFF;
    }

    .MuiFormControlLabel-root{
      height: fit-content !important;
      margin-right: 0px;
      min-height: 40px;
      max-width: 100px;
      order: 2;
      text-align: center;
      width: fit-content !important;
    }

    .MuiTypography-root{
      font-weight: 600 !important;
      font-size: @16px !important;
    }


    .makeStyles-icon-2,.MuiButtonBase-root{
      display: none;
    }

    .icon{
      order:1;
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }
  }
}


#icon{
  margin-bottom: 0;
  background-color: @grey2;

  &.active {
    background-color: @blueSelectedBackground;

    img {
      filter:none;
    }
  }

  img {
    filter:invert(0.4);
  }

}
