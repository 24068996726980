@import "../../../../../../../../_variables";

.FieldSetCheckBoxes{

  fieldset{
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    padding:0;
    min-height:90px;
    width: 100%;
    margin: -10px 0 0 0;

    >label{
      width: 32%;
    }

    &.four-in-line{
      >label{
        width:23%;
      }
    }

    &.two-in-line{
      >label{
        width:47%;
      }
    }

    &.one-column{
      min-height:40px;
    }



    .label_bold{
      .checkbox__label{
        font-weight:700;
      }
    }

    >label{
      display:inline-flex;
      align-items: center;
      border:1px solid @greyMiddle;
      border-radius:3px;
      padding:5px 7px;
      margin-top: 10px;
      background: white;


      >label{
        margin-left: 5px;
      }

    }
  }
}