@import "../../../../_variables";

.MyCalendar {
  margin-top: 48px;
  display: flex;
  height: 100%;

  @media (max-width: @mobileWidth) {
    flex-direction: column;
  }


  .MyCalendar__calendar {
    display: flex;
    flex: 1.5;

    @media (max-width: @mobileWidth) {
      flex-direction: column;
      padding-right: 10px;
      padding-left: 10px;
    }

    .MyCalendar__calendar-body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      @media (max-width: @mobileWidth) {
        flex-direction: column;
      }
      .calendar {
        display: flex;
        flex-direction: column;
        @media (max-width: @mobileWidth) {
          margin-bottom: 15px;
        }
        .calendar__header {
          display: flex;
          align-items: center;
          height: 36px;
          margin-bottom: 30px;
          font-size: @14px;
          color: @grey6;
          font-weight: 700;
          > img {
            margin-right: 16px;
          }
        }

        >div:last-child {
          @media (max-width: @mobileWidth) {
            display: flex;
            justify-content: center;
          }
        }

        .react-datepicker {
          border: unset;
          box-shadow: @box_shadow;
          margin-top: 35px;

          .react-datepicker__header {
            border-bottom: unset;
            background: @grey1;
          }

          .react-datepicker__month-container {
            width: 300px;
          }

          .react-datepicker__month-read-view--down-arrow {
            border: solid @blue3;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            margin-left: 20px;
            top: 4px;
            width: unset;
            border-top-color: unset;

            &:before {
              content: unset;
            }
          }

          .react-datepicker__month-dropdown {
            background-color: white;

            > div {
              color: @grey6;

              &:hover {
                background-color: @blueSelectedBackground;
              }

            }
          }

          .react-datepicker__day-name {
            color: @grey6;
            font-weight: 700;
            font-size: @14px;
          }

          .react-datepicker__day--outside-month {
            visibility: hidden;
          }

          .react-datepicker__day {
            color: @grey5;
            margin: 0.266rem;
            height: 40px;

            > span {
              display: flex;
              flex-direction: column;

              .custom-marks {
                display: flex;
                justify-content: center;

                .react-datepicker__day--highlighted-custom {
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                }

                .check-in {
                  background: #A3E31A;
                }

                .visit-appointment {
                  background: #1AB3E3;
                }

                .pending-visit {
                  background: #F2B457;
                }
                .user-event {
                  background: @purple;
                }
              }

            }

          }

          .react-datepicker__day--keyboard-selected {
            background: #57B4F233;
            border-radius: 4px;
            color: @grey5;
          }
        }
      }



      .calendar-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .calendar-list__header {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          font-size: @14px;
          color: @grey6;
          font-weight: 700;
          > img {
            margin-right: 16px;
            width: 19px;
          }

          > button {
            margin-left: auto;
            font-size: @12px;
            color: @lightBlack;
            font-weight: 400;
            border: unset;
          }
        }

        .list-filters {
          display: flex;
          border-bottom: 2px solid @grey3;
          margin-bottom: 30px;

          .list-filter:not(:first-child) {
            margin-left: 30px;
          }

          .list-filter {
            position: relative;
            font-size: @11px;
            color: @lightBlack;
            padding: 3px 6px 7px 6px;
            cursor: pointer;
            line-height: 20px;
            white-space: nowrap;

            &.selected {
              color: @blue;

              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 3px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                background: @blue3;
                top: 29px;
                right: -2px;
              }
            }
          }
        }
        .Loading {
          background-color: unset;
        }

        .events-list {
          display: flex;
          flex-direction: column;
          height: 400px;
          overflow-y: scroll;
          min-width: 393px;
          padding-right: 3px;

          .day-events {
            display: flex;
            flex-direction: column;

            .day-name {
              font-size: @14px;
              font-weight: 700;
              color: @lightBlack;
              margin-bottom: 20px;
              padding: 5px;
            }

            .event-info {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              padding: 5px;
              cursor: pointer;
              border-radius: 4px;
              &:hover {
                background-color: @blueSelectedBackground;
              }
              .hour {
                font-weight: 400;
                font-size: @13px;
                margin-right: 30px;
              }

              .info {
                display: flex;
                width: 100%;
                .type-icon {
                  margin-top: 5px;
                  width: 8px;
                  height: 16px;
                  border-radius: 100px;
                  margin-right: 20px;

                  &.visit_request {
                    background-color: #F2B457;
                  }

                  &.confirmed_visit {
                    background-color: #1AB3E3;
                  }

                  &.check_in {
                    background-color: #A3E31A;
                  }
                  &.user_event {
                    background-color: @purple;
                  }
                }

                .description {
                  display: flex;
                  flex-direction: column;

                  > span:first-child {
                    font-weight: 700;
                    font-size: @14px;
                    margin-bottom: 6px;
                  }

                  > span:last-child {
                    font-size: @12px;
                    color: @grey5;
                    font-weight: 400;
                  }
                }
              }
              &.selected {
                background-color: @blueSelectedBackground;
              }
            }


          }
        }
      }
    }
  }

  .dashed-separator {
    width: 100%;
    height: 2px;
    border-bottom: 1px dashed @grey3;
    margin-bottom: 30px;
  }
  .MyCalendar__selected {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 24px;
    background-color: white;
    border-top-left-radius: 3px;

    @media (max-width: @mobileWidth) {
      margin-left: unset;
      border-top-left-radius: unset;
    }

    >div:not(.dashed-separator) {
      padding: 10px 10px 10px 40px;
    }
    .label {
      display: flex;
      align-items: center;
      font-size: @16px;
      color: @grey6;
      font-weight: 700;
      margin-bottom: 24px;

      > image {
        margin-right: 8px;
      }
    }

    .round-image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      margin-right: 48px;
    }

    .property-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;


      .info {
        display: flex;

        .property-image {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          margin-right: 48px;
        }

        .description {
          display: flex;
          flex-direction: column;

          > span:first-child {
            font-size: @13px;
            color: @grey6;
            font-weight: 700;
            margin-bottom: 14px;
          }

          > span {
            font-size: @12px;
            color: @grey4;
            display: flex;
            >img {
              margin-right: 25px;
            }
          }
        }
      }

    }

    .user-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      .user-info {
        display: flex;
        .user-photo {
          display: flex;
          flex-direction: column;
          >span{
            display: flex;
            align-items: center;
            font-size: @14px;
            color: @grey6;
            font-weight: 700;
            >img {
              height: 11px;
              margin-left: 5px;
            }
          }
        }
        .details {
          display: flex;
          flex-direction: column;
        }

      }
    }

    .event-details {
      display: flex;
      flex-direction: column;
    }

    .visit_buttons {
      display: flex;
      align-items: center;
      margin-top: 40px;
      >button {
        padding: 7px 40px 7px 40px;
        font-size: @14px;
        margin-right: 30px;
      }
    }

    .small-text {
      font-size: @12px;
      color: @grey4;
    }
  }
}

.NewEventDialog {
  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 70vw;
    max-width: 700px;
    border-radius: 20px;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }
  .NewEventDialog__content {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: @16px;
      margin-bottom: 20px;
      >img {
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .input_container {
      margin-bottom: 15px;
      >textarea {
        width: 100%;
        height: 100px;
        resize: none;
      }
    }

    .submit-button {
      width: fit-content;
      margin-left: auto;
    }
  }
}