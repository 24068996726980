@import "../../_variables";

.AccountDetails__erase_account {
  padding: 2rem 4rem !important;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 2rem 0;

  @media screen and (max-width: @mobileWidth) {
    padding: 1rem 2rem !important;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: @grey6;
    padding: 1rem 0;
    @media screen and (max-width: @mobileWidth) {
      font-size: @16px;
    }
  }

  p {
    padding: .1rem 0;
    color: @grey5;
    @media screen and (max-width: @mobileWidth) {
      font-size: @12px;
    }
  }

  .erase_account {
    display: flex;
    right: 0;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: right;
    color: @grey6;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    @media screen and (max-width: @mobileWidth) {
      font-size: @12px;
    }

    img {
      margin-left: .5rem;
      height: 18px;
    }
  }
}