@import "../../../../../../_variables";

.DialogContent {
  .Ad {
    .inner-container {
      padding: 0;
    }
  }
}

.Ad {
  display: flex;
  position: relative;
  flex-direction: row;

  &.simple {
    padding-top: 0;
    height: 100%;

    .inner-container {
      width: 100%;
      .content {
        height: 100%;
        width: 100%;
        min-width: unset;
        padding: 0 0 0 20px;

        h4.title {
          display: none;
        }

        .CloneInformation {
          display: none;
        }
      }
    }

    .section_content .scrollable{
      padding-bottom: 20px;
      padding-top: 15px;
    }

    .RoomPhotos {
      width: unset;
      .RoomPhotos__header {
        display: none;
      }

      .RoomPhotos__content {
        margin-top: 0;

        .RoomPhotos__choose-photos {
          max-width: unset;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .RoomPrices {
      width: 100%;

      form {
        margin-right: 0;
      }

      .OverviewCalendar,
      .overview-slots {
        position: absolute;
        top: 10px;
        right: 70px;
        max-height: 100%;
        @media screen and (max-height: 720px) {
          transform: scale(0.8);
          top: -30px;
          right: 30px;
          max-height: 120%;
        }
      }
    }

    .RoomDetailsHeader {
      display: none;
    }

    .LandlordRules .LandlordRules_container:first-child .input_container:first-child {
      margin-top: 0;
    }

    .PropertyAreas1 {
      #title {
        color: #434A5F;
        margin-bottom: 0;
      }
      #sub_title {
        display: none;
      }

      .AmountButtonsInputContainers {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%!important;
        column-gap: 30px;

        .AmountButtonsInputContainer {
          border-bottom: unset;
          flex: 1;
          min-width: 45%;

          p#info {
            display: none;
          }
        }
      }
    }

    .PropertyDetails, .LandlordRules {
      margin-left: 0;
    }

    .PropertyDetails2 {
      form {
        .scrollable{
          div.input_container, div.input_container_double {
            max-width: 700px;
          }
        }
      }
    }

    .PropertyDetails2,.PropertyAreas2,.LandlordRules3,.LandlordRules5,.PropertyAreas1{
      width: 100%;

      form {
        .scrollable{
          padding-right: 20%;
        }
      }
    }

    .RoomAmenities .scrollable > h5 {
      margin-top: 0;
      font-weight: 700;
      margin-bottom: 10px;
      font-size: 0.813rem;
      color: #434A5F;
    }

    .Photographs{
      padding-right: 0;

      .Photographs__header{
        width: fit-content;
        position: absolute;
        right: 40px;

        > div:first-child {
          display: none;
        }
      }

      .Photographs__header-choose {
        margin-bottom: 25px;
      }

      .Photographs__content {
        margin-top: 0;
        padding-right: 10px;

        .ChooseImages{
          max-width: 900px;

          &:not(.no-images) {
            width: calc(100% - 40px);
            &:first-child {
              margin-top: 0;
            }
          }

          .ChooseImages__change-image-full{
            width: 100%;
            max-width: unset;
          }
        }
      }

    }

    .CommonAreasDetails {
      width: 100%;
    }

    .CommonAreaComponent__header {
      display: none;
      //display: flex;
      //flex-direction: row-reverse;
      //align-items: end;
      //justify-content: space-between;
      //.CommonAreaComponent__header-title {
      //  display: block;
      //  width: 100px;
      //  margin-right: 15px;
      //}
      //
      //.CommonAreaComponent__header-subtitle {
      //  margin-top: 0;
      //}
    }

    .Buttons{
      display: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .side-container {
    min-width: 310px;
    position: fixed;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .sidebar {
    border-right: 1px @greySoft solid;
    font-size: 0.813rem;
    min-width: 310px;
    transition: transform 0.6s;
    //position: fixed;
    width: 310px;
    max-height: calc(100vh - 180px);
    overflow-y: auto;

    &.active {
      transform: translateY(-100px);
      transition: transform 1s;
    }

    .links {
      display: flex;
      flex-direction: column;
      padding: 20px 15px;

      > div:not(:first-child) {
        margin-top: 30px;
      }


      .links__main_label {
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 0.813rem;

        img {
          margin-right: 12px;
        }

        p {
          font-weight: bold;
        }
      }


      button {
        width: 90%;
        display: block;
        text-decoration: none;
        color: black;
        border: none;
        background-color: white;
        transition: border-right-color 0.3s;
        text-align: left;
        padding: 6px 0 6px 10px;
        margin-left: 15px;
        cursor: pointer;

        &:disabled {
          color: @grey;
          cursor: default;
        }

        &.active {
          font-weight: bold;
          color: @blue;
          background-color: @blueSoft;
          cursor: pointer;
        }

      }
    }
  }

  .inner-container {
    flex-direction: row;
    padding: 0 0 0 310px;
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .content {
    padding-left: 45px;

    > div {
      display: flex;
      flex-direction: column;
      min-width: 50vw;

      &:not(.Photographs):not(.RoomPhotos) {
        > div {
          display: flex;
          height: 100%;
          flex-direction: column;
        }
      }

    }


    .title {
      width: 80%;
      border-bottom: 1px dashed @greyMiddle;
      padding-bottom: 7px;
      margin-bottom: 15px;
      font-size: @18px;
      color: @grey6;
    }
  }

  .section_content {
    flex: 1;
    display:flex;
    max-width: 660px;

    &:not(.Photographs):not(.RoomPhotos){
      > div {
        height: unset;
      }
    }

    form {
      display: flex;
      flex-direction: column;
    }

    .scrollable {
      flex: 1;
      padding-left: 2px;
      width: 100%;
    }
  }

  .scrollable {
  }

  .progress_circle {
    flex: 0.1;
    min-width: 100px;
    padding-top: 15px;
    margin-left: 10px;
    background-color: transparent;
    position: absolute;
    top: -45px;
    right: 15px;

    .circle_reactGradientProgress__1_0UG {
      background-color: white;
      box-shadow: 0 4px 40px rgba(202, 202, 202, 0.25);
    }

    .circle_progressCircleBar__1IKUZ {
      transform: none;
    }

  }
}
