@import "../../../../../../../../../../../_variables";

.PropertyDetails2{

  > form > .scrollable {
    div.input_container, div.input_container_double {
      max-width: 550px;
      .map_header{
        display: flex;
        justify-content: space-between;
        margin-bottom: .7rem;
        label{
          margin-right: 1rem;
          strong{
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .map_preview{
    display: inline-flex;
    justify-content:center;
    align-items:center;
    /*
     Blasting Circle Styles
    */
    @keyframes blast {
      0% {
        opacity: var(--loader-initial-scale, 0.5);
        transform: scale(var(--loader-initial-scale, 0.5));
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    .loader.blasting-circle {
      width: var(--loader-width, 60px);
      height: var(--loader-height, 60px);
      border-radius: 80%;
      background-color: var(--loader-color-primary, @blue);
      opacity:50%;
      animation: blast var(--animation-duration, 2s) infinite ease-out;
    }

  }
}

.Map{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  .marker{
    width: 16px;
    height: 16px;
    z-index: 5000;
    border-radius: 100px;
    background: linear-gradient(180deg, #1D9AFA 0%, #7080cd 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.9;
    transform: translate(-8px, -8px);
  }
}
