@import "../../_variables";

.MainPage{
  margin-top: 60px;
  flex:1;
  height: auto;
  background-color: @background;

  @media screen and (max-width: @mobileWidth) {
    max-width: 100vw;
    overflow-x: hidden;
  }

  &.limit-height.static {
    overflow: hidden;

    .HorizontalNavBar {
      height: 100%;
    }
    /*height: calc(100vh - 60px);

    &:not(.static) > div {
      height: calc(100% - 48px);
    }

    &.static > div {
      height: 100%;
    }*/
  }

  .HorizontalNavBar{
    //height:auto;
    //margin-bottom:50px;


    .HorizontalNavBar{
      .white_bg{
        margin-top: 0px;
      }

      #horizontalbar_content{
        //margin-top: 40px;

        @media screen and (max-width: @mobileWidth) {
          margin-bottom: 2.5rem;
        }
      }
    }
  }

}


