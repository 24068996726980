@import "../../_variables";

.Select {

  .Select__control {
    max-height: 36px;
    cursor: text;

    &.Select__control--is-focused {
      border: 1px solid @blueFocus !important;
    }

    &:hover {
      border-color: @greyHover;
    }
  }

  &.input_field {
    padding-left: 0px;
  }

  &.input_error {

    .Select__control {
      border: 1px solid @redBorder;
      background-color: @redBackGround;
      border-radius: 5px;
    }
  }

  .Select__option--is-focused {
    background-color: rgba(29, 154, 250, 0.06);
    cursor: pointer;
  }

  .Select__value-container {
    padding-left: 10px;
  }

  .Select__control:hover {
    border: 1px solid #8C9BA5;
  }


  :not(.disabled) {
    .Select__menu-list {
      max-height: 200px !important;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
      padding-right: 0;

      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @blue;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @blue;
      }
    }
  }

  .disabled{
    .Select__menu-list {
      > :not(.checked) {
        :hover {
          cursor: default;
        }

        :active {
          background: white;
        }
      }
    }
  }

  .SelectOptionContainer {
    width: 100%;

    > .Select__option {
      width: 100%;
      display: inline-flex;
      align-items: center;

      label {
        margin-left: 5px;
        text-align: left;

        &.not-selected {
          margin-left: 12px;
          margin-right: 12px;
        }
      }
    }

    &.disabled {
      background: @grey1 !important;
      color: @grey3 !important;

    }

    &.selected {
      background: @blueSoft !important;
      color: @blue !important;
    }

    &:not(.disabled) {
      &:not(.selected) {
        &:hover {
          background: @blueSuperSoft;
        }
      }
    }

    svg {
      color: @blue;
    }
  }

  .Select__single-value {
    color: @blue;
  }


}

.ReactSelectMulti {

  .Select__control {
    max-height: fit-content !important;

    .Select__value-container {

      .Select__multi-value {
        background-color: @blueSoft;

        .Select__multi-value__label {
          font-size: 100%;
          padding: 2px 10px;
          background-color: rgba(29, 154, 250, 0.1);
        }


        .Select__multi-value__remove {
          display: none;

          &:hover, &:focus {
            background-color: @blueSoft;
            box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
            cursor: pointer;

            svg {
              color: black;
            }
          }
        }
      }


    }


  }

  .Select__option__multi {
    display: inline-flex;
    align-items: center;

    label {
      margin-left: 5px;
    }

    :hover {
      cursor: pointer;
    }

  }
}

.Select__menu:after {
  content: '';
  background: transparent;
  position: absolute;
  bottom: -10px;
  height: 1px;
  width: 100%;
}
