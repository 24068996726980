@import "../../../../../../../../../../_variables";

.StepperBar{
  width: 100%;
  height: 65px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  .StepperBar__button{
    width: 30px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    button{
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border:none;
      background-color:transparent;

      &:hover{
        cursor: pointer;
      }

      &:disabled{
        opacity: 0.5;
        cursor: default;
      }

      svg{
        polyline{
          stroke:@grey4;
        }
      }
    }
  }

  .StepperBar__bar{
    align-items: center;
    display: inline-flex;
    flex:1;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0 40px;
    scrollbar-width: none;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */


    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .StepperBar__bar-step{
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 33.3%;
      height: 65px;

      .StepperBar__bar-step__step{
        min-height: 16px;
        width: 100%;
        display: inline-flex;
        align-items: center;

        .StepperBar__bar-step__step__circle{
          min-width:12px;
          min-height:12px;
          background-color: @grey3;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .StepperBar__bar-step__step__circle-icon{
            width: 100%;
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        .StepperBar__bar-step__step__line{
          width: 100%;
          height: 2px;
          background: @grey3;
        }
      }

      .StepperBar__bar-step__title{
        margin-top:5px;
        margin-bottom:5px;
      }

      .StepperBar__bar-step__info{
        color:@grey4;

        .StepperBar__bar-step__info-edit{
          color:@blue2;
          text-decoration: underline;
        }
      }

      &.completed{
        .StepperBar__bar-step__step__circle{
          background-color: @green;
          border-radius: 50%;
        }

        .StepperBar__bar-step__step__line{
          width: 100%;
          height: 2px;
          background: @grey3;
        }

        .StepperBar__bar-step__info-edit{
          color:@blue;
          text-decoration: underline;

          &:hover{
            cursor: pointer;
          }
        }
      }

      &.active{
        .StepperBar__bar-step__step__circle{
          min-width:14px;
          min-height:14px;
          background-color: @blue;
          border-radius: 50%;
        }

        .StepperBar__bar-step__step__line{
          width: 100%;
          height: 2px;
          background: @grey3;
        }

        .StepperBar__bar-step__title{
          color:@blue2;

        }

        .StepperBar__bar-step__info {
          color: @grey5;
        }

      }


    }

  }

}