@import "../../_variables";

.SearchFilter {
  display: flex;
  width: 250px;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;

  @media screen and (max-width: @mobileWidth) {
    width: 100%;
  }

  input {
    width: 100%;
    color: #8C9BA5;
    font-size: 0.875rem;
    font-weight: 500;
    border: none;

    ::placeholder {
      color: #8C9BA5;
    }
  }

  /* ::placeholder { Chrome, Firefox, Opera, Safari 10.1+
    color: rgba(24, 39, 56, 0.94);
    opacity: 1; Firefox
  }

  :-ms-input-placeholder { Internet Explorer 10-11
    color: rgba(24, 39, 56, 0.94);
  }

  ::-ms-input-placeholder { Microsoft Edge
    color: rgba(24, 39, 56, 0.94);
  } */
}