@import "../../_variables";

.ListItem{
  display: inline-flex;
  background-color: white;
  height: 12.5rem;
}

.propertyPhoto{
  height: 100%;
  width: 140px;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 10px;
}

.ListItem__content{
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: 15px 25px 0 40px;
  flex: 1;
}

.spaced-icon{
  width: 30px;
}

//Actions
.Actions{
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;


  .Actions__buttons{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 65px;

    /* img{
      margin-right: 10px;
    } */
  }

  /* button.btn, a.btn {
    padding-left: 10px;
    padding-right: 15px;
    text-decoration: none;
    white-space: nowrap;
  } */

  .link{
    display: inline-flex;

    img{
      margin-right: 5px;
    }
  }
}
