@import "../../../../../../_variables";

.MyAccount__Notifications {
  display: flex;
  gap: 1.5rem;

  h5 {
    margin: unset;
  }

  @media screen and (max-width: @mobileWidth) {
    flex-direction: column;
  }

  .AccountDetails__container {
    .FiltersContainer__Container,  .endSession{
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }
  }

  .Notifications__container {
    display: flex;
    width: 100%;
    gap: 1.5rem;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      margin-bottom: 5rem;
    }
  }

  .MyAccount__Notifications__options-nav {
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-width: 21.9rem;
    gap: 1rem;

    h5 {
      color: #284F83;
      font-size: 1.125rem;
      font-weight: 700;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }

    .options__nav {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 0.5rem;

      @media screen and (max-width: @mobileWidth) {
        width: 100vw;
        flex-direction: row;
        margin-left: 0;
        position: absolute;
        left: 0;
        overflow-x: scroll;
        z-index: 10;
      }

      .notification__nav {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-items: center;
        font-size: @16px;
        color: @grey6;
        line-height: 22px;
        font-weight: 500;
        justify-content: space-between;
        padding: 14px 16px;
        cursor: pointer;

        @media screen and (max-width: @mobileWidth) {
          width: 100vw;
        }

        h5 {
          display: block;
          color: #434A5F;
          font-size: 1rem;
          font-weight: 700;
        }
  
        &.active {
          background-color: #EEF7FE;
  
          h5 {
            color: #1D9AFA;
          }
        }
  
        &:hover {
          background-color: #EEF7FE;
        }
  
        /* @media screen and (max-width: @mobileWidth) {
          font-size: @14px;
          padding: 10px 12px;
        } */
  
        svg {
          @media screen and (max-width: @mobileWidth) {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .MyAccount__Notifications_sections {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
      flex-direction: row;
      margin-left: 0;
      margin-top: 66px;
      width: 100%;
      justify-content: space-between;
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h5 {
        color: #284F83;
        font-size: 1rem;
        font-weight: 700;
      }

      .section__options {
        display: flex;
        flex-direction: column;
        max-height: fit-content;
        gap: 0.5rem;
        min-width: 15.1rem;

        @media screen and (max-width: @mobileWidth) {
          margin-right: 0;
          margin-bottom: 0;
        }

        >span:first-child {
          font-size: @13px;
          color: @grey6;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 25px;
        }

        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #434A5F;
          font-size: 0.875rem;
          font-weight: 500;
          background: white;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          width: 100%;

          .switch {
            display: inline-flex;
            width: fit-content;

            > input {
              height: 0;
              width: 0;
              visibility: hidden;
            }

            > label {
              cursor: pointer;
              width: 60px;
              height: 26px;
              background: @grey4;
              display: block;
              border-radius: 13px;
              position: relative;
            }
          }

          .switch input[type=checkbox]:checked + label {
            background: @blue3;
          }

          .switch input[type=checkbox]:checked + label::after {
            left: calc(100% - 1px);
            transform: translateX(-100%);
          }

          .switch label::after {
            content: "";
            position: absolute;
            top: calc(50% - 24px / 2);
            left: 1px;
            width: 24px;
            height: 24px;
            background: white;
            border-radius: 16px;
            transition: 0.3s;
          }
        }
      }
    }
  }
}
