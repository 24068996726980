@import "../../_variables";

.PaginationLegacy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //margin-bottom: 1rem;

  .total-pages {
    display: flex;
    background: white;
    border-radius: 0.5rem;
  }

  .PageButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    border: unset;
    padding: 0.25rem;
    background-color: white;
    font-weight: 500;

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &.active {
      background-color: @blue2;
      color: white;
    }
  }

  .total-listings {
    font-size: 14px;
    margin-right: 24px;
  }
}
