@import "../../../../_variables";

.IntroductionContainer{
  width: 100%;
  color:white;
  min-width: 340px;
  overflow-y: auto;
  padding: 3rem 10rem 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .IntroductionContainer__title {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    h1 {
      font-size: 1.75rem;
      font-weight: 700;
    }

    h2 {
      font-size: 1.375rem;
      font-weight: 700;
    }

    @media screen and (max-height: 768px) {
      margin-bottom: 20px;
    }
  }

  .IntroductionContainer__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .IntroductionContainer__container {
      display: flex;
      justify-content: flex-start;
      gap: 1.125rem;
  
      h3 {
        font-weight: 700;
        font-size: 1.125rem;
        color: white;
      }
  
      p {
        font-size: 0.875rem;
        font-weight: 400;
      }

      .icon-container {
        display: inline-block;
        position: relative;
      }
      
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: white;
        width: 2.5rem;
        height: 2.5rem;
        position: relative;
        color: #1D9AFA;
      
        > svg {
          margin: 0.25rem;
          fill: currentColor;
        }
      }
  
      .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
