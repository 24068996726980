.CloneInformation{
  display:inline-flex;
  align-items: center;
  justify-content:space-between;
  padding-bottom: 10px;
  width:100%;

  label{
    font-weight: 700;
    margin-bottom: 5px;
  }

  .css-2b097c-container{
    width:37%;

    .Select__placeholder{

      p{
        display:inline-flex;
        align-items: center;
        font-size:0.9rem;

        svg{
          width:16px;
          height:16px;
          margin-right:5px;
        }
      }

    }
  }


}