@import "../../_variables";

.AccountDetails__ApiKeys {
    padding: 15px;
    background: @white;
    border-radius: 8px;
    box-shadow: @box_shadow;

    .ApiKeys_container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .ApiKey_block {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            background: @grey1;
            border-radius: 4px;
            padding: 10px;

            .ApiKey_header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                    margin: 0;
                }

                > div {
                    display: flex;

                    .ApiKey_header_last_used {
                        display: flex;
                        flex-direction: column;
                        margin-right: 15px;
                        width: 200px;
                        padding-top: 5px;

                        small {
                            font-size: @11px;
                            color: @grey5;
                            line-height: 0.5;
                        }
                    }
                }
            }

            .ApiKey_content {
                display: flex;
                padding-top: 15px;

                .ApiKey_content_main {
                    flex: 1;

                    > div {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 10px;
                    }

                    .ApiKey_secret {
                        width: 100%;
                        background: @grey2;
                        border-radius: 4px;
                        padding: 10px;
                        font-weight: bold;
                        font-size: @12px;
                    }

                    .ApiKey_secret_desc {
                        font-size: @11px;
                        color: @redError;
                    }
                }

                .ApiKey_content_side {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    button:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.ApiKeys_generate_dialog {
    .MuiDialog-container .MuiPaper-root {
        max-height: calc(100vh - 120px);
        height: fit-content;
        width: fit-content;
        max-width: calc(100vw - 20px);

        .DialogCloseButton {
            display: none;
        }
    }

    .ApiKeys_generate_dialog_content {
        padding: 20px;

        .input_container {
            padding-top: 5px;
            margin-bottom: 10px;

            label {
                margin-bottom: 0;
            }
        }

        .buttons_container {
            padding-top: 15px;
            display: flex;

            button:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
