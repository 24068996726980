@import "../../../../../../_variables";

.billing-history__container {
  display: flex;
  gap: 1.5rem;
  height: 100%;

  .AccountDetails__container {
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }
}

.invoices {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;

  @media screen and (max-width: @mobileWidth) {
    padding: 1.5rem 0;
    height: 100%;
    gap: 2rem;
  }

  .invoices__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;

    .invoices__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
  
      .invoices__header__title {
        margin: 0 0 24px 0;
        font-size: 16px;
        font-weight: 700;
        color: #284f83;
      }
  
      .invoices__header__actions {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 1rem;
  
        .invoices__header__actions__item {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;
  
          > label {
            font-size: 0.875rem;
            font-weight: 700;
            color: #284f83;
          }
  
          .invoices__header__actions__item__input {
            position: relative;
          }

          .datepicker__input__icon-wrapper {
            padding-left: 0.5rem;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            gap: 13px;
          }

          .datepicker__input {
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px;
            gap: 16px;
            background: #ffffff;
            border: 1px solid #dfe3e6;
            border-radius: 8px;
          }
        }
  
        .btn {
          width: 200px;
        }
  
        .react-datepicker-wrapper {
          width: 100%;
        }
  
        .react-datepicker__close-icon {
          display: none;
        }
      }
    }

    .invoices__list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex-grow: 1;
      
      .invoices__list__container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        @media screen and (max-width: @mobileWidth) {
          gap: 0;

          .btn {
            width: 100%;
          }
        }

        .invoices__list__container__item {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem 2rem;
          background: #FFFFFF;
          border: 1px dashed rgba(28, 55, 89, 0.1);
          gap: 1rem;

          .invoices__list__container__item__content {
            display: flex;
            gap: 1rem;

            @media screen and (max-width: @mobileWidth) {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
          }
        }

        @media screen and (max-width: @mobileWidth) {
          margin: 0;
          flex-direction: column;
          min-width: 100%;
        }

        .invoiceContainer {
          margin-right: 2rem;
          background: @white;
          border: 1px dashed rgba(28, 55, 89, 0.16);
          border-radius: 4px;
          padding: 1.5rem 5rem;
          color: #8c9ba5;
          font-weight: 400;
          font-size: 14px;
          display: flex;
          align-items: center;
          min-width: 70%;

          @media screen and (max-width: @mobileWidth) {
            padding: 1.5rem 1rem;
            margin-right: 0;
            min-width: 100%;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
          }

          img {
            width: 22px;
            height: 22px;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
