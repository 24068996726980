@import "../../../../../../_variables.less";

.timeline-container {
  width: 100%;
  display: flex;
  flex-direction: row;

  /* .timeline-item:first-child, .timeline-item:last-child {
    background: red;
  } */

  .timeline-icon {
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .timeline-icon-line {
      display: flex;
      flex-direction: column;
      width: 5px;
      border-radius: 8px;
      background-color: white;
      height: 2rem;
    }

    .timeline-icon-double-line {
      display: flex;
      flex-direction: column;
      width: 5px;
      border-radius: 8px;
      background-color: white;
      height: 4rem;
    }

    .timeline-item-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1D9AFA;

      .door-icon {
        fill: currentColor;
      }

      > svg {
        margin: 0.5rem 0;
      }
    }

    .padding {
      padding: 0 0.5rem;
    }
  }

  .timeline-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .timeline-item__content {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.80rem 1rem;

      span {
        color: #434A5F;
        font-size: 1rem;
        font-weight: 700;

        @media screen and (max-width: @mobileWidth) {
          font-size: 0.875rem;
        }
      }
  
      .timeline-item__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          color: #5A6872;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    .ellipsis {
      display: flex;
      align-items: flex-start;
      color: #434A5F;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.btn-container {
  align-self: end;
}
