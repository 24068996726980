@import "../../_variables";

.PhoneInput {
  display: flex;
  min-height: 40px;
  max-height: 40px;
  border: 1px solid #DFE3E6;
  box-shadow: 0 4px 40px rgba(196, 196, 196, 0.25);
  border-radius: 4px;
  color: #8C9BA5;
  background-color: white;
  position: relative;
  margin-bottom: 20px;

  &:hover {
    border-color: #94a0af;
  }

  >img {
    width: 15px;
    position: absolute;
    right: 3%;
    bottom: 30%;
  }

  .input_field {
    border: unset;
    background-color: unset;
    color: @grey4;
    height: unset;

    &:disabled {
      background-color: unset;
    }
    &:focus {
      border: unset;
    }
    .input-select__control {
      background-color: unset;
      border: unset;
      box-shadow: unset;
      min-height: unset;
      height: 36px;
      top: 10px;

      .flag-control-wrapper {
        display: flex;
        align-items: center;
        >svg {
          margin-right: 0px;
        }
        .input-select__value-container {
          width: 50px;
          .input-select__single-value {
            color: @grey4;
          }
        }

        .input-select__indicators {
          .input-select__indicator {
            >svg {
              margin-left: unset;
              margin-right: unset;
            }
          }
          .input-select__indicator-separator {
            display: none;
          }
        }
      }
    }
    .input-select__menu {
      .flag-option-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        >img {
          position: unset;
          max-height: 11px;
          max-width: 16px;
        }
      }
    }
  }
}