.PropertyAreas1{
  display:flex;
  flex-direction:column;

  .scrollable {
    > div {
      width: 90%;
    }
  }

  >label{
    margin-bottom:40px;
  }

  #title{
    font-size:1rem;
    margin-bottom:20px;
  }

  #sub_title{
    font-size:0.82rem;
    margin-bottom:20px;
  }


  .AmountButtonsInputContainers{

    >div:last-child {
      border-bottom:none;
    }
  }
}