@import "../../_variables";

.MuiDialogTitle-root.DialogHeader {
  padding-bottom: 0;

  .DialogTitle {
    margin-bottom: 5px;

    span {
      color: @blue;
    }
  }
}

.DialogCloseButton > .MuiIconButton-label {
  transform: scaleY(0.85);
}
