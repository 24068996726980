.MUIMenu {
  border:none;
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 5px;

  &.relative{
    position: relative;
    div{
      transform: translate3d(-29px, -7px, 0px)!important;
    }
  }

  .menu-img{
    background: url(images/moreOptionsIcon.svg) no-repeat center;
    margin-top: 15px;
    min-height: 24px;

    &.icon_circle{
      background: url(images/moreOptionsIconCircle.svg) no-repeat;
      margin-top: 0px;
    }
  }

  .full_link {
    width: 100%;
    height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    text-decoration: none;
    color: inherit;
  }
}
