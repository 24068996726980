.VisitWeekDays{
  display:flex;
  flex-direction:column;
  width: 100%;
  padding-top: 15px;
  padding-left: 10px;

  .VisitWeekDay{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  .SwitchContainer{
    align-items: flex-start;
    display: inline-flex;
    color:black;
    font-weight: 500;
    justify-content: space-between;
    margin-right: 20px;
    padding: 0;
    width: 200px;

    &:not(:first-child){
      padding: 7px 0px;
    }
  }
}