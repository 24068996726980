@import "../../../../../../../../../../../_variables";

.TimesPickers{
  width:100%;

  >div{
    &:first-child{
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      width:100%;

      >div{
        display:flex;
        flex-direction:column;

        &:not(:first-child){
          margin-top:10px;
        }

        >div{
          width:100%;
          display:inline-flex;
          justify-content:space-between;


          >div{
            width:47%;
          }
        }
      }

    }

    #close_button{
      margin-right:-55px;

      svg{
        margin-right:0px;
      }
    }
  }

  >.btn{
    width:100%;
    margin-top:10px;
    background:@grey1;
    color:@grey;
    border:1px solid @greyMiddle;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    p{
      font-size: 0.8rem;
    }

    svg{
      color:@grey;
    }
  }


}