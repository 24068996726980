@import "../../_variables";

.MuiDialog-container {

  .MuiPaper-root {
    height: calc(100vh - 100px);
    width: 90vw;
    max-width: 1200px;
  }

  .DialogCloseButton > .MuiIconButton-label {
    transform: scaleY(0.85);
  }
}


.DialogContent {
  height: calc(100% - 125px);
  overflow-y: scroll;

  &.double-nav {
    height: calc(100% - 151px);
  }
}

.DialogNav {
  display: block;
  width: calc(100% + 48px);
  margin-left: -24px;
  overflow-x: auto;
  border-bottom: 1px solid #ededed;

  &::-webkit-scrollbar {
    height: 3px;
  }

  .DialogNav__container{
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    padding-left: 6px;

    .DialogNav__shortcut {
      display: flex;
      font-size: @12px;
      padding: 4px 10px 0;
      margin-left: 5px;
      position: relative;

      &:not(.active) {
        cursor: pointer;
        label {
          cursor: pointer;
        }
      }

      label {
        color: @textSecondary;
        padding-bottom: 4px;
      }

      .shortcut-sub-container {
        display: none;
        margin: -4px -5px 0 10px;
        .shortcut-sub {
          padding: 4px 10px;

          &:not(.active) {
            cursor: pointer;
            > label {
              cursor: pointer;
            }
          }

          > label {
            padding-bottom: 5.5px;
          }

          &.active {

          }
        }
      }

      &.active{
        .shortcut-sub-container {
          display: flex;
        }
      }

      &:not(:last-child){
        margin-right: 5px;
      }
    }

    .DialogNav__shortcut.active, .shortcut-sub.active {
      > label {
        color: #18A0FB;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 50%;
          height: 2px;
          background-color: #18A0FB;
          left: 25%;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }

      .shortcut-sub-container {
        display: flex;
      }
    }
  }

}
