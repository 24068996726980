@import "../../_variables";

.IconContainerWithDescription {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-right: 10px;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 30px;
    max-width: 40px;
    img{
      max-width: 30px;
    }
  }


  .description-wrapper {
    display: flex;
    flex-wrap: wrap;

    label, p {
      margin-right: 5px;
      white-space: nowrap;
      font-size: @12px;

    }

    p{
      font-size: @16px;
    }

    label {
      font-weight: bold;
    }
  }
}

