@import "../../../../_variables";

.SignUpContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .with-email-separator-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    hr {
      flex-grow: 1;
      height: 1px;
      background-color: #DFE3E6;
      border: none;
      margin: 0;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: #8C9BA5;
      white-space: nowrap;
    }
  }

  .input-container {
    > div {
      display: flex;
      height: 40px;
      padding: 0.5rem 1rem;
      align-items: center;
      gap: 1rem;
      align-self: stretch;
      border-radius: 0.5rem;
      border: 1px solid #DFE3E6;
      background: white;
      box-shadow: 0 4px 40px rgba(196, 196, 196, 0.25);
    }
  }

  .signup__email {
    display: flex;
    flex-direction: column;
    height: fit-content;

    > * {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;

      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;

        &.input_error {
          border: 1px solid @redError;
        }
      }

    }
  }

  #terms_conditions {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;

    p,.link {
      font-size: @10px;
      text-decoration: none;
    }
    
    @media screen and (max-width: @mobileWidth) {
      text-align: center;
    }
  }

  .CountryAndPhoneSelect {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .PhoneInput {
      margin: 0;

      .PhoneInputInput {
        height: unset;
        padding: 0;
      }
    }

    .ReactFlagsSelect-module_flagsSelect__2pfa2 ul {
      width: 100%;
      top: 2.5rem;
      overflow-x: hidden;
      border-radius: 0.5rem;
    }
  }

  .blue-link-label {
    text-decoration: none;
    color: #1D9AFA;
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
      color: #57B4F2;
    }

    &:active {
      color: #2845C9;
    }
  }
}