@import "../../_variables";

.CustomDatePicker {
  width: 100%;
  position: relative;
  @media screen and (max-width: @mobileWidth) {
    font-size: @10px;
    width: 100%!important;
  }

  input {
    height: 36px;
    width: 100%;
    font-size: 16px !important;
    padding: 8px;
  }


  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    z-index: 990;
    height: 290px;

    .react-datepicker__header {
      background-color: @grey2;

    }


    .react-datepicker__day, .react-datepicker__day-name {
      width: 36px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: @mobileWidth) {
        width: 26px;
        height: 20px;
      }
    }

    .react-datepicker__day {
      &:hover {
        background: #CBD0D6;
        border-radius: 4px;
      }

      &.react-datepicker__day--disabled {
        &:hover {
          background: #f4f4f4;
        }
      }

      &.react-datepicker__day--selected {
        border: 1px solid #00D3FF;
        background: #E1EDF5;
        color: #066AE3;
      }

      &.react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
      }

      &.react-datepicker__day--keyboard-selected {
        background-color: white;
        color: black;
      }
    }
  }

  .react-datepicker-popper{
    z-index: 110;
  }

  img {
    position: absolute;
    top: 9px;
    right: 20px;
  }


}

.react-datepicker {
  .react-datepicker__navigation {
    width: 15px;
    height: 15px;
    border: none;
  }

  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    display: none;
  }

  .react-datepicker__navigation--previous {
    background: url(images/leftArrow.png) no-repeat;
  }

  .react-datepicker__navigation--next {
    background: url(images/rightArrow.png) no-repeat;
  }

  .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
    background-position: center center;
    width: 20px;
    height: 20px;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
    font-size: @14px;
    color: @grey6;
  }
}

