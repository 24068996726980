@import "../../../_variables";

.ResumeButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  
  @media screen and (max-width: @mobileWidth) {
    justify-content: center;
    width: unset;
  }

  &.align-right {
    align-items: flex-end;
    padding-right: 35px;
  }

  button {
    margin-bottom: 5px;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  img {
    margin-right: 3px;
  }

  .link_blue{
    font-size: @12px;
  }
}
