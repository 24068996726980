@import "../../_variables.less";

.drawer-container {
    position: absolute;
    z-index: 9;
    --transition-speed: 0.3s;
    transition: all ease-in-out var(--transition-speed);
  
  .drawer {
    background: #fff;
    width: 30%;
    /* height: 100%; */
    overflow: auto;
    position: fixed;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    transition: all var(--transition-speed) ease;
    z-index: 1000;
    border-radius: 2rem 2rem 0 0;
    padding: 1rem;
    font-size: 0.875rem;
    color: #5A6872;
    font-weight: 500;

    .btn {
      margin-top: 1rem;
    }
  }

  .drawer-handler {
    position: relative;
    padding: 1rem;

    &:before {
        position: absolute;
        content: "";
        background-color: rgba(232, 232, 232, 1);
        width: 50px;
        height: 5px;
        border-radius: 5px;
        left: calc(50% - 25px);
        top: 5px;
    }
  }

  .drawer.left {
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .drawer.right {
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
  
  .drawer.top {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 40%;
  }
  
  .drawer.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    /* height: min-content;
    min-height: calc(100vh - 75%);
    max-height: 75%; */
  }
  
  .backdrop {
    background: rgba(0, 0, 0, 0.5);
    transition: opacity var(--transition-speed) ease,
      visibility var(--transition-speed) ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 0;
  }
}

/*
    Only apply the box-shadow when open to prevent the shadow
    from appearing on the edge of the screen when inactive
*/
.drawer-container.open .drawer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
  
.drawer-container.in.open .backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
}
  
.drawer-container.in.open .left,
.drawer-container.in.open .right {
    transform: translateX(0);
}
  
.drawer-container.in.open .top,
.drawer-container.in.open .bottom {
    transform: translateY(0);
}

.drawer.modal {
  background-color: red;
}
