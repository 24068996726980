@import "../../_variables";

.Start {
  position: relative;
  /* background: url(./images/bg_image_main.jpg),#666666;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
  width: 50%;
  height:100vh;
  padding: 15px;
  z-index:-3;
  display:flex; */
  width: 90%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  margin: auto;

  @media screen and (max-width: @mobileWidth) {
    width: 100%;
  }
}

.background-introduction-container {
  position: relative;
  width: 50%;
  height: 100%;

  @media screen and (max-width: @mobileWidth) {
    display: none;
  }
}

.background-introduction-container .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./images/bg_image_main.jpg),#666666;
  background-size: cover;
  filter: brightness(0.6);
}

.background-introduction-container .introduction {
  position: absolute;
  height: 100%;
  padding-top: 60px;
  width: 100%;
  z-index: 1;
}

.auth-container {
  width: 50%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: @mobileWidth) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 1.5rem;
    background-color: #F0F3F6;
    margin-bottom: 2.5rem;
  }

  .HorizontalNavBar {
    display: flex;
    align-items: center;
    padding: 3rem 0 1.5rem 1.5rem;
    height: 100%;
    width: 100%;
    max-width: 40rem;

    @media screen and (max-width: @mobileWidth) {
      padding-right: 1.5rem;
    }

    .white_bg{
      position: inherit;
      background-color: transparent;
      margin-bottom: 10px;
      height: fit-content;
      @media screen and (max-width: @mobileWidth) {
        border-bottom: none;
      }
    }

    .horizontalbar{
      margin-bottom: 0;
      background: transparent;
    }

    #horizontalbar_content {
      flex:none;
      width: 100%;
      min-width: 30rem;
      height: fit-content;
      padding: 2.5rem 3rem;
      font-size: 0.875rem;
      font-weight: 400;
      color:black;
      border-radius: 0.5rem;

      @media screen and (max-width: @mobileWidth) {
        min-width: unset;
      }


      h3 {
        font-weight: 700;
      }

      .base-info {
        display:flex;
        flex-direction: column;
        gap: 1rem;
        flex-wrap:wrap;
        justify-content: space-between;

        >*:not(.error_message:last-child){
          display:flex;
          //flex-direction:column;
          //flex: 1;

          input {

            &.input_error{
              border: 1px solid @redBorder;
            }

            &:not(.PhoneInputInput) &:not(ReactFlagsSelect-module_filterBox__3m8EU) {
              width: 100%;
              margin-bottom: 15px;
              height: 36px;

            }

            //padding-left: 10px;

          }
        }
      }

      .base-info.row {
        display: flex;
        flex-direction: row;
      }

      #actions {
        width: 100%;
        display: flex;
        gap: 0.5rem;

        @media screen and (max-width: @mobileWidth) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        p {
          font-size: 0.875rem;
          font-weight: 500;
          color: #8C9BA5;
        }
      }
    }
  }
}
