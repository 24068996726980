@import '../../../../../../_variables';

.ReservationList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .page__header__title h3 {
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .ReservationList_noAds {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .reservations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
