@import "../../_variables";

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.51, 0.97);

  &.relative{
    position: relative;
    height: 50%;
    display: flex;
    width: 100%;
    z-index: 95;
  }

  .MuiCircularProgress-colorPrimary {
    color: @blue;
    animation-iteration-count: infinite!important;
    animation-duration: 1.4s!important;
  }
}
