@import "_reset";
@import './_variables.less';

body {
  overflow: initial!important;
}

html, body, #root {
  height: 100vh;
  width:100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: @grey6;

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0;
    border-radius: 0.5rem;

    @media screen and (max-width: @mobileWidth) {
      width: 0;
    }
  }

  //&::-webkit-scrollbar-track {
  //  background: #f1f1f1;
  //}

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: @dodger-blue;

  }

  ::-webkit-scrollbar-track {
    /* border-radius: 0.5rem;
    background: #DFE3E6; */
    display: none;
  }
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

