@import "../../../../_variables";

.SocialAccountButtons{
  display: flex;
  gap: 1rem;

  @media screen and (max-width: @mobileWidth) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  form {
    width: 100%;
  }

  .button-container {
    flex: 1;
    display: flex;

    @media screen and (max-width: @mobileWidth) {
      max-width: unset;
    }
  }

  button {
    font-size: 0.75rem;
    font-weight: 700;
    flex: 1;
    width: 100%;
  }
}
