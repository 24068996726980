@import "../../_variables";

.NotificationItem {
  display: flex;
  flex-direction: column;

  .Loading {
    top: 68px
  }

  .NotificationItem-title {
    display: flex;

    .bell-container {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(87, 180, 242, 0.3);
      margin-right: 8px;
    }
    .read {
      background-color: @greySoft;
      filter: grayscale(1);
    }
    >span {
      font-weight: 700;
      font-size: @13px;
      line-height: 18px;
      color: #434A5F;
      flex:1;
    }

    .delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
      &:hover {
        background-color: @redBackGround;
      }
    }
  }

  .NotificationItem-content {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 77px;
      width: 0px;
      border: 1.5px solid @grey2;
      background-color: @grey2;
      top: 10px;
      left: 8px;
    }
    .description {
      font-weight: 400;
      font-size: @12px;
      line-height: 20px;
      margin-left: 28px;
      min-height: 68px;
      color: @grey4;
    }

    >div:last-child {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      margin-left: 28px;

      .notification-date {
        font-weight: 700;
        font-size: @9px;
        line-height: 20px;
        color: #8C9BA5;
      }
    }

  }

  &:hover {
    .NotificationItem-title {
      .delete-icon {
        opacity: 1;
      }
    }
  }
}