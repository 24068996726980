@import "../../_variables";

.app-banner {
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-container {
    display: flex;
    background: #E9F5FF;
    border-radius: 10px;
    width: 65%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;

    @media screen and (max-width: @tableWidth) {
      display: grid;
    }
  }

  .modal-image-section {
    margin: auto;
    width: 440px;
    height: 480px;
    position: relative;

    @media screen and (max-width: @tableWidth) {
      width: 340px;
      height: 380px;
    }
  }

  .left-image {
    position: absolute;
    bottom: 0;
    width: 440px;
    height: 400px;

    @media screen and (max-width: @tableWidth) {
      width: 330px;
      height: 300px;
    }
  }

  .modal-content-section {
    padding: 20px;
    margin: auto;
  }

  .modal-close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    border: none;
    background: none;
    font-size: 1.5em;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 0.5em;
    color: #1D9AFA;
  }

  .modal-description {
    color: #0F4D7D;
  }

  .modal-feature-list {
    margin-top: 20px;
  }

  .modal-feature-list li {
    margin: 5px 0;
  }

  .modal-buttons {
    text-align: center;
    margin-bottom: 1em;
  }

  .modal-download-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .modal-download-button.google-play {
    background-color: #000;
  }

  .modal-dont-show-button {
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #1D9AFA;
    color: white;
    margin-right: 10px;
    border-radius: 50%;
    font-size: 18px;
  }

  .checked::before {
    content: "✓";
  }

  .download-section {
    padding: 20px;
    text-align: center;
  }

  .download-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .download-buttons img {
    height: 40px;
    margin: 0 10px;
  }

  .download-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .dismiss-link {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    display: block;
    margin-top: 10px;
    cursor: pointer;
  }

  // Mobile
  @media screen and (max-width: @mobileWidth) {
    background: #E9F5FF;
    width: 100%;
    position: relative;
    padding: 5px 5px 35px;
    margin: 80px auto -60px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .phones-img {
    width: 29%;
    float: left;
  }

  .phones-img img {
    width: 100px;
  }

  .banner-text {
    color: #0F4D7D;
    width: 69%;
    float: right;
  }

  .app-description {
    color: #1D9AFA;
  }

  .banner-text h5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .download-btn img {
    margin: auto;
  }
}
