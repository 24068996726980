.PropertyAreas2{

    .input_container{
      >label{
        font-size:0.95rem;
      }

      >.CheckBox{
        width: 47%;
      }
    }

    .commodities_checkboxes{
      .checkbox__label{
        font-weight:500;
      }
    }

  form{
    >div:first-child{
      .FieldSetCheckBoxes{

      }
    }

    fieldset {
      > label {
        margin-bottom: 5px;
      }
    }
  }

  #PropertyAreas__services{
    display:inline-flex;
    justify-content:space-between;
    width:100%;

    >div{
      padding-left:10px;
      width:48%;
    }

    .MuiFormGroup-root{
      >div{
        margin-top: 7px;
      }
    }
  }

  .radio_input{
    margin-top:15px;
  }
}
