@import "../../_variables";

.Static {
  margin-top: -48px;
  @media screen and (max-width: @mobileWidth) {
    margin-top: 0!important;
  }

  margin-bottom: 0!important;


  &.show-border {
    border-top: 1px solid #DFE3E6;
  }

  iframe {
    transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.51, 0.97);
    background-color: white;
    border: 0;
    width: 100vw;
    overflow-x: hidden;
    height: 100%;
    @media screen and (max-width: @mobileWidth) {
      height: 100vh;
    }
  }
}
