@import "../../../../../../../../../../../_variables";

.LandlordRules1 {

  .scrollable {
    > div {
      width: 90%;
    }
  }


  .input_container {

    .input_container_link {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
    }

    a {
      margin-top: 20px;
      color: @blue;
      font-size: 0.7rem;
    }

    #input_times {
      display: inline-flex;
      justify-content: space-between;

      .TimesPickers {
        width: 50%;
      }

    }

    .error_message{
      margin-top: 10px;
    }
  }
}

.VisitTypesModal{
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;


  .MuiDialog-container{
    width: 80vw;
    height: fit-content;
    max-width: 860px;

    .MuiPaper-root {
      padding: 40px 0;
      max-height: 630px;
      align-items: center;
      height: calc(100vh - 120px);
      min-width: 820px;
      border-radius: 8px;
    }

    .DialogContent{
      align-items: center;
      display: flex;
      flex-direction: column;
      flex:1;
      justify-content: space-between;
      padding: 0 20px;

      > h4 {
        color: @greyHover;
      }

      .scrollable{
        flex:1;
        padding-top: 15px;
        overflow-y: scroll;
      }
    }
  }



  .InformationContainers{
    align-items: flex-end;
    background: rgba(240, 243, 246, 0.3);
    display: inline-flex;
    height: 225px;
    justify-content: space-between;
    padding: 0 20px;
    width: 96%;
    margin-bottom: 30px;
    border-radius: 10px;

    .InformationContainer{
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 82%;
      max-width: 190px;

      h5 {
        margin-top: 10px;
        margin-bottom: 18px;
      }

      img{
        height: 64px;
      }
    }
  }

  .MuiDialogTitle-root{
    padding: 0;
  }

  .InformationParagraphs{

    >div{
      align-items: center;
      display: inline-flex;
      width: 80%;
      margin-bottom: 20px;

      p{
        text-align: left;
      }

      img{
        margin-right: 15px;
      }
    }
  }

  .btn{
    display: inline-block;
    width: 140px;
  }
}
