@import "../../../../../../../../_variables";

.AmountButtonsInput{
  height:36px;
  width: 100%;
  display:inline-flex;
  align-items: center;
  justify-content:space-between;
  border:1px solid @greyMiddle;
  background-color:white;
  font-size: 0.9rem;

  *{
    border:none;
  }

  >div{
    display:inline-flex;
    align-items: center;
    justify-content: center;
    flex:0.5;
    padding-right: 15px;

    input{
      align-items: center;
      display: inline-flex;
      text-align: center;
      width: 100%;
      height:32px;
      background-color:transparent;
      margin-left: 10px;

      &.input_error{
        border:none;
      }
    }


    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

  }

  &.focus{
    border: 1px solid @blueFocus !important;
  }

  button{
    background-color:transparent;
    width:40px;
    flex:0.3;

    &:not(:disabled){
      color:@blue;

      &:hover{
        cursor: pointer;
      }
    }


  }

}
