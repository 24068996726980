@import "../../../_variables";

//Reject Visit Modal

.RejectVisitModal {
  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 90vw;
    max-width: 700px;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .RejectVisit__content {
    display: flex;
    flex-direction: column;
    padding: 35px 30px 20px 30px;;
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }

    .RejectVisit__content-label {
      color: #000000;
      font-size: @18px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .RejectVisitModal__content-info{
      font-size: @14px;
      color: @grey6;
      margin-bottom: 34px;
    }
    .RejectVisit__content-select-motive {
      > label {
        font-size: 13px;
        font-weight: 600;
        color: @grey6;
        margin-bottom: 20px;
      }
      .select-motive {
        color: @grey5;
        margin-bottom: 60px;
        font-size: 16px !important;
      }
    }

    .line{
      display: flex;
      width: 100%;
      border-top: 1px solid @grey3;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .RejectVisitModal__content-inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 70px;

      >span {
        font-size: @14px;
      }

      .simple-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        >input {
          appearance: auto;
          width: fit-content;
        }
        >label {
          font-size: @13px;
          color: @grey6;
          font-weight: 600;
          margin-bottom: 9px;
        }
      }

      .radio-input {
        display: flex;
        margin-bottom: 7px;
        align-items: center;
        >input {
          appearance: auto;
          width: fit-content;
        }
        >label {
          font-size: @14px;
          color: @grey6;
          margin-left: 24px;
        }
      }

      .double-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        >div:last-child {
          margin-left: 23px;
          >span {
            font-size: @12px;
            color: @grey5;
          }
        }
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          >input {
            appearance: auto;
          }
          >label {
            margin-left: 6px;
            font-size: @13px;
            color: @grey6;
            font-weight: 600;
          }
        }
        .dates-info{
          display: flex;
          margin-bottom: 8px;
          color: @grey6;
          font-size: @12px;
          margin-left: 23px;
        }
        .new-dates{
          display: flex;
          >div:first-child{
            margin-right: 10px;
          }
          >div{
            display: flex;
            flex-direction: column;
            > label {
              > strong{
                font-size: 13px;
                font-weight: 600;
                color: @grey6;
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .NewDates-table {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        >div {
          display: flex;
          flex-direction: column;
          >label {
            font-size: @14px;
            color: @grey6;
            font-weight: 600;
            margin-bottom: 9px;
          }
          >input {
            appearance: auto;
            width: fit-content;
            margin-bottom: 8px;
          }
        }

      }
    }

    .RejectVisit__content-actions {
      display: flex;
      flex-direction: row;
      height: 100%;
      >button {
        display: flex;
        justify-content: center;
        width: 170px;

        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }

  }
}