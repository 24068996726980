@import "../../_variables";


.DropdownInput {
  position: relative;
  display: inline-block;
  flex: 1 auto;
  @media screen and (max-width: @mobileWidth) {
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.nav-auth-container{
      .trigger{
        > img{
          display: none;
        }
      }
  }


  .trigger {
    z-index: 15;
    position: relative;
    cursor: pointer;
    display: flex;

    .language-value {
      font-weight: 700;
      font-size: @14px;
      margin-left: 10px;

      &.active {
        color: @blue2;
      }
    }

    > img {
      margin-left: 8px;
    }

    &.button {
      .chevron {
        margin: 0;
        position: absolute;
        font-size: 20px;
        color: #434a5e;
        top: 5px;
        right: 3px;
        line-height: 0;
      }

    }
  }

  > .dropdown {
    z-index: 20;
  }

  .outside-click-listener {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }
}

#root > .dropdown {
  z-index: 1000;
}

.DropdownInput, #root { //for the portaling
  > .dropdown {
    position: absolute;
    background-color: white;
    padding: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 15%);
    border-radius: 15px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;
    max-width: 100vw;
    font-weight: 400;
    //top: 120%;
    //min-width: 100%;
    //left: 0;

    &.stripped {
      padding: 0;
    }

    &.dropdown-menu, &.notifications-dropdown{
      &.stripped {
        @media screen and (max-width: @mobileWidth) {
          opacity: 1;
          width: 100vw;
          height: calc(100vh - 60px);
          position: fixed;
          margin-top: 60px;
          box-shadow: none;
          border-radius: 0;
          inset: unset;
          transform: translateX(100vw)!important;
          transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        }
      }
      &.open{
        @media screen and (max-width: @mobileWidth) {
          transform: translateX(0)!important;
        }
      }
    }

    .form-container {
      > .input-field, > .multiple-selection-container {
        &:not(:first-child) {
          margin-top: 10px;
        }

        > label, > p {
          line-height: @14px;
          margin-bottom: 5px;
        }

        > .multiple-selection {
          > .selection-box {
            padding: 0 15px;
          }
        }

        > .input-select {
          > .input-select__menu {
            min-width: fit-content;
          }
        }
      }
    }

    &.language-dropdown {
      @media screen and (max-width: @mobileWidth) {
        width: 100vw;
        min-height: 60vh!important;
        margin-top: 0!important;
        inset: unset !important;
        position: fixed !important;
        bottom: -50vh !important;
        box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.3)!important;
        border-top-right-radius: 15px!important;
        border-top-left-radius: 15px!important;
        border-bottom-right-radius: 0!important;
        border-bottom-left-radius: 0!important;
        transform: none !important;
        transition: bottom 0.1s ease-in-out!important;
        height: fit-content!important;
      }

      &.open {
        @media screen and (max-width: @mobileWidth) {
          bottom: 0 !important;
        }
      }

      h5 {
        display: none;
        font-weight: 700;
        font-size: @16px;
        line-height: 22px;
        color: @textSecondary;
        margin: 0 0 1rem 26px;
        width: 100vw;

        &:before{
          content: "";
          background-color: @grey2;
          padding: 1.5px 2rem;
          position: absolute;
          border-radius: 10px;
          display: block;
          left: 50%;
          margin-bottom: 7px;
          transform: translate(-50%, -10px);
        }

        @media screen and (max-width: @mobileWidth) {
          display: block;
        }
      }
    }

    &.open {
      pointer-events: initial;
      opacity: 1;
      @media screen and (max-width: @mobileWidth) {
        width: 100vw;
        height: calc(100vh - 60px);
        position: fixed;
        margin-top: 60px;
        transform: none !important;
        box-shadow: none;
        border-radius: 0;
        inset: unset;
      }

      &.dates-mobile-fix {
        @media screen and (max-width: @mobileWidth) {
          inset: unset !important;
          left: 0 !important;
          width: 100%;
          box-shadow: 0 0 0 50vh rgba(0, 0, 0, 0.3);
        }

        @media screen and (max-width: @mobileWidth) and (min-height: 700px) {
          transform: translateY(40%) !important;
          top: 0 !important;
        }

        @media screen and (max-width: @mobileWidth) and (max-height: 700px) {
          transform: translateY(0) !important;
          top: 65px !important;
        }
      }
    }
  }


}

.language-dropdown {
  padding: 21px 31px 20px 0 !important;
}
