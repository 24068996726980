@import "../../../../../../../../../../../_variables";

.PropertyDetails5 {
  display: flex;
  flex-direction: column;

  .warning-text{
    color: @redError;
  }

  h6 {
    margin-bottom: .5rem;
    margin-top: 1rem;
  }

  form{
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}