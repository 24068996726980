@import "../../_variables";

.EmptyState {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 128px;
  background-color: white;
  box-shadow: @box_shadow;
  border-radius: 8px;
  margin-top: 1rem;

  .icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 102px;
    margin-left: 54px;
    @media screen and (max-width: @mobileWidth) {
      margin-right: 5%;
      margin-left: 5%;
      height: 36px;
    }
  }

  .potentials {
    background: rgba(195, 225, 249, 0.4)
  }
  .reservations {
    background: rgba(149, 117, 205, 0.1);
    @media screen and (max-width: @mobileWidth) {
     width: 55px;
    }
  }
  .visits {
    background: rgba(201, 50, 40, 0.1);
  }
  .ads, .offers {
    background: #E6F5E9;
  }
  .events {
    background: rgba(195, 225, 249, 0.8);
  }
  .favorites {
    background: rgba(238, 201, 113, 0.3);
  }

  .grey {
    background: rgba(223, 227, 230, 0.4) !important;
    >img {
      filter: invert(67%) sepia(8%) saturate(531%) hue-rotate(161deg) brightness(89%) contrast(93%);
    }
  }

  .text-info {
    display: flex;
    flex-direction: column;
    >p:first-child {
      font-size: @18px;
      color: @grey6;
      font-weight: 700;
    }
    >p:last-child {
      font-size: @12px;
      color: @grey4;
    }
  }

}