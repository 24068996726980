.RoomPhotos {
  width: 800px;


  .scrollable {
    width: 100%;
    height: fit-content;
  }


  .RoomPhotos__header {
    max-width: 680px;
    > * {
      width: 70%;
    }
  }

  .RoomPhotos__content {
    --gap: 25px;

    .RoomPhotos__choose-photos {
      margin-top: var(--gap);
      width: 100%;
      display: inline-flex;
      max-width: calc(100vw - 380px);

      .ChooseImages {
        width: calc(100% - var(--gap) - 215px);;
        max-width:700px;
      }

      .ChooseOnePhoto{
        margin-left: var(--gap);
      }
    }
  }

  .ProgressBarComponent {
    margin: 40px 0;
    width: 80%;
  }

}
