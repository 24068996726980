@import "../../../../../../_variables";

.ReservationDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ReservationDetailsNavBar__mobile{
    display: none;
    @media screen and (max-width: @mobileWidth) {
      display: block;
    }
  }

  .ReservationDetailsContainer__header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

h3 {
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  color: #284F83;
}


  .ReservationDetails {
    height: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: @mobileWidth) {
      padding: 1rem 0.5rem;
    }

    &.has-request-state {
      margin-top: 35px;
    }

    .ReservationDetails__wrapper {
      display: flex;
      gap: 1.5rem;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
        max-width: 100%;
      }
    }

    .ReservationDetails__header {
      display: flex;
      gap: 1.5rem;

      > h3 {
        color: #1D9AFA;
      }
    }

    .ReservationDetails__main-content {
      display: flex;
      gap: 1.5rem;
      height: 100%;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .ReservationDetails__house-reservation {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        gap: 1rem;

        .ReservationDetails__reservation-info {
          display: flex;
          gap: 2rem;
          justify-content: space-between;
          height: 100%;

          @media screen and (max-width: @mobileWidth) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .ReservationDetails__reservation-info__separator {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            @media screen and (max-width: @mobileWidth) {
              display: flex;
              flex-direction: row;
              padding: 0 1.5rem
            }

            .ReservationDetails__reservation-info__icon {
              display: flex;
              justify-content: center;
              padding: 0.35rem;
              align-items: center;
              border-radius: 100%;
              height: 5rem;
              width: 2.5rem;
              border: 1px solid #DFE3E6;

              svg {
                width: 100%;
              }

              @media screen and (max-width: @mobileWidth) {
                display: none;
              }
            }

            .ReservationDetails__reservation-info__separator__line {
              width: 1px;
              background-color: #DFE3E6;
              height: 100%;

              &:first-child {
                @media screen and (max-width: @mobileWidth) {
                  display: none;
                }
              }

              @media screen and (max-width: @mobileWidth) {
                width: 100%;
                height: 1px;
                padding: 0 1.5rem;
              }
            }
          }

          .ReservationDetails__property {
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1.5rem;

            @media screen and (max-width: @mobileWidth) {
              margin-right: 0;
              gap: 0;
            }

            .ReservationDetails__property-photo {
              /* height: 6.25rem; */
              min-height: 6.25rem;
              height: 100%;
              width: 100%;
              background-position: center;
              background-size: cover;
              border-radius: 5px;
              padding: 10px;
              background-color: #1D9AFA;
              cursor: pointer;
              transition: all 0.2s ease-in-out;

              &:hover {
                opacity: 0.5s;
              }

              @media screen and (max-width: @mobileWidth) {
                border-radius: 0.25rem 0.25rem 0rem 0rem;
              }
            }

            .ReservationDetails__property-details {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              @media screen and (max-width: @mobileWidth) {
                padding: 1rem 1.5rem;
              }

              > span {
                font-size: 0.75rem;
                font-weight: 500;
                color: #5A6872;

                > strong {
                  color: #284F83;
                  font-size: 1rem;
                  font-weight: 700;
                  cursor: pointer;
                  transition: all ease-in-out 0.2s;

                  &:hover {
                    color: #1D9AFA;
                  }
                }
              }

              .rent {
                display: flex;
                gap: 0.25rem;

                > span {
                  color:#5A6872;
                  font-size: 0.875rem;
                  font-weight: 700;
                }

                > h5 {
                  margin: 0;
                  font-size: 1rem;
                  font-weight: 700;
                  color: #57B4F2;
                }
              }
            }
          }

          .ReservationDetails__tenant {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            @media screen and (max-width: @mobileWidth) {
              gap: 1rem;
              align-items: flex-start;
              padding: 1rem 1.5rem 1.5rem 1.5rem;
            }

            .ReservationDetails__tenant__avatar {
              display: flex;
              flex-direction: column;
              align-items: center;
              min-height: 84px;
              gap: 1.5rem;

              @media screen and (max-width: @mobileWidth) {
                display: flex;
                gap: 1rem;
                flex-direction: row;
                align-items: flex-end;
              }

              h5 {
                color: #284F83;
                font-size: 1rem;
                font-weight: 700;
                text-align: center;
                margin: 0;

                @media screen and (max-width: @mobileWidth) {
                  text-align: left;
                }
              }

              .avatar-container {
                margin: 0;
                width: 5rem;
                height: 5rem;
                box-shadow: 0px 15px 40px 0px rgba(40, 79, 131, 0.25);
                background-origin: unset;

                @media screen and (max-width: @mobileWidth) {
                  margin-right: unset;
                 }
              }
            }

            .ReservationDetails__tenant__profile {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 0.5rem;

              @media screen and (max-width: @mobileWidth) {
                align-items: flex-start;
              }

              > span {
                font-size: 0.875rem;
                color: #5A6872;
                text-align: center;
                font-weight: 500;
              }
            }
          }
        }
      }

      .ReservationDetails__actions-card {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        .separator {
          width: 100%;
          height: 1px;
          border: none;
          background-color: #DFE3E6;
          margin: 0;
        }

        .ReservationDetails__actions-card__request-state {
          width: 100%;
        }

        @media screen and (max-width: @mobileWidth) {
          max-width: 100%;
          flex-direction: column;
        }

        .cancellation-info {
          width: 100%;
          font-size: 0.75rem;
          color: @grey4;
          font-weight: 400;
          line-height: 1.25rem;
          color: #5A6872;
        }

        .user-info {
          display: flex;
          flex-direction: column;

          .user-contact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0;
            width: 100%;

            p {
              color: #5A6872;
              font-weight: 500;
            }

            label {
              font-weight: 700;
            }
          }
        }

        .user-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          font-size: 0.875rem;
          font-weight: 700;
          color: #434A5F;

          > svg {
            margin-right: 0.25rem;
            width: 1rem;
            height: 1rem;
            fill: #5A6872;
          }

          > span {
            margin-left: 0.5rem;
            color: #57B4F2;
          }

          @media screen and (max-width: @mobileWidth) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .btn-container {
            width: 100%;

            @media screen and (max-width: @mobileWidth) {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              button {
                width: 100%;
              }
            }

            button, a {
              flex: 1;
            }
            a {
              text-decoration: none;
            }
          }
        }

        .reject-card {
          display: flex;
          flex-direction: column;
          height: 80%;
          justify-content: space-between;
          width: 400px;
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            max-width: 100%;
          }

          > div:first-child {
            margin-bottom: 20px;
          }

          > div {
            display: flex;

            > img {
              margin-right: 10px;
            }

            > div {
              display: flex;
              flex-direction: column;

              > span {
                font-size: @13px;
                color: @grey4;

                > strong {
                  font-size: 13px;
                  font-weight: 600;
                  color: @grey6;
                }
              }
            }
          }
        }

        .cancellation-request_info {
          border-radius: 8px;
          border: 1px solid @yellow;
          background: @yellowSoft;
          color: @grey6;
          padding: 16px 8px 16px 16px;
          font-family: Inter, sans-serif;
          font-size: @12px;
          font-style: normal;
          font-weight: 500;

          > strong {
            color: @yellow;
          }
        }

        .state {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          > div {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 700;
            color: #434A5F;

            > svg {
              margin-right: 0.25rem;
              width: 1rem;
              height: 1rem;
              fill: #5A6872;
            }

            > span {
              margin-left: 0.5rem;
              color: #57B4F2;
            }
          }

          > span, .ActionsExpires {
            display: inline-flex;
            font-size: @12px;
            margin-bottom: 10px;

            > img {
              margin-right: 10px;
            }
          }

          .ActionsExpires{
            width: unset;
          }
        }

        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          .btn {
            height: 100%;
          }

          @media screen and (max-width: @mobileWidth) {
            flex-direction: column-reverse;
            align-items: stretch;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          > p {
            font-size: 0.75rem;
            font-weight: 400;
            color: #5A6872;
          }

          > a {
            font-size: 0.875rem;
            font-weight: 400;
            color: #5A6872;
          }
        }

        .second-action {
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            text-wrap: nowrap;
          }

          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            flex-direction: column;
            align-items: stretch;
            gap: 1rem;
          }
        }
      }
    }

    .ReservationDetails__content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        flex: 1;

        @media screen and (max-width: @mobileWidth) {
          flex-direction: column;
          max-width: 100%;
        }

        .ReservationDetails__card {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .ReservationDetails__stay-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #57B4F2;
            font-size: 1rem;
            font-weight: 700;
            padding: 0.5rem 0;

            > svg {
              width: 3rem;
              height: 3rem;
            }

            &__last {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 700;
              font-size: 1.125rem;
              color: #1D9AFA;
              padding: 0.5rem 0;

              @media screen and (max-width: @mobileWidth) {
                padding: 0.5rem 0;
              }
            }

            @media screen and (max-width: @mobileWidth) {
              padding: 0.75rem 0;
            }
          }

          .ReservationDetails__stay-details__title {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: #434A5F;
            font-weight: 700;

            @media screen and (max-width: @mobileWidth) {
              font-weight: 500;

              > svg {
                width: 1.5rem;
                height: 1.5rem;
              }
            }

            &__last {
              display: flex;
              align-items: center;
              gap: 1rem;
              color: #434A5F;
              font-size: 1.125rem;

              @media screen and (max-width: @mobileWidth) {
                font-weight: 500;

                > svg {
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
            }
          }

          .stay-details__separator {
            height: 1px;
            border: none;
            background-color: #DFE3E6;
          }

          .ReservationDetails__meet {
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .ReservationDetails__meet__info {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 1.5rem;

              @media screen and (max-width: @mobileWidth) {
                flex-direction: column;
              }

              .ReservationDetails__meet__about {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                p {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: #5A6872;
                }

                @media screen and (max-width: @mobileWidth) {
                  width: 100%;
                }
              }

              .ReservationDetails__meet__video-bio {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                @media screen and (max-width: @mobileWidth) {
                  width: 100%;
                }

                video {
                  width: 100%;
                  border-radius: 1rem;
                  object-fit: fill;
                }
              }
            }

            .ReservationDetails__meet__application {
              display: flex;
              width: 100%;
              flex-direction: column;
              gap: 0.5rem;
            }

            h5 {
              margin: 0;
              font-size: 1rem;
              font-weight: 700;
              color: #284F83;
            }

            p {
              font-size: 0.875rem;
              font-weight: 400;
              color: #5A6872;
            }
          }
        }

        > div {
          display: flex;
          flex-direction: column;
        }

        /* .left {
          width: 50%;

          @media screen and (max-width: @mobileWidth) {
            width: 100%;
          }

          .ReservationDetails__bottom-about {
            > div:not(:last-child) {
              margin-bottom: 40px;
            }
          }

          .ReservationDetails__bottom-reservation {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            justify-content: space-between;
            max-width: 80%;
            @media screen and (max-width: @mobileWidth) {
              max-width: 100%;
              flex-direction: column;
            }

            > div {
              display: flex;
              flex-direction: column;
              @media screen and (max-width: @mobileWidth) {
                margin-bottom: 15px;
              }

              > div:not(:last-child) {
                margin-bottom: 15px;
              }
            }

          }
        } */

        .right {
          display: flex;
          flex-direction: column;
          margin-right: 150px;
          @media screen and (max-width: @mobileWidth) {
            margin-right: unset;
            width: 100%;
          }


          .payment-details {
            display: flex;
            flex-direction: column;

            > span:first-child {
              font-size: @13px;
              font-weight: 600;
              color: @grey6;
              margin-bottom: 15px;
            }

            > span:not(:first-child) {
              display: inline-flex;
              font-size: @12px;
              color: @grey4;
            }

            > div:not(:last-child) {
              margin-bottom: 15px;
            }

            > div {
              display: inline-flex;
              justify-content: space-between;
              width: 300px;
              @media screen and (max-width: @mobileWidth) {
                width: 100%;
              }

              > div {
                display: flex;
                flex-direction: column;

                > span {
                  display: inline-flex;
                  font-size: @12px;
                  color: @grey4;

                  > strong {
                    font-weight: 600;
                    color: @grey6;
                  }
                }
                >span:not(:first-child) {
                  font-weight: 300;
                  font-size: @10px;
                }
              }

              > span:last-child {
                font-size: 13px;
                color: @grey4;
                white-space: nowrap;
              }
            }

          }

          .tenant-bills {
            display: flex;
            flex-direction: column;
            margin-top: 25px;

            > span:first-child {
              font-size: @13px;
              font-weight: 600;
              color: @grey6;
              margin-bottom: 15px;
            }

            .entry-date {
              margin-bottom: 20px;

              > span:first-child {
                font-weight: 600;
                color: @grey4;
              }

              > span:not(:first-child) {
                display: inline-flex;
                font-size: @12px;
                color: @grey4;
              }
            }

            > div {
              display: inline-flex;
              justify-content: space-between;
              > div {
                display: flex;
                flex-direction: column;

                > span {
                  display: inline-flex;
                  font-size: @12px;
                  color: @grey4;
                  text-transform: capitalize;
                }
                >span:last-child {
                  font-size: @10px;
                  color: @grey4;
                  text-transform: unset;
                  max-width: 240px;
                  font-weight: 300;
                }
              }
            }

            > div:not(:first-child) {
              > span {
                font-size: @12px;
                color: @grey4;
                text-transform: capitalize;
              }

              > span:last-child {
                font-size: 13px;
              }
            }
            .rents-info {
              font-size: @10px;
              color: @grey4;
              max-width: 300px;
              margin-top: 6px;
              text-align: justify;
            }
          }

          .refund-bills {
            display: flex;
            flex-direction: column;
            margin-top: 25px;

            > span {
              font-size: @13px;
              font-weight: 600;
              color: @grey6;
              margin-bottom: 15px;
            }

            > div {
              display: inline-flex;
              justify-content: space-between;

              > span {
                font-size: @12px;
                color: @grey4;
              }

              > span:last-child {
                font-size: 13px;
              }
            }
          }

          .total-value {
            display: flex;
            flex-direction: column;
            margin-top: 25px;

            > span {
              font-size: @13px;
              font-weight: 600;
              color: @grey6;
              margin-bottom: 15px;
            }

            > div {
              display: inline-flex;
              justify-content: space-between;

              > span {
                font-size: @12px;
                color: @grey4;
              }

              > span:last-child {
                font-size: 15px;
                font-weight: 600;
                color: @grey6;
              }
            }
          }
        }

        .ReservationDetails__cancellation-details {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .cancellation-details {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .cancellation-details__header {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              color: #284F83;

              h5 {
                color: #284F83;
                margin: 0;
              }

              svg {
                fill: currentColor;
              }
            }

            .cancellation-details__content {
              display: flex;
              gap: 1.5rem;

              @media screen and (max-width: @mobileWidth) {
                flex-direction: column;
              }
            }

            .cancellation-details__info {
              display: flex;
              width: 100%;
              flex-direction: column;
              gap: 0.5rem;
              justify-content: space-between;

              .cancellation-details__info__item {
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: @mobileWidth) {
                  flex-direction: column;
                  align-items: flex-start;
                }

                label {
                  font-size: 0.75rem;
                  font-weight: 500;
                  color: #5A6872;
                }

                span {
                  color: #434A5F;
                  text-align: center;
                  font-size: 0.75rem;
                  font-weight: 700;
                }
              }
            }

            .cancellation-details__content__policy-container {
              display: flex;
              align-items: center;
              flex: 1 0 35%;
              border-radius: 0.5rem;
              padding: 1rem;
              background-color: rgba(238, 247, 254, 1);
              font-size: 0.75rem;
              font-weight: 500;
              color: #434A5F;
              box-shadow: 2px 2px 8px 0px rgba(196, 196, 196, 0.24);

              strong {
                font-weight: 700;
                color: #1D9AFA;
              }
            }
          }

          .penalty-info {
            color: #5A6872;
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
    }

    .ReservationDetails__footer {
      display: none;

      @media screen and (max-width: @mobileWidth) {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

.ReservationDetails__section {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @13px;
    font-weight: 600;
    color: @grey6;
    margin-bottom: 10px;
  }

  .text {
    display: inline-flex;
    font-size: @12px;
    color: @grey4;
  }
}

.horizontal_dashed_line {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px dashed @grey2;

  .w-100 {
    width: 100%;

  }

  .w-50 {
    width: 50%;
  }
}


.RentMonths_Dialog {
  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 60vw;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .title {
    font-size: @13px;
    color: @grey6;
    margin-bottom: 10px;
  }

  .rents-input {
    columns: 2 auto;
    column-gap: 40px;

    .rent {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      > label {
        min-width: 22px;
        font-size: @13px;
        color: @grey5;
        margin-right: 5px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    font-size: @11px;
    margin-bottom: 30px;

    > svg {
      margin-right: 5px;
    }
  }

  .dialog-actions {
    display: flex;

    > button:first-child {
      margin-right: 20px;
    }
  }
}

//close button for the dialogs
.dialog_close-button {
  height: 26px;
  margin-left: auto;
  width: fit-content;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background: @grey2;
  }
}

//Modal suggest property

.SuggestNewProperty {
  .CustomDatePicker .react-datepicker {
    @media screen and (max-width: @mobileWidth) {
      height: 220px;
    }
  }

  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 80vw;
    @media screen and (max-width: @mobileWidth) {
      max-height: unset;
      height: unset;
      width: 90vw;
    }
    .DialogCloseButton {
      display: none;
    }

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .SuggestNewProperty__content {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 30px;
    width: 100%;
    min-height: 350px;

    @media screen and (max-width: @mobileWidth) {
      padding: 15px 17px 13px 13px;
    }


    .SuggestNewProperty__content-label {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      > span:first-child {
        margin-bottom: 10px;
      }

      > span {
        font-size: 13px;
        color: @grey5;

        > strong {
          font-size: 14px;
          font-weight: 600;
          color: @grey6;
        }
      }
    }

    .SuggestNewProperty__content-select-motive {
      > label {
        font-size: 13px;
        font-weight: 600;
        color: @grey6;
        margin-bottom: 20px;
      }

      .select-motive {
        font-size: 16px !important;
        color: @grey5;
        max-width: 450px;
      }
    }

    .SuggestNewProperty__content-choose-ad {
      width: 100%;

      .SuggestNewProperty .MuiDialog-container .MuiPaper-root {
        max-width: unset;
      }

      .select-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        @media screen and (max-width: @mobileWidth) {
          flex-direction: column;
        }

        .title {
          font-size: @13px;
          color: @grey6;
          font-weight: bold;
          @media screen and (max-width: @mobileWidth) {
            margin-bottom: 1rem;
          }
        }

        .SearchFilter {
          @media screen and (max-width: @mobileWidth) {
            width: unset;
          }
        }

        .search-input {
          display: flex;
          justify-content: space-between;
          border: 1px solid #CBD0D6;
          border-radius: 4px;
          padding: 2px;

          > input {
            font-size: @12px;
            color: @grey5;
            border: unset;
          }
        }

        .select-house {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: @mobileWidth) {
            flex-direction: row;
            align-items: center;
            width: 100%;
          }

          > span {
            font-size: @13px;
            color: @grey5;
            @media screen and (max-width: @mobileWidth) {
              margin-right: 1rem;
            }
          }

          .react-select-container {
            width: 200px;
            font-size: @12px;
            color: @grey5;
          }
        }


      }

      .labels {
        display: flex;
        width: 100%;

        .roomName {
          width: 40%;
        }

        .price {
          width: 10%;
        }

        .availableFrom {
          width: 20%;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow-y: scroll;

        &.list-compact {
          @media screen and (min-width: @mobileWidth) {
            flex-direction: row;
            flex-wrap: wrap;

            .room-card {
              width: 50%;
            }
          }
        }

        .selected {
          border: 2px solid @blue;
          @media screen and (max-width: @mobileWidth) {
            border: 2px solid @blue!important;
          }
        }

        .room-card:not(:last-child) {
          margin-bottom: 10px;
        }

        .room-card {
          display: flex;
          flex-direction: row;
          background-color: white;
          box-shadow: 0 4px 40px rgba(202, 202, 202, 0.25);
          width: 100%;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          @media screen and (max-width: @mobileWidth) {
            flex-wrap: wrap;
            justify-content: unset;
            max-width: 100%;
            border: 1px solid @grey3;
            padding: .5rem 0;
            flex-direction: column;
            align-items: center;
          }

          &.not-available {
            background-color: #f4f4f4;
            opacity: 0.5;

            &:hover {
              cursor: not-allowed !important;
            }
          }

          .room-section {
            display: flex;
            flex-direction: row;

            .room-image {
              width: 100px;
              height: 100px;
              margin-right: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .room-info {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              @media screen and (max-width: @mobileWidth) {
                width: 100%;
              }

              > span {
                font-size: 12px;
                color: @grey6;

                > strong {
                  font-size: 13px;
                  font-weight: 600;
                }
              }
            }
          }

          .rent-input {
            border: 1px solid @grey4;
            border-radius: 3px;
            font-size: 12px;
            color: @grey6;
            width: 90px;
          }

          .room-prices {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: @grey6;
            min-width: 90px;

            @media screen and (max-width: @mobileWidth) {
              margin-left: 0;
              min-width: unset;
              justify-content: unset;
              margin-top: 5px;
            }

            > img {
              cursor: pointer;
              @media screen and (max-width: @mobileWidth) {
                display: none;
              }
            }
          }

          .room-date {
            display: flex;
            font-size: 12px;
            color: @grey6;

            > img {
              cursor: pointer;
              margin-left: 10px;
              @media screen and (max-width: @mobileWidth) {
                display: none;
              }
            }
          }

          .room-availability {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            @media screen and (max-width: @mobileWidth) {
              margin-right: unset;
              align-items: center;
              text-align: center;
            }


            .available {
              color: #2DC928;
            }

            .unavailable {
              color: @redError;
            }

            > span:first-child {
              margin-bottom: 10px;

            }

            > span {
              font-size: @12px;
              color: @grey5;

              > strong {
                font-size: 13px;
              }
            }
          }
        }

        .property-card:not(:last-child) {
          margin-bottom: 10px;
        }

        .property-card {
          display: flex;
          flex-direction: row;
          background-color: white;
          box-shadow: 0 4px 40px rgba(202, 202, 202, 0.25);
          width: 100%;
          justify-content: space-between;
          cursor: pointer;
          align-items: center;

          @media screen and (max-width: @mobileWidth) {
            max-width: 100%;
            border: 1px solid @grey3;
            flex-direction: column;
          }

          &:hover {
            background: @blueSuperSoft;
          }

          .property-section {
            display: flex;
            @media screen and (max-width: @mobileWidth) {
              width: 100%;
            }

            .property-image {
              width: 100px;
              height: 100px;
              margin-right: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              @media screen and (max-width: @mobileWidth) {
                width: 0;
                height: 0;
                padding: 3rem 2.25rem;
              }
            }

            .property-info {
              display: flex;
              flex-direction: column;
              margin-right: 10px;
              min-width: 250px;
              max-width: 250px;
              @media screen and (max-width: @mobileWidth) {
                min-width: unset;
                max-width: unset;
                margin-top: 5px;
              }

              > span:not(:last-child) {
                margin-bottom: 6px;
              }

              > span {
                font-size: 12px;
                color: @grey6;

                > strong {
                  font-size: 13px;
                  font-weight: 600;
                }
              }
            }
          }

          .property-prices {
            width: 100px;
            font-size: 13px;
            color: @grey6;
            @media screen and (max-width: @mobileWidth) {
                font-size: @10px;
                align-self: flex-end;
            }
          }
        }
      }

      .schedule-dialog-body {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 20px;

        .left {
          flex: 3;

          > p {
            font-weight: 700;
            color: @grey6;
            margin-bottom: 10px;
          }

          .day-picker-container {
            .date-box {
              background-color: #f8f9fc;
              border-radius: 8px;
              height: 40px;
              width: 100%;
              padding: 0 15px;
              font-size: @12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              &:hover {
                background-color: @grey2;
              }

              &.active {
                background-color: #dbf0ff;
              }

              &:not(:last-child) {
                margin-bottom: 10px;
              }

              > span {
                color: @grey4;
              }

              > strong {
                color: @grey6;
                font-weight: 400;
              }
            }
          }
        }

        .div {
          flex: 0.1;
          border-right: 1px dashed @grey3;
          height: calc(100vh - 315px);
          margin: 0 20px;
        }

        .right {
          flex: 5;

          .select-date-time-container {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: calc(100% - 30px);
            margin: 10px 15px 0 15px;

            > p {
              font-weight: 700;
              color: @grey6;
              margin: 10px 0;
            }

            > div:last-child {
              display: flex;
              font-size: @12px;
              color: @grey5;
              font-weight: 400;
              margin-top: 20px;

              > img {
                margin-right: 9px;
              }
            }

            .date-box-title {
              width: 100%;
              height: 32px;
              background-color: @grey1;
              color: @grey4;
              font-size: @12px;
              font-weight: 700;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
            }

            .div-horiz {
              border-top: 1px dashed @grey3;
              width: 100%;
              margin: 20px 0;
            }

            .TimesPickers {
              max-width: 200px;
            }
          }
        }
      }
    }

    .SuggestNewProperty__content-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;
      align-items: end;
      flex: 1;
      gap: 1rem;

      > button {
        display: flex;
        justify-content: center;
        width: 170px;
      }

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;

        button {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}


//Modal suggest new dates

.PaymentSuccessModal {

  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 90vw;
    max-width: 700px;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .PaymentSuccessModal__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;

    > span {
      font-size: @16px;
      font-weight: bold;
      color: @grey4;
    }

    .payment-success {
      width: 80px;
      height: 115px;
      margin: 0 auto;

      .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;

        &::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
        }

        &::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
        }

        &::before, &::after {
          content: '';
          height: 100px;
          position: absolute;
          background: #FFFFFF;
          transform: rotate(-45deg);
        }

        .icon-line {
          height: 5px;
          background-color: #4CAF50;
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;

          &.line-tip {
            top: 46px;
            left: 14px;
            width: 25px;
            transform: rotate(45deg);
            animation: icon-line-tip 0.75s;
          }

          &.line-long {
            top: 38px;
            right: 8px;
            width: 47px;
            transform: rotate(-45deg);
            animation: icon-line-long 0.75s;
          }
        }

        .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid rgba(76, 175, 80, .5);
        }

        .icon-fix {
          top: 8px;
          width: 5px;
          left: 26px;
          z-index: 1;
          height: 85px;
          position: absolute;
          transform: rotate(-45deg);
          background-color: #FFFFFF;
        }
      }
    }

    @keyframes rotate-circle {
      0% {
        transform: rotate(-45deg);
      }
      5% {
        transform: rotate(-45deg);
      }
      12% {
        transform: rotate(-405deg);
      }
      100% {
        transform: rotate(-405deg);
      }
    }

    @keyframes icon-line-tip {
      0% {
        width: 0;
        left: 1px;
        top: 19px;
      }
      54% {
        width: 0;
        left: 1px;
        top: 19px;
      }
      70% {
        width: 50px;
        left: -8px;
        top: 37px;
      }
      84% {
        width: 17px;
        left: 21px;
        top: 48px;
      }
      100% {
        width: 25px;
        left: 14px;
        top: 45px;
      }
    }

    @keyframes icon-line-long {
      0% {
        width: 0;
        right: 46px;
        top: 54px;
      }
      65% {
        width: 0;
        right: 46px;
        top: 54px;
      }
      84% {
        width: 55px;
        right: 0;
        top: 35px;
      }
      100% {
        width: 47px;
        right: 8px;
        top: 38px;
      }
    }
  }

}

.SuggestNewDatesModal {

  .CustomDatePicker .react-datepicker {
    @media screen and (max-width: @mobileWidth) {
      height: 220px;
    }
  }

  .MuiDialog-container .MuiPaper-root {
    /* max-height: calc(100vh - 120px);
    height: fit-content; */
    width: 90vw;
    max-width: 700px;
    max-height: 65%;

    @media screen and (max-width: @mobileWidth) {
      height: unset;
      max-height: unset;
    }

    .DialogCloseButton {
      display: none;
    }

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .SuggestNewDates__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem;
    width: 100%;

    .dialog__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;

      .dates {
        display: flex;
        gap: 0.5rem;

        button {
          flex: 1;
        }
      }

      .header_container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        > span {
          font-size: 12px;
          color: @grey5;
          font-weight: 500;

          > strong {
            font-size: 22px;
            font-weight: 700;
            color: #0F4D7D;
          }
        }
      }

      .dates-info {
        display: flex;
        color: @grey6;
        font-size: @12px;
      }

      .dates-error {
        margin-bottom: 5px;
        font-size: 12px;
        color: red;
      }

      .dates-input {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          display: flex;
          flex-direction: column;

          > label {
            > strong {
              font-size: 13px;
              font-weight: 600;
              color: @grey6;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .actions-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      gap: 1rem;

      > button {
        display: flex;
        justify-content: center;
        width: 100%;
        flex: 1;
      }

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
        gap: 1rem;

        button {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}

//Modal reject

.RejectReservationModal {
  .CustomDatePicker .react-datepicker {
    @media screen and (max-width: @mobileWidth) {
      height: 220px;
    }
  }

  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    width: 90vw;
    max-width: 700px;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .RejectReservation__content {
    display: flex;
    flex-direction: column;
    padding: 35px 30px 20px 30px;;
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
    }

    .RejectReservation__content-label {
      margin-bottom: 20px;

      > span:first-child {
        margin-bottom: 10px;
      }

      > span {
        font-size: 13px;
        color: @grey5;

        > strong {
          font-size: 14px;
          font-weight: 600;
          color: @grey6;
        }
      }
    }

    .RejectReservation__content-select-motive {
      > label {
        font-size: 13px;
        font-weight: 600;
        color: @grey6;
        margin-bottom: 20px;
      }

      .select-motive {
        font-size: 13px;
        color: @grey5;
        max-width: 450px;
      }
    }

    .RejectReservation__content-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      > span {
        font-size: 13px;
        color: @grey5;
      }

      .details_other-dates {
        display: flex;
        flex-direction: column;

        > span:first-child {
          margin-bottom: 25px;
        }

        > span {
          font-size: 12px;
          color: @grey5;

          > strong {
            font-size: 13px;
            font-weight: 600;
            color: @grey6;
          }
        }

        > div:not(:last-child) {
          margin-bottom: 10px;
        }

        .align-items-center {
          align-items: center;
          @media screen and (max-width: @mobileWidth) {
            display: flex;
            flex-direction: column;
          }
        }

        > div {
          font-size: 12px;
          color: @grey5;
          display: inline-flex;

          > input {
            appearance: auto;
            margin-right: 20px;
          }
        }
      }

      //here was details outdated
    }

    .RejectReservation__content-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;

      > button {
        display: flex;
        justify-content: center;
        width: 170px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

  }
}

.horizontal_line {
  margin-top: 50px;
  margin-bottom: 40px;
  border-bottom: 2px solid @grey2;
  margin-left: -15px !important;
  margin-right: -15px;

  .w-100 {
    width: 100%;

  }

  .w-50 {
    width: 50%;
  }
}


.details_outdated {
  display: flex;
  flex-direction: column;

  .details-checkboxes {
    display: flex;
    margin-bottom: 10px;

    .details-input-container {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      margin-right: 15px;
      @media screen and (max-width: @mobileWidth) {
          white-space: nowrap;
      }

      > input {
        appearance: auto;
      }

      > label {
        margin-left: 6px;
        font-size: 13px;
        color: @grey6;
        font-weight: 600;
      }
    }
  }


  .rent-months-input {
    columns: 2 auto;
    column-gap: 40px;
    @media screen and (max-width: @mobileWidth) {
      columns: 1 auto;
    }

    .rent {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      @media screen and (max-width: @mobileWidth) {
        width: 100%;
        justify-content: center;
      }

      input{
        @media screen and (max-width: @mobileWidth) {
          max-width: 70%;
        }
      }

      > label {
        min-width: 22px;
        font-size: @13px;
        color: @grey5;
        margin-right: 5px;
      }
    }
  }

  .rent-value-input {
    margin-left: 23px;
    margin-bottom: 40px;

    @media screen and (max-width: @mobileWidth) {
      margin-left: 0;
      margin-bottom: 20px;
    }


    > span {
      margin-left: 15px;
      font-size: @12px;
      color: @grey5;
    }
  }


  > span {
    font-size: 12px;
    color: @grey5;
    margin-bottom: 15px;
  }

  .rents_info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    > svg {
      margin-right: 5px;
    }
  }

  .double-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .CustomDatePicker{
      @media screen and (max-width: @mobileWidth) {
        width: 100%!important;
      }
    }

    > div:last-child {
      margin-left: 23px;
      @media screen and (max-width: @mobileWidth) {
        margin-left: 0;
        flex-direction: column;
      }

      > span {
        margin-left: 15px;
        font-size: @12px;
        color: @grey5;
        @media screen and (max-width: @mobileWidth) {
          margin-top: .5rem;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      > input {
        appearance: auto;
      }

      > label {
        margin-left: 6px;
        font-size: 13px;
        color: @grey6;
        font-weight: 600;
      }
    }

    .dates-info {
      display: flex;
      margin-bottom: 8px;
      color: @grey6;
      font-size: @12px;
      margin-left: 23px;
    }

    .new-dates {
      display: flex;

      > div:first-child {
        margin-right: 10px;
      }

      > div {
        display: flex;
        flex-direction: column;

        > label {
          > strong {
            font-size: 13px;
            font-weight: 600;
            color: @grey6;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.new-date {
  >label {
    margin-left: 3px;
    font-size: 13px;
    color: #434A5F;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .current-dates {
    display: flex;
    margin-top: 8px;
    color: #434A5F;
    font-size: 0.75rem;
  }
}


//button go back

.action-back {
  display: flex;
  margin-bottom: 35px;
  align-items: center;
  cursor: pointer;
  padding-right: 12px;

  &:hover {
    .button-container {
      transform: scale(1.15);
    }
  }

  .button-container {
    cursor: pointer;
    height: 28px;
    width: 28px;
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(196, 196, 196, 0.25);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;

    > img {
      transform: scaleX(-1);
      font-size: @14px;
    }
  }

  > span {
    margin-left: 10px;
    font-size: @14px;
    color: @grey6;
    line-height: @14px;
  }
}

.card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);

  @media screen and (max-width: @mobileWidth) {
    padding: 1.5rem;
  }
}

.card__payment {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1.20rem;
}

.sub-card {
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4375rem;
  border-radius: 0.25rem;
  background: #F0F3F6;

  .sub-card__header {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.4375rem;
    align-self: stretch;
    border-radius: 0.25rem;
    background: rgba(29, 154, 250, 0.10);
    color: #1D9AFA;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .sub-card__body {
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .sub-card__body__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
      padding: 0.5rem 0rem;

      .vat-item {
        display: flex;
        flex-direction: column;
        color: #5A6872;

        span {
          font-size: 0.875rem;
          font-weight: 500;
        }

        .extra-info {
          font-size: 0.75rem;
        }
      }

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }

      p {
        color: #5A6872;
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 500;
      }

      strong {
        color: #434A5F;
        font-size: 0.875rem;
        font-weight: 700;
        text-align: right;
        white-space: nowrap;
      }

      .sub-card__body__item__icon {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        @media screen and (max-width: @mobileWidth) {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .btn-container button svg {
    fill: currentColor;
  }
}

.payment-total-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #EEF7FE;

  > h4 {
    color: #284F83;
    font-size: 1.125rem;
    font-weight: 700;
  }

  > h3 {
    color: #1D9AFA;
    font-size: 1.375rem;
    font-weight: 700;
  }
}

.sub-card__body__item__icon .tooltip__wrapper .tooltip-tip {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;

  .bills-tooltip__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;

    > p {
      font-size: 0.875rem;
      font-weight: 700;
      color: white;
    }

    > span {
      color: #DFE3E6;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: left;
    }
  }

  .bills-tooltip__content-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #F0F3F6;

    .bills-tooltip__content-item__icon {
      display: flex;
      align-items: center;
      padding: 0.3rem;
      background-color: rgba(238, 247, 254, 0.50);
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
    }
  }
}

.sub-card__body .timeline-container {
  padding: 0;
}

.ReservationDetails__house-reservation.card {
  @media screen and (max-width: @mobileWidth) {
    padding: 0;
  }
}

.drawer {
  display: flex;
  flex-direction: column;

  .bills-tooltip {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .bills-tooltip__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    > p {
      font-size: 1rem;
      font-weight: 700;
      color: #434A5F;
    }

    > span {
      color: #8C9BA5;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: left;
    }
  }

  .bills-tooltip__content-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #1D9AFA;

    .bills-tooltip__content-item__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem;
      background-color: #1D9AFA;
      opacity: 0.5;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;

      svg {
        fill: #1D9AFA;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
      font-size: 1rem;
      font-weight: 700;
      color: #434A5F;
    }

    > span {
      color: #8C9BA5;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: left;

      &:last-child {
        margin-bottom: 0.5rem;
      }
    }

    > span:last-child {
      color: #8C9BA5;
      font-size: 0.75rem;
      font-weight: 500;
      text-align: left;
    }
  }
}

.message {
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: #CCDCE6;
  color: #434A5F;
  font-size: 0.75rem;
  font-weight: 500;

  div {
    display: flex;
    flex-direction: column;
  }

  strong, a {
    font-weight: 700;
    color: #284F83;
    text-decoration: none;
  }
}
