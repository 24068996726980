.overview-slots {
    display: flex;
    flex-direction: column;
    width: 300px;
    font-family: "Inter";

    .overview-slots__title {
        font-size: 14px;
        font-weight: 700;
    }

    .overview-slots__info {
        margin: 20px 0;
        display: inline-flex;
        align-items: flex-start;
        width: 250px;

        svg {
            width: 35px;
            height: 25px;
            margin-right: 5px;
        }

        p {
            font-size: 12px;
        }
    }

    .overview-slots__slots {
        background-color: white;
        box-shadow: 0px 4px 40px rgba(196, 196, 196, 0.25);
        border-radius: 8px;
        padding-bottom: 24px;

        .overview-slots__slots__title {
            width: 100%;
            text-align: center;
            background-color: #F0F3F6;
            font-family: "Inter";
            font-size: 14px;
            font-weight: 700;
            padding: 16px 10px;
        }

        .overview-slots__slots__container {
            padding: 0 24px;
            display: flex;
            flex-direction: column;
            margin: 24px 0;
            max-height: 300px;
            overflow-y: auto;
            gap: 16px;

            .overview-slots__slots__container__slot-wrapper {
                display: flex;
                flex-direction: column;
                border-radius: 6px;

                .slow-wrapper-title {
                    text-align: center;
                    font-size: 12px;
                    font-weight: bold;
                    color: #434A5F;
                    padding-top: 8px;
                    margin-bottom: -10px;
                }

                &.available {
                    background-color: #EEF7FE;
                }

                &.unavailable {
                    background-color: #FA392E1A;
                }

                &.automatic {
                    background-color: #dafae0;
                }
            }

            .overview-slots__slots__container__slot {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0 16px;
                gap: 16px;

                .overview-slots__slots__container__slot__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 0;
                    gap: 8px;

                    h5 {
                        font-size: 16px;
                        font-weight: 700;
                        color: #434A5F;
                        margin: 0;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: #434A5F;
                    }
                }

                .overview-slots__slots__container__slot__separator {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &__line {
                        width: 1px;
                        min-height: 50%;
                        height: 50%;
                        background-color: white;
                    }

                    &__icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px;
                        background-color: white;
                        border-radius: 100%;

                        .icon {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }

            .overview-slots__no-slots {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 24px;
                gap: 24px;

                .overview-slots__no-slots__icon-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 7px;
                    background-color: #EEF7FE;
                    border-radius: 100%;

                    .icon {
                        width: 24px;
                        height: 24px;
                    }
                }

                .overview-slots__no-slots__icon-container__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 4px;

                    > h5 {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #284F83;
                        margin: 0;
                    }

                    > p {
                        font-size: 12px;
                        font-weight: 400;
                        color: #8C9BA5;
                    }
                }
            }
        }

        .overview-slots__slots__info {
            display: flex;
            align-content: center;
            justify-content: space-between;
            margin: 0 24px;

            .overview-slots__slots__info--available {
                display: flex;
                align-items: center;

                > div {
                    background-color: #D2EBFE;
                }

                .overview-slots__slots__info--available__icon {
                    width: 8px;
                    height: 8px;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }

            .overview-slots__slots__info--unavailable {
                display: flex;
                align-items: center;

                > div {
                    background-color: #FED2D2;
                }

                .overview-slots__slots__info--unavailable__icon {
                    width: 8px;
                    height: 8px;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }
        }
    }
}

.react-datepicker {
    .react-datepicker__navigation-icon--next,
    .react-datepicker__navigation-icon--previous {
        left: 0;
        top: 8px;
    }
}
