@import '../../../../../../_variables';

.VisitsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .VisitsList_noAds {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
  }
}
