@import "../../_variables";

.filtersContainer {
  display: flex;
  justify-content: space-between;

  .SearchFilter {
    margin-left: 2rem;
  }


  > div {
    color: @grey5;
    font-size: @12px;
    text-align: center;
  }

  .more_filters {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @grey4;
    font-size: @12px;
    text-decoration-line: underline;
    cursor: pointer;

    img {
      margin: .5rem;
      max-width: 15px;
      opacity: .5;
    }
  }

  .filters {
    font-size: @12px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }

    .label {
      margin-right: 1rem;
    }

    &.afterLine:after {
      content: " ";
      width: 1px;
      height: 100%;
      background-color: @grey3;
    }

    > div {
      font-size: @12px;
      min-width: 123px !important;
      margin: 0 .5rem;

      > div {
        color: @grey5 !important;
        font-size: @14px;
        text-align: center;
        border-radius: 17px;
        border-style: unset;
        width: 100%;

        > div {
          width: fit-content;
          border-radius: 17px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: content-box;
          white-space: nowrap;
          margin: unset;

          div.css-1hb7zxy-IndicatorsContainer {
            display: none;
          }

          /* &:nth-child(2) svg {
            display: none;
          } */

          &:last-child {
            flex-direction: column;
            z-index: 99;
            width: 100%;
            white-space: pre-wrap;
            min-height: max-content;

            > div {
              min-height: min-content;
            }
          }
        }
      }
    }

    .prices-filters {
      max-width: 95px;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      align-items: center;

      input {
        border: 1px solid #DFE3E6;
        box-sizing: border-box;
        border-radius: 14px;
        height: 47px;
        padding: 8px 0 8px 10px;
        line-height: 0;
        font-size: @14px;
        width: 80px;
        margin: .5rem;
      }
    }


    input.filter {
      border: none;
      color: @grey5;
      font-size: @14px;
      text-transform: unset;
      text-align: center;
      width: 100px;

      &.non-background {
        background-color: unset;
      }
    }

    .filter {
      margin: 0 .5rem;
      padding: .5rem 1rem;
      width: fit-content;
      background-color: @white;
      border-radius: 17px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: content-box;
      min-width: 100px;
      white-space: nowrap;

      &.non-background {
        background-color: unset;
      }

      p {
        text-align: center;
      }

      .CustomDatePicker {
        width: 0;

        .react-datepicker__input-container {
          display: none;
        }
        .react-datepicker__tab-loop {
          display: block!important;
        }
      }

      .priceRangeFilter {
        position: absolute;
        background-color: @white;
        border-radius: 4px;
        z-index: 10;
        padding: 1rem;
        transform: translateY(160px);
        box-shadow: @box_shadow;
        border: 1px solid @grey3;
        display: flex;
        flex-direction: column;
        max-width: max-content;

        .PriceRange {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;

          .PriceRangeChart {
            min-width: 100%;

            > span {
              min-width: 2.5%;
            }
          }
        }

        .inputs {
          input {
            margin-right: 1rem;
          }
        }

        .btn {
          align-self: center;
        }
      }
    }
  }

  .sort {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-left: 1rem;
    @media screen and (max-width: @mobileWidth) {
      margin-left: 0;
      width: 100%;
      justify-content: flex-end;
    }

    p {
      white-space: nowrap;
      @media screen and (max-width: @mobileWidth) {
        margin: 0 10px;
        font-size: @12px;
        color: @grey4;
      }
    }

    .sortIcon {
      width: 25px;
      height: 25px;
      transition: all .5s ease-in-out;
      cursor: pointer;
      margin: .5rem;
      @media screen and (max-width: @mobileWidth) {
        margin: 0 2px;
        width: 16px;
        height: 16px;
      }

      &.asc {
        transform: rotateX(180deg);
      }
    }

    .sort__select {
      div:nth-child(2) {
        min-width: min-content;
        border: none;
        margin: 0 0.5rem;
        padding: 0 .5rem;
        width: fit-content;
        background-color: #FFFFFF;
        border-radius: 17px;
        cursor: pointer;
        display: inline-flex;
        flex-wrap: nowrap;

        div:nth-child(1) {
          min-width: 100px;
          min-height: 20px;
        }

        div:nth-child(2) {
          margin: 0 0.2rem;
          padding: unset;

        }
      }
    }
  }
}

.filters {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  .filters__options {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
    }

    .filters__dropdowns {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      .filters__sort {
        display: flex;
        padding: 0.5rem 1rem;
        align-items: center;
        border-radius: 0.5rem;
        background: #FFF;
        cursor: pointer;

        svg {
          fill: #284F83;
        }
      }

      .dates__filter {
        display: flex;
        padding: 0.5rem 0;
        align-items: center;
        gap: 1rem;

        .datepicker-container {
          display: flex;
          flex-direction: column;

          label {
            color: #434A5F;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
      }
    }
    
    .filters__more-options {
      display: flex;
      gap: 1.5rem;

      @media screen and (max-width: @mobileWidth) {
        gap: 1rem;
      }

      .dropdown__header {
        display: none;

        @media screen and (max-width: @mobileWidth) {
          display: flex;
          padding: 0.5rem 1rem;
          gap: 0.5rem;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;
          border-radius: 0.5rem;
          background: white;
          cursor: pointer;
          font-size: 0.875rem;
          font-weight: 700;
          color: #434A5F;
          text-transform: capitalize;
          background: var(--White, #FFF);
          box-shadow: 0px 1px 3px 1px rgba(67, 74, 95, 0.15), 0px 1px 2px 0px rgba(67, 74, 95, 0.30);
        }
      }
  
      .dropdown__header.active {
        border: 1px solid #1D9AFA;
        box-shadow: 0px 0px 6px 0px rgba(24, 160, 251, 0.20);
      }

      @media screen and (max-width: @mobileWidth) {
        width: 100%;
      }
    }
  }
}

.simple-datepicker-revamped {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #DFE3E6;
  background: #FFF; 
  max-width: 10rem;
  overflow: hidden;

  &:active {
    box-shadow: 0px 0px 6px 0px rgba(29, 154, 250, 0.20);
    border: 1px solid #1D9AFA;
  }

  .react-datepicker-wrapper {
    flex: 1;
    width: 6rem;
  }

  .react-datepicker-wrapper input {
    border: none;
    width: 100%; /* Ensure the input takes the available width */
  }
}
