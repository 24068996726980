@import "../../../../_variables";

.MyAccount{
  background-color: @background;
  flex:1;

  .FiltersContainer__Container .FiltersContainer {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      gap: 1rem;
      font-size: 0.875rem;
      padding: 1rem;
    }
  }
}
