@import "../../../../_variables";

.Reservations {
  margin-bottom: 2.5rem;
  
  .status-bar {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .status-bar__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.30rem;
      border-radius: 100%;
      width: 2.5rem;
      height: 2.5rem;
    }

    .status-bar__icon.waitingPay {
      color: #F2B457;
      background-color: rgba(242, 180, 87, 0.1);

      svg {
        fill: currentColor;
      }
    }

    .status-bar__icon.rejected, .status-bar__icon.cancelled {
      color: #FA392E;
      background-color: rgba(250, 57, 46, 0.1);

      svg {
        fill: currentColor;
      }
    }

    .cancellation-icon_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 24px;
      width: 44px;
      height: 44px;
      background-color: #FFFAF2;
    }


    .status-bar__icon.confirmed, .status-bar__icon.scheduled {
      color: #2DC928;
      background-color: rgba(45, 201, 40, 0.1);

      svg {
        fill: currentColor;
      }
    }

    .status-bar__info {
      display: flex;
      flex-direction: column;

      p {
        color: #8C9BA5;
        font-size: 0.875rem;
      }
    }

    /* > span {
      display: flex;
      padding-left: 10px;
      @media screen and (max-width: @mobileWidth) {
        padding-left: unset;
        font-size: @10px;
        flex-wrap: wrap;
      }

      > strong{
        margin-right: 3px;
      }
    } */
  }

  //paga e aceite
  .confirmed {
    color:#2DC928;
  }

  //aguarda pagamento
  .waitingPay, .cancellation, .waitingStudentAcceptance{
    color: #F2B457;
  }

  //rejeitada ou expirada
  .rejected, .cancelled {
    color: #FA392E
  }

  //pedido feito || pedido feito e paga (instant)
  .requested {
    display: none;
  }
}
