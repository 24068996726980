@import "../../_variables";

.date-picker-range {
  width: 100%;
  position: relative;
  @media screen and (max-width: @mobileWidth) {
    font-size: @10px;
    width: 100%!important;
  }

  input {
    height: 36px;
    width: 100%;
    font-size: 14px !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    z-index: 990;
    height: 300px;

    .react-datepicker__header {
      background-color: @grey2;

    }


    .react-datepicker__day, .react-datepicker__day-name {
      width: 36px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: @mobileWidth) {
        width: 26px;
        height: 20px;
      }
    }

    .react-datepicker__day {
      &:hover {
        background: #CBD0D6;
        border-radius: 4px;

      }

      &.react-datepicker__day--disabled {
        &:hover {
          background: #f4f4f4;
        }
      }

      &.react-datepicker__day--selected {
        background: #1D9AFA;
        color: #1D9AFA;
        border-radius: 6px;
        font-weight: 700;
      }

      &.react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
      }
    }

    .react-datepicker__day--selecting-range-start {
      color: #434A5F;
      font-weight: 700;
      background: linear-gradient(135deg, #1D9AFA 51.03%, rgba(238, 247, 254, 0) 51.04%);

      &:hover {
        background-color: #1D9AFA;
        color: #434A5F;
      }
    }
  }

  .react-datepicker-popper{
    z-index: 110;
  }

  svg {
    position: absolute;
    top: 8px;
    right: 20px;

    .spaced {
        right: 30px;
    }
  }
}

.react-datepicker {
  .react-datepicker__navigation--previous {
    background: url(images/leftArrow.png) no-repeat;
  }

  .react-datepicker__navigation--next {
    background: url(images/rightArrow.png) no-repeat;
  }
  
  .react-datepicker__navigation {
    width: 15px;
    height: 15px;
    border: none;
  }

  .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
    background-position: center center;
    width: 20px;
    height: 20px;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
    font-size: @14px;
    color: @grey6;
  }
}

