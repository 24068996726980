@import "../../../../../../../../../_variables.less";

.propertyResume {
  width: calc(50% - 1.5rem);
  min-width: 45%;
  max-width: 50%;
  height: 225px;
  flex: 1;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  gap: 1.5rem;
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);

  @media screen and (max-width: @mobileWidth) {
    background: #FFFFFF;
    /* margin: 1rem 0; */
    max-height: unset;
    min-width: 100%;
    flex-direction: column;
    gap: 1rem;
    height: unset;
  }

  .property-resume__info {
    width: 100%;
    padding: 1rem 1.5rem 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (max-width: @mobileWidth) {
      padding: 0 1rem 1rem 1rem;
      gap: 1rem;
    }
  }

  /* > div{
    display: flex;
    max-height: 100%;
    justify-content: space-evenly;
    width: 80%;
    @media screen and (max-width: @mobileWidth) {
      width: 70%;
      align-items: center;
    }
  } */

  .closeButton{
    cursor: pointer;
    width: 29.67px;
    height: 30.8px;
    margin-right: 0.2rem;
  }

  /* &.blocked{
    cursor: pointer;
    opacity: .4;
    &:hover{
      opacity: 1;
    }
  } */

  &.selected{
    border-bottom: unset;
    background: #EEF7FE;
  }

  .propertyResume--photo {
    position: relative;
    min-width: 10rem;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.5rem 0 0 0.5rem;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      border-radius: 0.5rem 0.5rem 0 0;
      height: 12.5rem;
      /* padding: 3rem 2.25rem;
      width: unset;
      height: unset; */
    }

    .property__blocked {
      position: absolute;
      padding: 0.5rem;
      bottom: 0;

      .status {
        display: flex;
        border-radius: 0.25rem;
        background-color: #BE0F05;
        color: white;
        font-size: 0.75rem;
        font-weight: 700;
        padding: 0.15rem 0.25rem;

        .tooltip__wrapper {
          margin-left: 5px;
        }
      }
    }
  }

  .propertyResume--title{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .propertyResume--title_numRooms{
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }

    p {
      color: #5A6872;
      font-size: 0.75rem;
      font-weight: 500;

      @media screen and (max-width: @mobileWidth) {
        font-size: 0.75rem;
      }
    }

    h5 {
      color: #284F83;
      font-size: 1rem;
      font-weight: 700;
      margin: unset;

      @media screen and (max-width: @mobileWidth) {
        font-size: 1rem;
      }
    }
  }

  .propertyResume--prices {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      font-size: 0.875rem;
    }

    div {
      display: flex;
      gap: 0.25rem;

      @media screen and (max-width: @mobileWidth) {
        justify-content: space-between;
      }

      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: #5A6872;
  
        @media screen and (max-width: @mobileWidth) {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 20px;
          color: @grey5;
        }
      }
  
      h5 {
        margin: unset;
        font-size: 0.875rem;
        font-weight: 700;
        color: #434A5F;
  
        @media screen and (max-width: @mobileWidth) {
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 20px;
          color: @grey5;
        }
      }
    }
  }

  img {
    width:  11.67px;
    height: 19.8px;

    cursor: pointer;
  }
}