@import "../../../../../../../../_variables.less";

.OfferCard {
  cursor: pointer;
  background-color: white;
  box-shadow: @box_shadow;
  border-radius: 8px;
  gap: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12.5rem;

  p:not(:link){
    color: @grey4;
  }
  h5{
    margin: unset;
  }

  .Card__image {
    width: 160px;
    height: 100%;
    min-height: 150px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px;
  }

  .Card__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 150px);
    padding: 1.5rem 1.5rem 1.5rem 0;

    .compareArrowsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      background-color: rgba(40, 79, 131, 0.1);
      border-radius: 100%;
    }

    img{
      margin-right: 1rem;
    }

    .Card__property-info {
      display: flex;
      flex-direction: column;
      width: 300px;
      max-width: 300px;
      gap: 0.5rem;

      h5 {
        color: #284F83;
      }

      > p {
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
        max-width: 247px;
      }

      .property-details {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        svg {
          fill: #434A5F;
        }

        > span {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          color: #5A6872;
          font-size: 0.75rem;
          font-weight: 500;

          > img {
            margin-right: 10px;
            width: 8px;
            max-height: 10px;
          }

          > strong {
            font-weight: 700;
          }
        }
      }
    }

    .Card__tenant-info {
      display: flex;

      .avatar-container {

      }

      .tenant-details {
        max-width: 300px;
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: column;

          > p {
            font-size: 12px;
            font-weight: 700;
          }

          > span {
            display: inline-flex;
            font-size: 10px;
            color: @grey4;

            > img {
              width: 12px;
              margin-right: 4px;
            }

            > strong {
              margin-right: 3px;
              font-weight: 400;
              color: @grey6;
            }
          }
        }

        > div:not(:last-child) {
          margin-bottom: 8px;
        }

        > div:last-child {
          > span:not(:last-child) {
            margin-bottom: 3px;
          }
        }


      }
    }

    .Card__dates {
      display: flex;
      flex-direction: column;
      max-width: 280px;

      > p {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .dates-container {
        display: flex;
        flex-direction: column;
        min-height: 88px;

        .date-box {
          background-color: #f8f9fc;
          border-radius: 6px;
          height: 26px;
          width: 100%;
          padding: 0 10px;
          font-size: @11px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          > span {
            color: @grey4;
          }

          > strong {
            color: @grey6;
            font-weight: 400;
          }
        }
      }
    }

    .Card__actions {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;

      .expires-in {
        display: flex;
        margin-bottom: 10px;
        color: @grey4;
        font-size: @10px;
        font-weight: 500;

        > img {
          margin-right: 5px;
        }
      }

      > button.btn {
        font-size: 12px;

        > img {
          margin-right: 10px;
        }
      }

    }

    .reject-visit {
      color: @grey4;
      font-size: @10px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 10px;
    }

    .Card__summary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        border: 1px solid @grey3;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;

        > span {
          font-size: @11px;
        }
      }
    }
  }
}

