.vertical-timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem; /* Align content to the left */

  .timeline-item {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.25rem 0;
  }
  
  .dot {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #284F83;
    border-radius: 50%;
    color: white;
    font-size: 0.875rem;
    font-weight: 700;
  
    svg, svg path {
      fill: currentColor;
    }
  }
  
  .line {
    top: calc(100% + 0.5rem);
    position: absolute;
    width: 2px;
    height: calc(100% - 0.25rem);
    background-color: #284F83;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .content {
    color: #434A5F;
    font-size: 0.875rem;
    font-weight: 400;
  
    strong {
      font-weight: 700;
    }
  }
}
