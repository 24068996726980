@import "../../_variables";


.CheckBox{
  display:inline-flex;
  align-items: center;
  border:1px solid @greyMiddle;
  border-radius:3px;
  padding:5px 7px;
  margin-top: 10px;
  background: white;

  &:hover{
    cursor: pointer;
  }


  >label{
    margin-left: 8px;

    &:hover{
      cursor: pointer;
    }
  }

}

.right{
  border:none;
  display: flex;

  input{
    order:3;
    flex:0;
  }

  .check_mark{
    order:2;
  }

  label{
    order:1;
    flex:2
  }
}


.check_mark{
  display:inline-flex;
  justify-content:center;
  align-items:center;
  border: 1.5px solid #DFE3E6;
  border-radius: 3px;
  /* background-color: @greyMiddle; testing to see if it breaks */
  min-width: 19px;
  min-height:19px;
  height: 19px;
  max-width: 19px;
  flex:0.1;
  background: white;

  &:hover{
    cursor: pointer;
  }

  &.checked{
    background:@blue;

    svg{
      color:white;
    }
  }
}

&.transparent{
  background: transparent !important;
  /* padding-left: 5px !important; testing to see if something breaks */
  border: none !important;

  &.noPadding {
    padding: 0! important;
  }
}
