@import "../../../../_variables";

.RecoverContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .action {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    p {
      color: #434A5F;
      text-align: center;
    }

    .btn-container {
      width: 100%;

      a {
        text-decoration: none;
        flex: 1;
      }
    }

    .backToLogin {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #1D9AFA;
      text-align: center;
      font-size: 0.875rem;
      font-weight: 700;
      cursor: pointer;
  
      svg {
        width: 24px;
        fill: currentColor;
      }
    }
  }

  h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0;

    @media screen and (max-width: @mobileWidth) {
      text-align: center;
    }
  }

  p {
    font-weight: 500;

    strong {
      font-weight: 700;
    }

    &.status-success {
      color: #00af00;
    }

    &.status-error {
      color: #a50303;
    }
  }

  .base-info {
    width: 100%;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &.reset {
      justify-content: flex-end;
    }
  }

  .blue-link-label {
    text-decoration: none;
    color: #1D9AFA;
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
      color: #57B4F2;
    }

    &:active {
      color: #2845C9;
    }
  }
}
