@import "../../../../../../_variables";

//TopLanlordHeader
.TopLandlordHeader{
  align-items: center;
  background-color: rgba(@blue3,0.1);
  display: inline-flex;
  justify-content: space-between;
  padding: 30px 80px;
  margin-bottom: 50px;
  @media screen and (max-width: @mobileWidth) {
    flex-wrap: wrap;
    padding: 30px 20px;
  }

  h4{
    color:@blue;
    text-align: center;
    flex:0.9;
  }

  p{
    flex:1.6;
    @media screen and (max-width: @mobileWidth) {
      flex: unset;
      width: 100%;
      margin-top: 1rem;
    }
  }
}

//TopLandlordIndicators
.TopLandlordIndicators{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: @mobileWidth) {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }

  >div{
    width: 48.5%;
    margin-bottom: 20px;
  }
}

.TopLandlordIndicatorContainer{
  height: fit-content;
  padding:15px 40px 30px;
  min-height: 172px;
  min-width: 400px;
  width: 50%;
  @media screen and (max-width: @mobileWidth) {
    min-width: 100%;
  }

  .small{
    @media screen and (max-width: @mobileWidth) {
      white-space: nowrap;
    }
  }

  .TopLandlordIndicatorBox {
    &.indicatorStrength {
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }
  }

  >div{
    align-items: flex-start;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: @mobileWidth) {
      min-width: 100%;
    }
  }

}

@media (max-width: 905px) {
  .TopLandlordIndicatorContainer {
    flex-grow:1;
  }
}

.TopLandlordIndicatorBox{
  width: 32%;
  margin-bottom: 10px;

  @media screen and (max-width: @mobileWidth) {
    width: unset;
    max-width: 50%;
  }

  .small{
    @media screen and (max-width: @mobileWidth) {
      white-space: normal;
    }
  }

  &.indicatorStrength{
    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }
  }

  h6{
    margin: 0 0 5px 0;
  }

  >div{
    display: inline-flex;

    img{
      margin-left: 10px;
      @media screen and (max-width: @mobileWidth) {
        width: 10px;
      }
    }
  }
}


//ProgressBarWithIcons
.ProgressBarWithIcons{
  align-items: center !important;
  height: 80px;
  position: relative;
  width: 100%;
}

.ProgressBarWithIcons__bar{
  background-color: #8C9BA5;
  display: inline-flex;
  height: 5px;
  width: 100%;
  border-radius: 5px;

  &.no_data{
    background-color: @grey3;
  }

  .ProgressBarWithIcons__completed{
    background-color: @blue;
    overflow: visible;
    height: 100%;
  }
}


.ProgressBarIcon{
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.goalAchieved{
  background-color: rgba(67, 74, 95, 0.1);
  border-radius: 50%;
  position: absolute;
  height: 36px;
  width: 36px;

  >div{
    background-color: @black;
  }
}

.currentPosition{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 65px;
  position: absolute;

  .small{
    text-align: center;
  }

  h5,h6{
    margin: 0;
  }

  h5{
    color:@grey4;
  }

  >div{
    margin-top: 4px;
    background-color: @blue;
  }
}

.arrowIcons{
  position: absolute;
  left: 0;
  bottom:10px;
}



.TopLandlordRank{
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: 8px 80px;
  width: 100%;

  @media screen and (max-width: @mobileWidth) {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
  }


  .DashboardIcon{
    height: 48px;
    width: 48px;

    img{
      width: 50%;
      height: 50%;
    }

    &.top_landlord{
      background-color: rgba(@blue3,0.1);
    }

    &.rising_star{
      background-color: rgba(@green,0.1);
    }

    &.new_landlord{
      background-color: @grey3;
    }
  }

  h5{
    flex: 1.5;
    text-align: center;
  }

  p{
    flex:2;
    @media screen and (max-width: @mobileWidth) {
      flex: none;
      width: 100%;
    }
  }
}