@import "../../../../../../_variables";

@purpleGradient: linear-gradient(180deg, #1D9AFA 0%, #9575CD 100%);

.CancelReservation {
  background-color: white;
  box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-direction: column;


  .CancelReservation__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 50px 30px 30px;
    justify-content: center;

    .CancelReservation__content-header {
      display: flex;
      gap: 2rem;
      width: 100%;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .CancelReservation__timeline {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      > div{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;

        .title{
          @media screen and (max-width: @mobileWidth) {
            display: inline-flex;
            white-space: nowrap;
          }
        }
        .cancellation-time-line {
          .time-line {
            .mid-section {
              color: @grey4;
            }
          }
        }
        >div:first-child {
          display: flex;
          >img {
            margin-right: 21px;
          }
        }
      }

      .cancellation_policy_box {
        padding: 1.2rem;
        background-color: #EEF7FE;
        border: 1.5px solid @blue;
        border-radius: 11px;
        box-shadow: 2px 2px 8px rgba(196, 196, 196, 0.24);

        .title {
          font-size: @13px;
          font-weight: 600;
          color: @grey6;
          margin-bottom: 10px;
        }

        .body {
          font-size: @12px;
          color: @grey4;
        }

        a{
          font-size: @12px;
          color: @blue;
        }
      }


      .CancellationPolicy-dates {
        display: flex;
        margin-top: 36px;
        margin-bottom: 40px;
        .dates-box {
          display: flex;
          flex-direction: column;
          border-right: 1px solid @grey3;

          > div {
            display: flex;
          }

          .time-line {
            align-items: center;

            .number {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              color: @grey6;
              border-radius: 50%;
              background-color: @grey2;
              font-size: @12px;
            }

            .line {
              margin-left: 20px;
              margin-right: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
              max-width: 105px;
              border-radius: 100px;
              border-top: 1px solid @grey3;
            }

          }

          .labels {
            margin-top: 20px;
            margin-bottom: 20px;

            > span {
              font-size: @12px;
              color: @grey4;
              width: 105px;
            }

            > span:not(:last-child) {
              margin-right: 60px;
            }
          }

          .dates {
            > span {
              font-size: @12px;
              font-weight: bold;
              color: @grey6;
              width: 105px;
            }

            > span:not(:last-child) {
              margin-right: 60px;
            }

          }
        }

        .preWarning-section {
          display: flex;
          flex-direction: column;
          margin-left: 30px;

          .image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: @blue2;
          }

          .label {
            width: 97px;
            font-size: @12px;
            color: @grey4;
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 36px;
          }

          .days {
            font-size: @12px;
            color: @blue2;
          }
        }
      }
    }

    .title {
      font-size: @13px;
      font-weight: 600;
      color: @grey6;
    }

    .text {
      display: inline-flex;
      font-size: @12px;
      color: @grey4;
    }

    .CancelReservation__content-body {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .CancelReservation__content-body-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: @mobileWidth) {
          width: 100%;
        }

        .CancelReservation-reservation-info {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          > span:first-child {
            margin-bottom: 17px;
          }

          .text{
            @media screen and (max-width: @mobileWidth) {
              justify-content: space-between;
            }
          }

          > span:not(:first-child):not(:last-child) {
            margin-bottom: 5px;
            @media screen and (max-width: @mobileWidth) {
              margin-bottom: 1rem;
            }
          }
          >span {
            > strong {
              margin-left: 3px;
            }
          }
        }

        .CancelReservation__user-information {
          display: flex;
          align-items: center;
          width: 90%;
          height: 160px;
          background-color: @grey1;
          border: 1px solid @grey3;
          border-radius: 8px;
          margin-bottom: 11px;
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
          }

          .user-photo {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #FFFFFF;
            border-radius: 50%;
            width: 64px;
            height: 64px;
            margin-left: 36px;
            margin-right: 37px;
            background-position: center;
            background-size: cover;
            background-origin: border-box;
            box-shadow: 0 0 20px 2px rgba(90,90,90,0.1);
            position: relative;
            background-color: #f5f7fa;

            @media screen and (max-width: @mobileWidth) {
              margin-left: 10px;
              margin-right: 20px;
            }
          }

          .user-details {
            display: flex;
            flex-direction: column;

            .name {
              display: flex;
              margin-bottom: 4px;
              align-items: center;

              > span {
                font-size: 18px;
                color: @grey6;
                font-weight: bold;
                margin-right: 15px;
              }

              .country-flag {
                background-position: center;
                background-size: cover;
                width: 14px;
                height: 14px;
              }

            }

            > span {
              font-size: 12px;
              color: @grey5;
            }

            .numTenants-info {
              display: flex;
              margin-bottom: 25px;
              margin-top: 16px;
              font-size: 12px;
              color: @grey5;
              >strong {
                margin-right: 3px;
              }
            }
          }
        }

        .ReservationDetails__bottom-about {
          > div:not(:last-child) {
            margin-bottom: 40px;
          }
        }

        .ReservationDetails__bottom-reservation {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          justify-content: space-between;
          max-width: 80%;
          @media screen and (max-width: @mobileWidth) {
            flex-direction: column;
          }

          > div {
            display: flex;
            flex-direction: column;
            @media screen and (max-width: @mobileWidth) {
              margin-bottom: 15px;
            }

            > div:not(:last-child) {
              margin-bottom: 15px;
            }
          }

        }

      }

      .CancelReservation__content-body-right {
        width: 50%;
        display: flex;
        flex-direction: column;
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
          }
        >div:first-child{
          margin-bottom: 43px;
        }
        .policy-summary {
          display: flex;
          flex-direction: column;
          margin-bottom: 75px;
          @media screen and (max-width: @mobileWidth) {
            flex-direction: row;
          }
          .labels {
            display: flex;
            margin-bottom: 9px;
            @media screen and (max-width: @mobileWidth) {
              flex-direction: column;
              max-width: 50%;
              min-width: 50%;
            }

            > div {
              padding: 10px;
              display: flex;
              line-height: @12px;
              border-bottom: 1px solid @grey3;
              font-size: @9px;
              color: @grey4;
              flex: 1;
              position: relative;
              @media screen and (max-width: @mobileWidth) {
                min-height: 6rem;
                font-size: @10px;
              }

              &:before {
                content: '';
                position: absolute;
                width: 1px;
                background-color: @grey3;
                height: 50%;
                left: 0;
                bottom: 0;
              }

              &:last-child:after {
                content: '';
                position: absolute;
                height: 6px;
                width: 6px;
                transform: rotate(45deg);
                border-top: 1px solid @grey3;
                border-right: 1px solid @grey3;
                bottom: -3px;
                right: 0;
              }
            }
          }

          .boxes-info {
            display: flex;
            @media screen and (max-width: @mobileWidth) {
              flex-direction: column;
              max-width: 50%;
              min-width: 50%;
            }

            > div:not(:last-child) {
              margin-right: 5px;
            }

            > div {
              color: @grey6;
              font-weight: 500;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              padding: 5px;
              background-blend-mode: multiply, normal;
              border: 1px solid;
              box-sizing: border-box;
              border-radius: 2px;
              flex: 1;
              height: 77px;
              font-size: @7px;
              margin-left: 5px;
              background-image: repeating-linear-gradient(
                      -45deg, #5c5c5c1a, #5c5c5c1a 1px, transparent 1px, transparent 5px);
              @media screen and (max-width: @mobileWidth) {
                min-height: 6rem;
                font-size: @10px;
                min-width: 100%;
              }
            }

            .box-purple {
              background-color: rgba(204, 173, 243, 0.3);
              border-color: #CCADF3;
            }

            .box-yellow {
              background-color: rgba(238, 201, 113, 0.3);
              border-color: #EEC971;
            }

            .box-green {
              background-color: rgba(171, 229, 175, 0.3);
              border-color: #ABE5AF;
            }

            .box-blue {
              background-color: rgba(176, 195, 245, 0.3);
              border-color: #B0C3F5;
            }
          }
        }
        .reason-select{
          display: flex;
          flex-direction: column;
          margin-bottom: 25px;

          >label{
            margin-bottom: 25px;
          }

        }
        .availableFrom-picker {
          display: flex;
          flex-direction: column;
          margin-bottom: 35px;
          > .text {
            color: @grey6;
            margin-bottom: 5px;
          }
          .CustomDatePicker {
            .react-datepicker-popper {
              z-index: unset !important;
            }

            .react-datepicker {
              z-index: unset !important;
            }
          }

          .react-datepicker {
            color: @grey5;

            .react-datepicker__triangle {
              display: none;
            }
          }
        }
        .terms {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          .input-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            >input {
              width: 18px;
              height: 18px;
              background-color: white;
              border: 1px solid @grey5;
              margin-right: 10px;
              appearance: auto;
            }
            >label {
              margin-left: 6px;
              font-size: @12px;
              color: @grey6;
            }

          }
          >div:not(:first-child) {
            display: flex;
            align-items: center;
            .dot {
              min-width: 5px;
              height: 5px;
              background: @grey3;
              border-radius: 50%;
              margin-right: 10px;
            }
            >span {
              font-size: @10px;
              color: @grey4;
            }
          }
          >div:last-child {
            margin-top: 13px;
          }
        }

        .buttons {
          display: flex;
          >div{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: @14px;
            width: 260px;
            height: 36px;
            border-radius: 6px;
            cursor: pointer;
          }
          >div:first-child {
            background: #FFFFFF;
            border: 1px solid @grey4;
            color: @grey4;
            margin-right: 20px;
          }
          >div:last-child {
            border: 1px solid @blue2;
            background: @blue2;
            color: #FFFFFF;
          }
          .btn{
            @media screen and (max-width: @mobileWidth) {
             white-space: nowrap;
            }
          }
        }
        .required_field {
          color: @redError;
          margin-left: 5px;
          font-weight: 600;
        }
      }
    }
  }
}
