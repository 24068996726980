@import "../../../../_variables";

.LoginContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  .with-email-separator-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    hr {
      flex-grow: 1;
      height: 1px;
      background-color: #DFE3E6;
      border: none;
      margin: 0;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: #8C9BA5;
      white-space: nowrap;
    }
  }

  .base-info button:last-child {
    margin-top: 0.5rem;
  }

  .blue-link-label {
    text-decoration: none;
    color: #1D9AFA;
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
      color: #57B4F2;
    }

    &:active {
      color: #2845C9;
    }

    @media screen and (max-width: @mobileWidth) {
      text-align: center;
    } 
  }
}