@import "../../_variables";

.ActionDialog {

  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    min-height: 30vw;
    height: fit-content;
    width: 70vw;
    max-width: 700px;
    border-radius: 20px;

    .MuiDialogTitle-root {
      padding: 0;
    }
  }

  .ActionDialog__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 16px 16px 32px;


    >img {
      height: 160px;
      margin-top: 20px;
    }

    .title {
      font-size: @18px;
      color: @grey6;
      font-weight: 700;
      line-height: 24px;
      text-align: center;

    }

    .message {
      display: inline-block;
      background: none;
      font-weight: 500;
      font-size: @14px;
      color: @grey4;
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      width: 70%;
      margin-top: 24px;
      padding: 8px 16px;
      background-color: @blue2;
      font-size: @14px;
      line-height: 24px;
      color: @white;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
      }
    }

    .input-notification {
      display: flex;
      margin-top: 25px;
      align-items: center;
      >input {
        appearance: auto;
      }
      > label {
        font-size: @14px;
        color: @grey6;
        margin-left: 18px;
        font-weight: 500;
      }
    }

    .undo-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      width: 90%;
      margin-top: 24px;
      padding: 8px 0 8px 0;
      background-color: @grey2;
      font-size: @14px;
      line-height: 24px;
      color: @grey6;
      font-weight: 700;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        background-color: @grey3;
      }
    }
  }

}
