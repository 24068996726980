@import "../../../../_variables";

.Visits {
    margin-bottom: 2.5rem;

    .page__header__title h3 {
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }
}
