@import "../../../_variables";

@border-radius: 4px;

.RoomResume {
  height: 130px;
  width: 100%;
  background-color: white;
  border: 1px solid #DFE3E6;
  border-radius: @border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child):not(.Integrations) {
    border-radius: 0;
    border-bottom: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.type_Resume {
    height: 120px;
    border: 0;
    border-radius: 0;
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

  }

  p, time, label {
    font-size: @13px
  }

  .RoomResume__main {
    width: 400px;
    display: flex;
    align-items: center;

    .RoomResume__photo {
      background-size: cover;
      min-width: 112px;
      width: 112px;
      height: 112px;
      margin: auto 25px auto 8px;
      border-radius: 4px;
      box-shadow: 0 0 7px 3px #0000000d;
    }

    label {
      font-size: @18px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-break: break-word;

      > small {
        //font-size: @18px;
        //font-weight: bold;
        color: @grey4;
        vertical-align: text-bottom;
      }
    }

    .RoomResume__main-content {
      height: 100%;
      flex: 1;
      display: inline-flex;
      justify-content: flex-start;
      overflow: hidden;

      > div {
        display: flex;
        flex-direction: column;
      }

      .RoomResume__info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;

        .PropertyResume__header-main > h5 > small {
          margin-left: 5px;
          font-size: 14px;
          color: @grey5;
          @media screen and (max-width: @mobileWidth) {
            text-align: center;
          }
        }

        .RoomResume__info-title {
          color:@grey4;
          display: block;
          overflow: hidden;
        }
      }
    }
  }

  .RoomResume__complementary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    .confirm-availability {
      flex: 1.2;
    }

    &:not(.type_Resume) {
      padding-left: 0;
      padding-right: 8px;
      position: relative;
    }

    &.no_info{
      justify-content: flex-end;
    }

    .RoomResume__buttons {
      img {
        margin-right: 10px;
      }
    }


    .RoomResume__innerContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      > div {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
      }

      >p {
        color: @grey4
      }

    }
  }

}

.PriceAndAvailabilityComponent {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  flex: 2;
  @media screen and (max-width: @mobileWidth) {
    width: 100%;
  }

  > div {
    height: 78px;
  }

  p, time {
    font-size: @12px;
    line-height: 22px;
  }

  label {
    font-weight: bold;
    font-size: @14px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .RoomResume__price {
    max-width: 150px;
    min-width: 100px;
    flex-grow: 1;

    .RoomResume__innerContainer {
      padding-right: 10px;
    }

    p {
      width: 100%;
    }

  }

  .PopupState{
    align-items: center;
    display: inline-flex;
    justify-content: center;
    max-width: 40px;
    height: 20px;
  }

  .MuiButton-containedPrimary{
    background-color: transparent !important;
    box-shadow: none;
    width: fit-content;
    max-width: 20px;
    padding: 5px;

    .MuiTouchRipple-root{
      padding: 5px;
    }
  }

  .RoomResume__available {
    min-width: 105px;
    max-width: 160px;
    flex-grow: 1;

    .RoomResume__innerContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .AvailabilityComponent {
    .CustomDatePicker {
      width: 0;

      .react-datepicker__day--excluded, .react-datepicker__day--disabled, .passed_day {
        background-color: #ffe7e7;
        color: #8b8b8b;
        border-radius: 4px;
      }

      .react-datepicker {
        border: 1px solid #e3e6e9;

        .react-datepicker__header {
          background-color: #fcfcfc;
          border-bottom: 1px solid #e3e6e9;
        }
      }

      .react-datepicker__input-container {
        display: none;
      }

      .react-datepicker__triangle {
        display: none;
      }
    }
  }

  .RoomResume__pencilIcon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 1;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(171deg); //azul!
  }

  &.MyAds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    margin-bottom: 0;

    > div {
      display: flex;
      row-gap: 5px;
      width: 240px;
      @media screen and (max-width: @mobileWidth) {
        width: 100%!important;
        justify-content: space-between;
        margin: .5rem 0;
      }

      > :first-child {
        display: flex;
        flex-direction: row;
        height: fit-content;
        margin-right: -15px;

        > p, > time {
          font-size: @18px;
          font-weight: bold;
          width: fit-content;
        }


      }

      > p {
        color: @grey4;
      }

      .AvailabilityComponent {
        display: flex;
        flex-direction: row;
        height: fit-content;
        color: @blue;

        p {
          margin-right: 4px;
        }

        p:not(.TypeResidence), time {
          cursor: pointer;
        }

        img {
          margin-left: 8px;
        }
      }
    }
  }
  p.prices-interval {
    white-space: nowrap;
    display: flex;
    flex-direction: column;

    > small {
      color: gray;
      font-weight: 500;
      font-size: 12px;
      line-height: 8px;
      width: fit-content;
      text-decoration: line-through;
    }
  }

  .bills-included {
    font-size: 0.75rem !important;
    line-height: 22px !important;
    color: #8C9BA5 !important;
    font-weight: normal !important;
    white-space: nowrap;

    @media screen and (max-width: @mobileWidth) {
      margin-left: 5px;
    }
  }
}

.unavailabilities-popper {
  display: flex;
  flex-direction: column;
  background-color: @greySoft;
  color: @grey6;
  padding: 15px;
  border-radius: 8px;
  >span {
    font-size: @14px;
  }
  >span:not(:last-child) {
    margin-bottom: 5px;
  }
}

.MuiBox-root{
  display: flex;
  flex-direction: column;
  box-shadow: @box_shadow;
  justify-content: flex-end;
  align-items: flex-end;
  background: white;
  z-index: 999;
  border: 1px solid #e3e6e9;
  border-radius: 3px;

  label{
    font-size: @14px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
  }

  button{
    align-items: center;
    display: inline-flex;
    justify-content: space-around;
    margin-top: 10px;
    width: fit-content;
  }

  p{
    margin-right: 7px;
  }

  .basic_container{
    width: 150px;
  }
}
