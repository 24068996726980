@import "../../../../../../../../../../../_variables";

.RoomPrices {
  align-items: center;
  flex-direction: row !important;
  width: 935px;

  form {
    width: 100%;


    .scrollable {
      width: 100%;
      > div{
        max-width: 500px;
      }
      .RoomDetailsHeader{
        max-width: 700px;
      }

      .PriceComponent.input_field {
        padding-top: 0!important;
      }
    }

    title{
      width: 100%;
      max-width: 900px;
    }

    .AddExternalUnavailabilities {
      min-height: 150px;
      height: fit-content;
      padding: 12px 20px;
      display: flex;
      flex-direction: column;

      >label {
        font-weight: bold;
        margin-bottom: 5px;
      }

      > div {
        display: inline-flex;
        width: 100%;
        margin-bottom: 10px;

        input {
          flex: 1;
          margin-right: 10px;
        }
      }

      .label_as_placeholder{
        position: relative;
        flex:1;
        margin-right: 10px;

        input {
          width: 100%;
        }

        input + label {
          position: absolute;
          left: 10px;
          top: 7px;
          opacity: 0.7;

          &:hover{
            cursor: auto;
          }
        }

        .input-container {
          position: relative;
        }

        input::placeholder {
          opacity: 0;
        }

        /* show the placeholder when input has no content (no content = invalid) */
        input:placeholder-shown + label {
          display: inline-block;
        }

        /* hide the placeholder when input has some text typed in */
        input:not(:placeholder-shown) + label{
          display: none;
        }
      }

      p {
        width: 270px;
      }

      .error {
        color: @redError;
        font-size: @12px;
        margin-bottom: 10px;
        margin-top: -5px;
      }

      button {
        width: 90px;
        display: table-cell;
        vertical-align: middle;
      }

      #export_input{
        &:hover{
          cursor: auto;
        }
      }

      .export_button_pt:focus {
        font-size: 0;
        background-color: #166294;
        &:after {
          content: 'Copiado';
          font-size: 14px;
        }
      }

      .export_button_en:focus {
        font-size: 0;
        background-color: #166294;
        &:after {
          content: 'Copied';
          font-size: 14px;
        }
      }
    }

    #availabilities{
      display: inline-flex;

      #availabilitiesInfo{
        margin-right: 70px;
        min-width: 100%;

        .openResidenceCalendar {
          display: inline-flex;
          align-items: center;
          padding-left: 0!important;

          .checkbox__label {
            margin-left: 12px;
            color: @grey5;
            font-size: @12px;
          }
        }

        small.unavailability-residence-warning {
          padding-left: 20px;
          position: relative;
          margin-bottom: 10px;

          > img {
            position: absolute;
            top: 2px;
            left: 2px;
            filter: brightness(0.6);
            width: 12px;
            padding-top: 2px;
          }
        }
      }
    }

    .UnavailabilityDatesTable {
      padding: 12px 20px;
      font-size: @12px;

      .UnavailabilityEventUrlContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .UnavailabilityEventUrl {
          font-size: @11px;
          color: black;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 10px;

          &:not(:first-child) {
            margin-top: 15px;
          }
        }

        img {
          padding: 5px;
          cursor: pointer;
        }
      }



      table {
        width: 100%;

        tr {
          height: 34px;
          border-radius: 4px;

          &.removed-date {
            color: @redError;
          }

          td {
            &:first-child {
              padding-left: 10px;
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &:last-child {
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }

            &.event-domain {
              max-width: 65px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .UnavailabilityDatesTable__delete {
              height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;


              p {
                font-size: inherit;
                margin-right: 5px;
                color: @blue;
                text-decoration: underline;
              }

              &:hover {
                cursor: pointer;

                p {
                  color: @blue1;
                }
              }
            }
          }

          &:nth-child(even) {
            background: fade(@grey4, 10%);
          }
        }
      }
    }

    .infoContainerWithIcon {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .infoIcon {
        width: 15px;
        height: 15px;
        padding-top: 5px;
        margin-right: 10px;
      }

      .infoText {
        font-size: @12px;
        color: @grey5;

        > strong {
          font-weight: 700;
        }
      }
    }

    .checkbox {
      margin-bottom: 24px;
    }
  }
}

.delete-availabilities-modal {
  .MuiDialog-container .MuiPaper-root {
    max-height: calc(100vh - 120px);
    height: fit-content;
    max-width: calc(100vw - 50%);
    padding: 24px;

    .MuiDialogTitle-root {
      display: flex;
      justify-content: center;

      > h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #284F83;
      }
    }

    .DialogCloseButton {
      display: none;
    }
  }

  .delete-availabilities-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #8C9BA5;
    }

    .delete-availabilities-modal__buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      > button {
        display: flex;
        padding: 8px 16px;
        font-weight: 700;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }

      .btn-primary {
        color: white;
        background: #1D9AFA;
      }

      .btn-secondary {
        color: #434A5F;
        background: #F0F3F6;
      }
    }
  }
}
