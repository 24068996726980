@import "../../../../_variables.less";

.MakeOffers {
    margin-bottom: 2.5rem;
    
    .PotentialTenantsList .page__header {
        margin-bottom: 1rem;

        @media screen and (max-width: @mobileWidth) {
            margin: unset;
        }
    }
}
