@import "../../../../../../_variables.less";

.PotentialTenantsList {
  position: relative;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;

  .page__header__title h3 {
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  @media screen and (max-width: @mobileWidth) {
    gap: 1.5rem;
  }

  .page__header div p {
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  .Loading {
    position: absolute;
    width: 100%;
    height: 150px;
  }

  .PotentialTenantsList__p {
    text-align: center;
  }

  .basic_container {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  .PotentialTenantsList__darkPage {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .55);
    z-index: 999;
    content: " ";
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }

  .rooms {
    display: none;
    transition: all 1s;
  }

  .offer_Card {
    transition: all 0.3s;

    .OfferCard {
      @media screen and (max-width: @mobileWidth) {
        max-height: unset;
        min-height: unset;
        flex-direction: column;
      }

      .Card__image {
        @media screen and (max-width: @mobileWidth) {
          min-width: 100%;
          min-height: 70px;
          max-height: 70px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          margin-right: unset;
        }
      }

      .Card__body {
        @media screen and (max-width: @mobileWidth) {
          flex-direction: column;
          width: 100%;
        }

        img{
          @media screen and (max-width: @mobileWidth) {
            margin-right: unset;
          }
        }

        .Card__property-info {
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            padding: 1rem;
          }

          h5 {
            @media screen and (max-width: @mobileWidth) {
              text-align: center;
              font-style: normal;
              font-weight: 700;
              font-size: @13px;
              line-height: 18px;
              color: @grey6;
            }
          }

          .property-details {
            > span {
              > span {
                @media screen and (max-width: @mobileWidth) {
                  font-weight: 600;
                  color: @grey5;
                }
              }
            }
          }
        }

        .compareArrowsContainer {
          @media screen and (max-width: @mobileWidth) {
            display: none;
          }
        }

        .TenantInfo {
          @media screen and (max-width: @mobileWidth) {
            border-top: 1px solid #DFE3E6;
            border-bottom: 1px solid #DFE3E6;
            padding: 1rem;
          }

          &.fullIcon {
            .TenantPhoto {
              &.avatar-container {
                @media screen and (max-width: @mobileWidth) {
                  margin-right: 15px !important;
                  width: 40px !important;
                  height: 40px !important;
                }
              }
            }
          }

          .TenantName {
            h4 {
              @media screen and (max-width: @mobileWidth) {
                font-size: @18px;
                color: @grey6;
              }
            }
          }

          .tenant-details_Container {
            .RequestDates {
              @media screen and (max-width: @mobileWidth) {
                display: none;
              }
            }

            .small {
              &.mobile {
                @media screen and (max-width: @mobileWidth) {
                  display: none;
                }
              }
            }
          }
        }

        .tenant_dates_info {
          display: none;
          @media screen and (max-width: @mobileWidth) {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            width: 100%;
          }

          div {
            display: inline-flex;
            align-items: center;

            img {
              width: 10px;
              height: 10px;
              margin-right: .5rem;
            }

            p {
              font-size: @10px;

              strong {
                font-weight: 600;
                color: @grey5;
              }
            }
          }
        }
      }
    }

    .roomResume {
      &.offer {
        @media screen and (max-width: @mobileWidth) {
          max-height: unset;
          height: fit-content;
          min-height: fit-content;
          flex-direction: column;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }

        .roomResume--adds {
          @media screen and (max-width: @mobileWidth) {
            flex-direction: column;
            max-height: unset;
            height: fit-content;
            min-height: fit-content;
            width: 100%;
            max-width: 100%;
            margin-left: unset;
          }

          .roomResume--photo {
            @media screen and (max-width: @mobileWidth) {
              width: 100%;
              min-width: 100%;
              max-width: 100%;
              min-height: 70px;
              padding: unset;
              margin-top: 0;
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;
            }
          }

          .roomResume--title {
            @media screen and (max-width: @mobileWidth) {
              padding: 1rem .5rem;
              min-height: unset;
              justify-content: space-around;
              width: 100%;
              margin-left: unset;
              padding-left: 1rem;
              max-width: 100%;
              overflow: hidden;
            }

            h5 {
              @media screen and (max-width: @mobileWidth) {
                font-weight: 700;
                font-size: @13px;
                line-height: 18px;
              }
            }

            .info {
              .detailsSection {
                .IconContainerWithDescription {
                  .iconContainer {
                    img {
                      @media screen and (max-width: @mobileWidth) {
                        height: 15px;
                      }
                    }
                  }

                  .description-wrapper {
                    @media screen and (max-width: @mobileWidth) {
                      align-items: center;
                    }

                    label {
                      @media screen and (max-width: @mobileWidth) {
                        align-items: center;
                        font-size: @10px;
                        color: @grey4;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }

            .roomResume--rent_container {
              @media screen and (max-width: @mobileWidth) {
                padding: 0 .5rem;
              }

              .rent {
                div {
                  h4 {
                    @media screen and (max-width: @mobileWidth) {
                      font-weight: 700;
                      font-size: @13px;
                      line-height: 18px;
                    }
                  }
                }
              }

              > div {
                p {
                  img {
                    @media screen and (max-width: @mobileWidth) {
                      min-height: 10px;
                      max-height: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        .status {
          @media screen and (max-width: @mobileWidth) {
            padding: 0 1rem 1rem 1rem;
            width: 100%;
            align-items: center;
          }

          > div {
            @media screen and (max-width: @mobileWidth) {
              margin-right: unset;
            }

            p {
              @media screen and (max-width: @mobileWidth) {
                white-space: nowrap;
              }
            }

            span {
              @media screen and (max-width: @mobileWidth) {
                padding: 3px 13px;
                font-size: @10px;
              }
            }
          }

          .MuiMenuItem-root {
            @media screen and (max-width: @mobileWidth) {
              min-height: 30px;
            }
          }
        }
      }
    }
  }
}
