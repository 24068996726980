@import "../../../../../../../../../_variables";

.LandlordRules{
  margin-left: 5px;
  font-size:0.9rem;

  .CloneInformation{
    width:90%;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }

  .LandlordRules_container{
    padding-bottom: 20px;
    width: 100%;


    &:not(.LandlordRules_container_last):not(:last-child) {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    }

    .input_container{
      &:first-child {
        margin-top: 30px;
      }

      &:last-child{
        margin-bottom:20px;
      }
    }
  }

  .second_input_container{
    margin-top:15px;

  }

  h4{
    font-size: 1rem;
  }
}
