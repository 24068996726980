@import './_variables.less';

.container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  /*@media (max-width: @mobileWidth) {
    width: 95%;
  }*/

  padding-left: env(safe-area-inset-left, 0px);
  padding-right: env(safe-area-inset-right, 0px);
}

.initial_margin {
  margin-top: 85px;
}

form {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//text
h1 {
  line-height: 42px;
  font-size: 1.75rem;
}

h2 {
  line-height: 30px;
  font-size: 1.563rem;
}

h3 {
  line-height: 28px;
  font-size: 1.375rem;
}

h4 {
  line-height: 24px;
  font-size: 1.125rem;
}

h5 {
  line-height: 22px;
  font-size: 1rem;
  color: @grey6;
}

h6 {
  line-height: 18px;
  font-size: 0.813rem;
}

p {
  line-height: 22px;
  font-size: 0.875rem;

  &.bold {
    font-weight: bold;
  }

  &.small {
    line-height: 20px;
    font-size: 0.75rem;
    color: #434A5F;
    font-weight: 700;

    span {
      color: #5A6872;
      font-weight: 500;
    }
  }
}

label {
  color: @grey6;
}

.link {
  line-height: 24px;
  font-size: 0.875rem;
}

.caption {
  line-height: 18px;
  font-size: 0.625rem;
}


.input_field {
  border: 1px solid @grey3;
  border-radius: 8px;
  height: 36px;
  padding: 8px 16px;
  color: #434A5F;
  font-weight: 400;
  font-size: 14px;

  &.input_error {
    border: 1px solid @redBorder;
    background-color: @redBackGround;
  }

  &:disabled {
    opacity: 0.7;
    background-color: #fbfbfb;
  }

  &.disabled {
    opacity: 0.7;
  }

  &:not(.disabled) {
    &:not(:disabled) {
      &:not(:focus) {
        &:hover {
          border-color: @greyHover;
        }
      }
    }
  }

  &:focus {
    border: 1px solid @blueFocus;
  }
}

//.hover_focus_border{
//  &:hover{
//    &:not(.disabled)
//    border-color:@greyHover;
//  }
//
//  &:focus {
//    border: 1px solid @blueFocus;
//  }
//}

.error_message {
  font-size: @14px;
  color: @redError;
}

.success_message {
  font-size: @14px;
  color: @green;
}

.basic_container {
  border-radius: 8px;
  background-color: white;
  box-shadow: @box_shadow
}

.clickable {
  cursor: pointer;
}

.input_container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 40px;
  font-size: @14px;

  &.input_info {
    margin-bottom: 30px;
  }

  > label {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: @13px;
    color: @grey6;
  }

  .info {
    margin-top: 3px;
    font-weight: 500;
    color: @grey;
    font-size: 0.8rem;
  }
}

.half_input_container {
  width: 45%;
}

.input_container_double {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    > .input_container {
      width: 100%;
    }
  }

  &.input_info {
    margin-bottom: 30px;
  }

  > .input_container {
    margin-bottom: 0;

    label {
      width: 300px;
      //max-width: 300px;
    }
  }

  > .info {
    font-weight: 400;
    color: @grey5;
    font-size: @12px;

    &:first-child {
      margin-top: 3px;
    }
  }


  > div {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-bottom: 15px;
  }
}


//buttons
.btn {

  &.small{
    width: 2rem;
    padding: 0 5px;
  }

  /* svg,img {
    margin-right: 3px;
  } */

  /* &:disabled, &.disabled {
    opacity: 0.5;
    cursor: not-allowed!important;
  } */

  &.block {
    display: block;
    line-height: 40px;
    text-align: center;
  }

  @media (max-width: @mobileWidth) {
    font-size: 12px;
  }
}

.btn-delete {
  background-color: @redBorder;
  color: @white;
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }
}

.btn_blue {
  background: @blue;
  color: white;

  &.shadow {
    box-shadow: 0 4px 40px #bde2ff8a;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.active {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  &:not(:disabled) {
    &:focus {
      outline: 0 !important;
      text-shadow: -1px 1px 3px rgba(255, 255, 255, 0.3);
    }

    &:hover {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}


.btn_black {
  background: black;
  color: white;
}

.btn_black:focus {
  outline: 0 !important;
  text-shadow: -1px 1px 3px rgba(255, 255, 255, 0.3);
}

.btn_black:hover {
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.btn_grey {
  background: @grey;
  color: white;
}

.btn_grey:focus, .btn_grey_soft:focus {
  &:not(:disabled) {
    outline: 0 !important;
    text-shadow: -1px 1px 3px rgba(255, 255, 255, 0.3);
  }
}

.btn_grey:hover, .btn_grey_soft:hover {
  &:not(:disabled) {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.btn_grey_soft {
  background: #F5F7FA;
  color: #6F6F6F;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btn_white {
  background: #F0F3F6;
  color: #434A5F;
}

.btn_white:focus {
  &:not(:disabled) {
    outline: 0 !important;
    text-shadow: -1px 1px 3px rgba(255, 255, 255, 0.3);
  }
}

.btn_white:hover {
  &:not(:disabled) {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.link {
  text-decoration: underline;
  font-size: @12px;

  &:hover {
    cursor: pointer;
  }
}

.link_blue {
  color: @blue;
}

.link_grey {
  color: @grey4
}

.hidden{
  display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


///* Firefox */
//input[type=number] {
//  -moz-appearance: textfield;
//}


//.Select {
//
//  .Select__control {
//    max-height: 36px;
//
//    &.Select__control--is-focused {
//      border: 1px solid @blueFocus !important;
//    }
//
//    &:hover {
//      border-color: @greyHover;
//    }
//  }
//
//  &.input_field {
//    padding-left: 0px;
//  }
//
//  &.input_error {
//
//    .Select__control {
//      border: 1px solid @redBorder;
//      background-color: @redBackGround;
//      border-radius: 5px;
//    }
//  }
//
//  .Select__value-container {
//    padding-left: 10px;
//  }
//
//  .Select__control:hover {
//    border: 1px solid #8C9BA5;
//  }
//
//
//  .Select__menu-list {
//    max-height: 200px !important;
//    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
//    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
//
//
//    &::-webkit-scrollbar {
//      width: 4px;
//      height: 0px;
//    }
//
//    &::-webkit-scrollbar-track {
//      background: #f1f1f1;
//    }
//
//    &::-webkit-scrollbar-thumb {
//      background: @blue;
//    }
//
//    &::-webkit-scrollbar-thumb:hover {
//      background: @blue;
//    }
//  }
//
//  .SelectOptionContainer {
//    width: 100%;
//
//    > .Select__option {
//      width: 100%;
//      display: inline-flex;
//      align-items: center;
//
//      label {
//        margin-left: 5px;
//
//        &.not-selected {
//          margin-left: 19px;
//        }
//      }
//    }
//
//    &.disabled {
//      background: @grey1 !important;
//      color: @grey3 !important;
//
//    }
//
//    &.selected {
//      background: @blueSoft !important;
//      color: @blue !important;
//    }
//
//    &:not(.disabled) {
//      &:not(.selected) {
//        &:hover {
//          background: @blueSuperSoft;
//        }
//      }
//    }
//
//    svg {
//      color: @blue;
//    }
//  }
//
//  .Select__single-value {
//    color: @blue;
//  }
//
//
//  .Select__option__multi {
//    display: inline-flex;
//    align-items: center;
//
//    label {
//      margin-right: 5px;
//    }
//  }
//
//}


.crisp-client div#crisp-chatbox {
  z-index: 1200;
  /*&[data-large-view=true][data-full-view=false] > div > div:first-child {
    max-height: 645px!important;
  }
  &[data-large-view=false][data-full-view=false] {
    > div > div:first-child {
      max-height: 525px!important;
    }
    &[data-small-view=true] > div > div:first-child {
      max-height: 445px!important;
    }
  }
  div[data-cshid] a[href^='https://crisp.chat/livechat/?ref=chatbox'] {
    height: 0!important;
    opacity: 0;
    pointer-events: none;
  }*/
}

.avatar-container {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  height: 48px;
  margin-right: 15px;
  position: relative;
  width: 48px;
  margin-bottom: 20px;
  border: 2px solid white;
  background-origin: border-box;
  box-shadow: 0 0 20px 2px rgba(90,90,90,0.1);
  background-color: #f5f7fa;
}

img.flip_img {
  transform: scale(-1);
}



/**
Design
System
**/

/* Pages Headers */

.page__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.page__header__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h3 {
    color: #1D9AFA;

    /* @media screen and (max-width: @mobileWidth) {
      display: none;
    } */
  }
}

/* Buttons */

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 700;
  min-height: 40px;
  height: fit-content;
  border: none;
  padding: 0.5rem 1rem;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  gap: 0.5rem;
  cursor: pointer;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.btn-primary {
  background-color: #1D9AFA;
  color: white;

  svg {
    fill: currentColor;
  }

  &:hover {
    background-color: #57B4F2;
  }

  &:active {
    background-color: #2845C9;
  }

  &:disabled {
    background-color: #DFE3E6;
  }

  &--danger {
    background-color: white;
    color: #FA392E;

    &:hover {
      background-color: #FFEDE9;
    }
  }
}

.btn-secondary {
  background-color: #F0F3F6;
  color: #434A5F;

  svg {
    fill: currentColor;
  }

  &:hover {
    background-color: #DFE3E6;
  }

  &:active {
    background-color: #CCDCE6;
  }

  &:disabled {
    background-color: #DFE3E6;
    color: #8C9BA5;
  }

  &--danger {
    background-color: #F0F3F6;
    color: #FA392E;

    &:hover {
      background-color: #CCDCE6;
    }
  }
}

.btn-tertiary {
  background: #FFFFFF;
  color: #5A6872;
  box-shadow: 0px 1px 3px 1px rgba(67, 74, 95, 0.15), 0px 1px 2px 0px rgba(67, 74, 95, 0.30);

  svg {
    fill: currentColor;
  }

  &:hover {
    background: #DFE3E6;
    color: #434A5F;
    box-shadow: 0px 2px 6px 2px rgba(67, 74, 95, 0.15), 0px 1px 2px 0px rgba(67, 74, 95, 0.25);
  }

  &:active {
    background: #DFE3E6;
    color: #434A5F;
    box-shadow: 0px 1px 3px 1px rgba(67, 74, 95, 0.15), 0px 1px 2px 0px rgba(67, 74, 95, 0.30);
  }

  &:disabled {
    background: #F5F8FA;
    color: #B4C4D7;
    box-shadow: none;
  }
}

.btn-ghost {
  background: transparent;
  color: #1D9AFA;

  svg {
    fill: currentColor;
  }

  &:hover {
    color: #57B4F2;
  }

  &:active {
    color: #2845C9;
  }

  &:disabled {
    color: #DFE3E6;
  }

  &--danger {
    color:#FA392E
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;

  label {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: #284F83;
  }

  input {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #DFE3E6;
    background: white;
    box-shadow: 0 4px 40px rgba(196, 196, 196, 0.25);
  }
}

/* Dialog */

.modal {
  /* background: #FFFFFF;
  border-radius: 8px;
  padding: 24px 16px;
  padding: 1.5rem; */

  .MuiDialog-container .MuiPaper-root {
    justify-content: center;
    align-items: center;
    max-height: 75%;
    max-width: 50%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    /* height: fit-content; */
    min-height: 25%;
    border-radius: 0.5rem;
    padding: 1.5rem;

    .MuiDialogContent-root {
      padding: 0;
      flex: none;
      overflow: hidden;
    }

    @media screen and (max-width: @mobileWidth) {
      max-height: 100vh;
      width: 90vw;
      height: fit-content;
    }
  }

  .modal__container {
    height: 100%;
    text-align: center;

    .modal__container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }

      .modal__container__header__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #1D9AFA;
      }

      .modal__container__header--no-title {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .modal__container__content {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
}

/* SVG */

svg {
  fill: currentColor;
}


.ToggleSwitch {
  display: inline-flex;
  width: fit-content;

  > input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  > label {
    cursor: pointer;
    width: 45px;
    height: 20px;
    background: @grey4;
    display: block;
    border-radius: 13px;
    position: relative;
  }
}

.ToggleSwitch input[type=checkbox]:checked + label {
  background: @blue3;
}

.ToggleSwitch input[type=checkbox]:checked + label::after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.ToggleSwitch label::after {
  content: "";
  position: absolute;
  top: calc(50% - 18px / 2);
  left: 1px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}
.photographs-tips-tooltip {
  min-width: 400px;
  padding: 15px!important;

  .textbox {
    p:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

/* Datepicker */

/* .react-datepicker-popper {
  width: 100%;
  max-width: 340px;
  padding: 16px;
}

.react-datepicker {
  width: 100%;
  background: white;
  box-shadow: 2px 2px 8px rgba(196, 196, 196, 0.24);
  border-radius: 8px;
  font-family: 'Inter';

  &__month {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  &__triangle {
    display: none;
  }

  &__month-container {
    width: 100%;
    .react-datepicker {

      &__header {
        background-color: #EEF7FE;
        padding: 24px 0;
      }

      &__current-month {
        font-weight: 700;
        font-size: 18px;
        color: #1D9AFA;
      }

      &__day-names {
        font-weight: 700;
        font-size: 16px;
        color: #284F83;
      }
    }
  }
} */
