@import "../../_variables";

.HorizontalNavBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - @footerheight);
  position: relative;
  @media screen and (max-width: @mobileWidth) {
    height: 100%;
  }

  .container.false {
    display: none;
  }

  &.main {
    #horizontalbar_content {
      margin-top: 2.5rem;

      @media screen and (max-width: @mobileWidth) {
        margin-top: 1rem;
      }
    }
  }

  .auth-container {
    height: 4rem;
    margin: 0;
    padding: 0;
  }

  .white_bg {
    min-width: 100%;
    background: #fff;
    top: 60px;
    height: 48px;
    position: fixed;
    border-bottom: 1px solid #f5f7fa;
    z-index: 100;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }

    > .container {
      height: 55px;
    }

    &.boxShadow {
      box-shadow: 0 2px 5px rgba(240, 243, 246, 0.25);
    }
  }

  .horizontalbar-dashboard {
    background: #fff;
    display: inline-flex;
    width: 100%;
    height: 47px;
    align-items: center;
    justify-content: space-between;

    .links-dashboard {
      height: 48px;
      display: inline-flex;
      align-items: center;
      width: fit-content;

      a, span.link {
        width: fit-content;
        display: flex;
        align-items: center;
        font-size: @12px;
        text-decoration: none;
        border-bottom: 2px transparent solid;
        height: 100%;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 10px;
        transition: border-right-color 0.3s;
        cursor: pointer;

        @media screen and (max-width: @mobileWidth) {
          padding-left: 0px;
        }

        label {
          cursor: pointer;
        }

        &:hover {
          border-bottom-color: @grey;
        }

        &.active {
          border-bottom-color: @blue;
          font-weight: 700;

          label {
            color: @blue;
          }
        }

        a.active {
          color: @blue;
        }
      }
    }
  }

  .horizontalbar {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: @mobileWidth) {
      display: none;
    }

    .links {
      height: 100%;
      display: flex;
      align-items: center;
      width: fit-content;

      a, span.link {
        width: fit-content;
        display: flex;
        align-items: center;
        font-size: @12px;
        text-decoration: none;
        border-bottom: 2px transparent solid;
        height: 100%;
        position: relative;
        text-align: center;
        vertical-align: middle;
        //padding-left: 20px;
        //padding-right: 20px;
        //margin-right: 10px;
        transition: border-right-color 0.3s;
        cursor: pointer;

        @media screen and (max-width: @mobileWidth) {
          padding-left: 0px;
        }

        &:hover {
          background: #EEF7FE;
          color: #77C2FC;
        }

        &:hover .label {
          color: #77C2FC;
        }

        label {
          &:hover {
            cursor: pointer;
            color: #77C2FC;
          }
        }
      }

      a:hover .label {
        color: #77C2FC;
      }
    }
  }

  //Secondary NavBar
  &.secondary {
    .white_bg {

      //If it is the secondary bar the bottom border is shorter
      &.secondary {
        top: 108px;
        height: 32px;

        .container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .horizontalbar {
          height: 32px;

          .links {
            height: 32px;

            a {
              border-bottom: none !important;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding-top: 7px;

              &:first-child {
                margin-left: -18px;
              }

              .underline {
                height: 1.5px;
                width: 50%;
                max-width: 48px;
              }

              &:hover {
                .underline {
                  background-color: @grey;
                }
              }


              &.active {
                label {
                  color: @blue3 !important;
                }

                .underline {
                  background-color: @blue3;
                }
              }
            }
          }
        }


      }

      > .container {
        height: 55px;
      }

      &.boxShadow {
        box-shadow: 0 2px 5px rgba(240, 243, 246, 0.25);
      }
    }

    #horizontalbar_content {
      margin-top: 48px;
      padding-bottom: 0;
    }
  }


  //Start Page
  &.start_page {

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      overflow: scroll;
    }

    /* .white_bg, .container, .horizontalbar {
      height: fit-content;
    } */

    .auth-container {
      position: relative;
      width: 100%;
      background: #F0F3F6;
      border-radius: 0.5rem 0.5rem 0 0;
      display: flex;
      align-items: flex-start;
    }

    .auth-container::after {
      position: absolute;
      content: "";
      bottom: -0.5rem;
      width: 100%;
      height: 0.5rem;
      background-color: white;
    }

    .horizontalbar-auth {
      width: 100%;
    }

    .links-auth {
      width: 100%;
      height: 100%;
      display: flex;
      background: #F0F3F6;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .links-auth a {
      padding: 1rem 2.5rem;
      height: 100%;
      font-size: 1.375rem;
      font-weight: 700;
      background: #F0F3F6;
      border-radius: 0.5rem 0.5rem 0 0;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-wrap: nowrap;

      &:hover {
        background: #EEF7FE;

        label {
          color: #77C2FC;
        }

        ~ .container {
          background-color: #EEF7FE;
        }
      }

      label {
        font-size: 1.375rem;
        color: #8C9BA5;
      }

      &.active {
        background: white;

        label {
          color: #1D9AFA;
        }
      }
    }

    .auth-container.active {
      background: white;

        &.active {
          label {
            color: #1D9AFA;
          }
        }
      }

    #horizontalbar_content {
      display: flex;
      justify-content: flex-start;
      //margin-top: 2.5rem;
      box-shadow: 0px 4px 40px 0px rgba(195, 195, 195, 0.25);
      //padding-bottom: 48px; Esta a criar um white space no footer

      @media screen and (max-width: @mobileWidth) {
        margin-top: 0;
        max-width: 100vw;
        overflow-x: hidden;
        padding: 2.5rem 1rem;
      }

      > div {
        width: 100%;
      }


      .propertyType__icon {
        width: 36px;
        height: 36px;
        background-color: @blueSoft;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  #horizontalbar_content {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    //padding-bottom: 48px; Esta a criar um white space no footer

    @media screen and (max-width: @mobileWidth) {
      margin-top: 0;
      max-width: 100vw;
      overflow-x: hidden;
    }

    > div {
      width: 100%;
      margin-bottom: 2.5rem;
    }

    .propertyType__icon {
      width: 36px;
      height: 36px;
      background-color: @blueSoft;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .potential-tenants {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

}
