@import "../../../../_variables";

.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .base-info {
      .none {
        display: none;
      }

      .input-container .CountryAndPhoneSelect {
        .ReactFlagsSelect-module_flagsSelect__2pfa2 {
          height: 40px;

          button {
            background: white;
          }
        }
      }
    }

    .btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      button {
        flex: 1;
      }
    }
  }

  form .btn {
    margin-top: 0.5rem;
  }

  .verification-container__steps {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .verification-container__steps__icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2.5rem;
      gap: 1rem;
      color: #8C9BA5;

      @media screen and (max-width: @mobileWidth) {
        padding: 0 1.5rem;
      }

      .icon {
        display: flex;
        align-items: center;
        padding: 0.4rem;
        background-color: #F5F7FA;
        border-radius: 100%;

        .active {
          svg {
            background-color: #EEF7FE;
            color: #1D9AFA;
          }
        }
      }

      .icon.active {
        background-color: #EEF7FE;

        svg {
          color: #1D9AFA;
        }
      }

      .icon.validated {
        background-color: rgba(45, 201, 40, 0.1);

        svg {
          color: #2DC928;
        }
      }
    }

    .verification-container__steps__labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75rem;
      font-weight: 500;
      color: #8C9BA5;

      @media screen and (max-width: @mobileWidth) {
        text-align: center;
      }

      p.active {
        color: #434A5F;
        font-weight: 700;
      }
    }
  }

  .verification-container__title {
    color:  #284F83;
    text-align: center;
    font-size: 1.375rem;
    font-weight: 700;
  }

  .verification-container__description {
    color: #434A5F;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;

    strong {
      color: #1D9AFA;
      font-weight: 700;
    }
  }

  .verification-container__actions {
    display: flex;
    gap: 1rem;
    width: 100%;
    color: #8C9BA5;
  }

  .blue-link-label {
    text-decoration: none;
    color: #1D9AFA;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #57B4F2;
    }

    &:active {
      color: #2845C9;
    }
  }
}
