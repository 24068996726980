@import "../../../../../../_variables";

.AccountDetails {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
  @media screen and (max-width: @mobileWidth) {
    width: 95%;
    margin-left: 2.5%;
  }

  .AccountDetails__content {
    display: flex;
    gap: 1.5rem;
    min-width: 100%;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      margin-top: .5rem;
    }

    .AccountDetails__profile-container {
      display: flex;
      gap: 1.5rem;
      width: 100%;

      @media screen and (max-width: @mobileWidth) {
        flex-direction: column;
      }

      .AccountDetails__container {
        flex: 1;
      }
    }

    .AccountDetails__container {

      .name-photo-container {
        display: flex;
        gap: 1.5rem;
        justify-content: space-between;
        align-items: center;
      }

      &.noWidth {
        min-width: unset !important;
        @media screen and (max-width: @mobileWidth) {
          display: none;
        }
      }

      .gradient-avatar {
        position: relative;
        width: 96px;
        height: 96px;
        border-radius: 54px;
        background-clip: padding-box;
        margin-bottom: 1.5rem;
        background-blend-mode: multiply, normal;
        border: 4px solid #F0F3F6;
        box-sizing: border-box;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        background: linear-gradient(0deg, #D2D2D2, #D2D2D2) no-repeat center;
        @media screen and (max-width: @mobileWidth) {
          width: 65px;
          height: 65px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .gradient-avatar__upload-photo {
        cursor: pointer;
        transition: all .3s;

        img {
          position: absolute;

          &.edit {
            z-index: 50;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 14.87px;
            height: 14.87px;
          }
        }
      }

      .gradient-avatar__upload-photo:hover {
        filter: brightness(105%);
      }


      .input_container {
        margin-bottom: 20px;
        position: relative;
        width: 100%;

        img {
          width: 15px;
          position: absolute;
          right: 3%;
          bottom: 20%;
        }

        input, .css-2b097c-container {
          min-height: 40px;
          max-height: 40px;
          border: 1px solid #DFE3E6;
          box-shadow: 0 4px 40px rgb(196 196 196 / 25%);
          border-radius: 4px;
          color: @grey4;

          .css-1okebmr-indicatorSeparator {
            display: none;
          }
        }
      }

      .input-field {
        margin-bottom: 20px;

        img, svg {
          width: 1em;
          height: 1em;
          margin-left: 1em;
          margin-right: 1em;
        }

        .input-select__control {
          border-color: #DFE3E6;

          &:hover {
            border-color: @greyHover;
          }
        }

        .css-2b097c-container {
          margin-top: 10px;
          min-width: 100%;

          .flag-control-wrapper, .flag-option-wrapper {
            min-width: 100%;
            display: flex;
            align-items: center;
          }

          .css-7x2mut-menu {
            div > div {
              white-space: nowrap;
            }

            img, svg {
              margin-right: .5em;
              margin-left: 0;
            }
          }
        }

        &.dial_code-select {
          width: fit-content;
          margin-bottom: 0px;

          .input-select {
            width: 110px;
            min-width: unset;
            margin-top: 0px;

            .input-select__control {
              background: unset;
              border: unset;

              .flag-control-wrapper {
                > img, > svg {
                  margin-right: 0;
                }

                .input-select__value-container {
                  flex: 0.5;
                }

                .input-select__indicators {
                  .input-select__indicator-separator {
                    display: none;
                  }

                  .input-select__indicator {
                    padding: 0;

                    > svg {
                      margin-left: 5px;
                      margin-right: 0px;
                    }
                  }
                }
              }

            }
          }

        }
      }

      .label {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: @13px;
        color: @grey6;
      }

      .buttons_container {
        margin-top: 1rem;
        width: 100%;

        button.btn {
          p + img {
            padding-left: 7px;
          }
        }
      }

      .AccountDetails__details {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: .5rem;
        margin-top: .5rem;

        &:after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background: rgba(8, 46, 71, 0.1);
          bottom: 0;
        }

        .css-2b097c-container {
          min-width: 40%;

          .css-1hb7zxy-IndicatorsContainer {
            display: none;
          }
        }

        p {
          width: 50%;
          @media screen and (max-width: @mobileWidth) {
            font-size: @12px;
          }
        }

        .grey-text {
          display: flex;
          color: @grey4;
          align-items: center;
          text-align: right;
          justify-content: right;

          img {
            height: 10px;
            margin-left: 1rem;
            cursor: pointer;
          }
        }

        .commission-text {
          margin-right: 5px;
        }
      }
    }
  }
}

#profile-picture-input {
  display: none;
}

.NewEmailDialog {
  .MuiDialog-container {
    .MuiPaper-root {
      height: calc(100vh - 700px);
      width: 50vw;
      min-height: 300px;
      max-width: 1200px;
      @media screen and (max-width: @mobileWidth) {
        height: calc(60vh - 240px);
        width: unset;
      }
    }

  }

  .NewEmailDialog__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    .title {
      font-size: @18px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    input {
      max-width: 400px;
    }

    .buttons_container {
      display: flex;
      margin-top: auto;

      > button:first-child {
        margin-right: 20px;
      }
    }

    > span {
      margin-top: 5px;
    }

    .validation-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      > span {
        font-size: @14px;
        color: @grey6;
      }
    }
  }
}

.PhoneVerificationDialog {
  .MuiDialog-container {
    .MuiPaper-root {
      height: fit-content;
      min-height: 315px;
      width: 38vw;
      max-width: 1200px;
      border-radius: 16px;
      position: relative;
      padding-bottom: 18px;
      @media screen and (max-width: @mobileWidth) {
        width: unset;
      }
    }

  }

  .PhoneVerificationDialog__content {
    display: flex;
    flex-direction: column;

    > img {
      width: 170px;
      margin-bottom: 20px;
      margin-right: auto;
      margin-left: auto;
    }

    .close_icon {
      width: 16px;
      position: absolute;
      right: 25px;
      cursor: pointer;
    }

    .title {
      font-weight: 700;
      font-size: @18px;
      color: @blue2;
      margin-bottom: 8px;
    }

    > p {
      font-weight: 400;
      font-size: @12px;
      color: @grey4;
      margin-bottom: 20px;

      > strong {
        color: @grey6;
      }
    }

    .label {
      color: @grey6;
      font-size: @13px;
      font-weight: 700;
    }


    .input__wrapper {
      display: flex;
      margin-top: 5px;

      .digit_input {
        height: 36px;
        width: 32px;
        text-align: center;
        color: black;
        background: white;
        border: 1px solid @grey4;
        border-radius: 8px;
        outline: 0;

        &:focus {
          border: 1px solid @blue2;
        }
      }

      .digit_input:not(:last-child) {
        margin-right: 5px;
      }

      .btn_blue:disabled {
        background: @grey4;
        color: white;
      }
    }

    .info {
      margin-top: 10px;
      font-size: @11px;
      color: @grey4;
    }
  }
}
