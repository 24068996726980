@import "../../../../../../_variables";

.Verifications {
  display: flex;
  gap: 1.5rem;

  @media screen and (max-width: @mobileWidth) {
    flex-direction: column;
  }

  .AccountDetails__container {
    .noWidth {
      max-width: 25%;
      @media screen and (max-width: @mobileWidth) {
        display: none;
      }
    }
  }

  .Verifications__container {
    display: flex;
    width: 100%;
    gap: 1.5rem;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      padding-top: 1.5rem;
    }
  }

  .landlord-verification {
    flex: 1;

    .input_container {
      position: relative;
      margin-bottom: 30px;

      .pencilIconGrey {
        width: 15px;
        position: absolute;
        right: 3%;
        bottom: 20%;
      }

      input {
        min-height: 40px;
        max-height: 40px;
        border: 1px solid #DFE3E6;
        box-shadow: 0 4px 40px rgb(196 196 196 / 25%);
        border-radius: 4px;
        color: @grey4;
      }

      .input-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;

        label {
          font-style: normal;
          font-weight: 700;
          font-size: @14px;
          line-height: 22px;
          color: @grey6;
        }
      }

      &.verificationDocument {
        margin-bottom: .5rem;

        .verificationDocument__header {
          label {
            font-style: normal;
            font-weight: 700;
            font-size: @14px;
            line-height: 22px;
            color: @grey6;
          }
        }

        .verificationDocument__input {
          width: 100%;
          padding: 0.5rem;
          height: 56px;
          background: #FFFFFF;
          border: 1px dashed rgba(28, 55, 89, 0.1);
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          color: rgba(27, 44, 66, 0.72);
          font-size: @14px;
          font-weight: 400;

          img {
            width: 22px;
            margin-left: .5rem;
            margin-right: 1rem;
          }
        }
      }
    }

    p {
      font-style: normal;
      font-size: @12px;
      line-height: 22px;
      color: @grey5;
      margin-bottom: 1rem;
    }

    .buttons_container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .property-verification {
    flex: 1;

    h5 {
      margin-top: 0;
    }

    .list {
      overflow-y: scroll;
      max-height: 70vh;

      @media screen and (max-width: @mobileWidth) {
        max-height: none;
      }

      .property-card {
        display: flex;
        flex-direction: row;
        background-color: white;
        box-shadow: 0 4px 40px rgba(202, 202, 202, 0.25);
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        @media screen and (max-width: @mobileWidth) {
          max-width: 100%;
          border: 1px solid @grey3;
          flex-direction: column;
        }

        &:hover {
          background: @blueSuperSoft;
        }

        .property-section {
          display: flex;
          
          @media screen and (max-width: @mobileWidth) {
            width: 100%;
            justify-content: space-between;
          }

          .property-image {
            width: 100px;
            height: 100px;
            margin-right: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: @mobileWidth) {
              width: 0;
              height: 0;
              padding: 3rem 2.25rem;
            }
          }

          .property-info {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            min-width: 175px;
            max-width: 175px;

            @media screen and (max-width: @mobileWidth) {
              min-width: unset;
              max-width: unset;
              margin-top: 5px;
            }

            > span{
              margin-bottom: 6px;
            }

            > span {
              font-size: 12px;
              color: @grey6;
              margin-top: .5rem;

              > strong {
                font-size: 13px;
                font-weight: 600;
              }
            }
          }

          .VerificationTag__container {
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            right: 0;

            .VerificationTag {
              height: max-content;
              white-space: nowrap;
            }

            p {
              font-size: @10px;
              line-height: 20px;
              text-align: center;
              text-decoration-line: underline;
              color: @blue3;
              cursor: pointer;
            }

            .asked{
              text-decoration-line: unset;
              color: @grey6;
              cursor: unset;
            }
          }
        }
      }
    }
  }

  .VerificationTag {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 18px;
    max-width: max-content;
    padding: 0.2rem 0.6rem;
    font-weight: 700;
    font-size: 8px;

    img {
      margin-right: .3rem;
    }

    &.verified {
      background: rgba(45, 201, 40, 0.1);
      color: @green;
    }

    &.unverified {
      background: @grey2;
      color: @grey5;
    }
  }
}

#verificationDocument {
  display: none;
}