@import "../../_variables";

.VisitCard {
  position: relative;
  background-color: white;
  box-shadow: @box_shadow;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 185px;
  //margin-bottom: 10px;

  @media screen and (max-width: @mobileWidth) {
    flex-direction: column;
    height: unset;
  }

  .VisitCard-type_tag__mobile-container {
    display: none;
    position: absolute;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }

    .VisitCard-type_tag__mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      font-size: 10px;
      color: white;
      background-color: @purple;
      margin-left: 15px;
      border-radius: 100px;
      padding: 0 .5rem;
      margin-top: 10px;

      &.livecall {
        background-color: @blue3;
      }

      > span {
        display: block;
        width: 100%;
        text-align: center;
      }

      > img {
        margin-right: 5px;
      }
    }
  }

  .VisitCard__image {
    width: 120px;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px;
    padding: 10px;

    @media screen and (max-width: @mobileWidth) {
      width: 100%;
      min-height: 115px;
      margin-right: unset;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
    }

    .VisitCard__image__overlay {
      position: relative;
      top: -18px;
      right: 27px;
      height: 36px;
      width: 36px;
      border: 1px solid @grey3;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-shadow: @box_shadow;
    }

    .VisitCard__image-type_tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      border-radius: 12px;
      font-size: 10px;
      color: white;
      padding: 0 8px;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      &.livecall {
        background-color: @blue3;
      }

      &.onsite {
        background-color: @purple;
      }

      > span {
        display: block;
        width: 100%;
        text-align: center;
      }

      > img {
        margin-right: 5px;
      }
    }
  }

  .VisitCard__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 150px);
    padding: 20px;

    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
      width: 100%;
    }

    .VisitCard__property-info {
      width: 250px;

      @media screen and (max-width: @mobileWidth) {
        width: 100%;
      }

      > p {
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
      }

      .property-details {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        > span {
          display: flex;
          align-items: center;
          font-size: 10px;
          color: @grey4;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          > img {
            margin-right: 10px;
            width: 8px;
            max-height: 10px;
          }

          span {
            margin-right: 10px;
          }

          > strong {
            margin-left: 5px;
            color: @grey6;
          }
        }
      }
    }

    .VisitCard__tenant-stay-info__mobile {
      display: none;
      color: @grey6;
      margin-bottom: 1rem;

      @media screen and (max-width: @mobileWidth) {
        display: flex;
        width: 100%;
        flex-direction: column;
      }

      span {
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: @12px;
        line-height: 20px;

        img {
          margin-right: .5rem;
        }

        strong {
          font-weight: 700;
          margin-left: 1rem;
        }
      }
    }

    .VisitCard__tenant-info {
      display: flex;
      width: 220px;
      align-items: center;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      &.VisitCard__tenant-info__mobile {
        display: none;
        border-top: 1px solid #DFE3E6;
        border-bottom: 1px solid #DFE3E6;
        padding: 1rem;
        margin: 1rem 0;

        @media screen and (max-width: @mobileWidth) {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        .avatar-container{
          margin-right: unset;
        }
      }

      .avatar-container {
        margin-bottom: unset;
      }

      .tenant-details-visits {
        max-width: 300px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: @mobileWidth) {
         text-align: center;
        }

        > p {
          font-size: 12px;
          font-weight: 700;
        }

        > span {
          font-size: 10px;
          color: @grey4;

          > strong {
            font-weight: 400;
            color: @grey6;
          }
        }
        >span:last-child {
          font-weight: bold;
        }
      }
    }

    .VisitCard__dates {
      display: flex;
      flex-direction: column;
      max-width: 280px;

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      &.mobile {
        display: none;
        min-width: 100%;

        @media screen and (max-width: @mobileWidth) {
          display: flex;
        }

        p {
          border-bottom: 1px solid #DFE3E6;
          min-width: 100%;
          font-weight: 700;
          font-size: @13px;
          line-height: 18px;
          color: @grey6;
          padding-bottom: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            margin-left: 1rem;
          }
        }

        .dates-container {
          min-height: 0;
          margin: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 1s ease-in-out;

          &.hidden {
            display: none;
          }

          .date-box {
            white-space: nowrap;
            width: 50%;
            min-width: fit-content;

            strong{
              margin-left: 1rem;
            }
          }
        }
      }

      > p {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .dates-container {
        display: flex;
        flex-direction: column;
        min-height: 88px;

        .date-box {
          background-color: #f8f9fc;
          border-radius: 6px;
          height: 26px;
          width: 100%;
          padding: 0 10px;
          font-size: @11px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          > span {
            color: @grey4;
          }

          > strong {
            color: @grey6;
            font-weight: 400;
          }
        }
      }
    }

    .VisitCard__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;

      span, button{
        @media screen and (max-width: @mobileWidth) {
          margin: .5rem;
        }
      }

      .suggest-button {
        width: 118px;
        margin-top: 5px;
        min-height: 25px;
        justify-content: center;
        font-size: 10px !important;
      }

      .expires-in {
        display: flex;
        color: @grey4;
        font-size: @10px;
        font-weight: 500;

        > img {
          margin-right: 5px;
        }
      }

      > button.btn {
        font-size: 12px;
        width: 100%;

        > img {
          margin-right: 10px;
        }
      }

    }

    .reject-visit {
      color: @grey4;
      font-size: @10px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }

    .VisitCard__summary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        border: 1px solid @grey3;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        min-width: 200px;

        > span {
          font-size: @11px;
          width: fit-content;
        }
      }

      .btn-videocall {
        display: flex;
        align-items: center;
        padding: 7px 12px;
        color: @white;
        font-size: @14px;
        background: @green;
        border-radius: 6px;
        margin-top: 7px;
        margin-bottom: 10px;
        cursor: pointer;
        >img {
          width: 18px;
          height: 12px;
          margin-right: 11px;
        }

        &.disabled {
          cursor: unset;
          background-color:  @grey4;
        }
      }

      .btn-share {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: @14px;
        color: @blue2;
        >img {
          margin-right: 10px;
        }
        &:hover {
          box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .tenant_more_info {
      height: 100px;
      width: 120px;
      margin-right: 1rem;
      cursor: pointer;
      @media screen and (max-width: @mobileWidth) {
        width: unset;
        margin-top: 1rem;
        margin-right: unset;
      }


      .title {
        font-size: @12px;
        font-weight: 700;
        @media screen and (max-width: @mobileWidth) {
          text-align: center;
        }
      }

      .text {
        overflow-y: scroll;
        height: 95px;
        display: flex;
        font-size: @10px;
        color: #8C9BA5;
        cursor: pointer;
        @media screen and (max-width: @mobileWidth) {
          height: 50px;
        }

        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }

      @media screen and (max-width: @mobileWidth) {
        display: none;
      }

      &.tenant_more_info__mobile {
        @media screen and (max-width: @mobileWidth) {
          display: flex!important;
          flex-direction: column;
        }
      }
    }
  }
}

.VisitCard__share-dialog {
  .MuiDialog-container .MuiPaper-root {
    max-width: 900px;
    padding: 42px;
    height: fit-content;
    @media screen and (max-width: @mobileWidth) {
      height: unset;
      padding: 22px;
    }

    .VisitCard__share-dialog-content {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        font-size: @18px;
        font-weight: 700;
        margin-bottom: 20px;
        >img {
          cursor: pointer;
          margin-left: auto;
          width: 17px;
          &:hover {
            background-color: @grey3;
            border-radius: 50%;
          }
        }
      }
      .info-box {
        display: flex;
        align-items: center;
        padding: 8px 12px 8px 20px;
        border: 1px solid @blue3;
        border-radius: 8px;
        border-left: 5px solid @blue3;
        margin-bottom: 40px;
        color: @grey6;
        font-size: @12px;
        >img {
          margin-right: 15px;
          height: 20px;
          width: 20px;
        }
      }

      .visit-section {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        .property-photo {
          width: 144px;
          height: 166px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 40px;
          border-radius: 8px;
        }
        .visit-info {
          display: flex;
          flex-direction: column;

          .property-name {
            font-size: @16px;
            font-weight: 700;
            color: @grey6;
            margin-bottom: 12px;
          }
          >span {
            font-size: @12px;
            color: @grey4;
            margin-bottom: 5px;
            >strong {
              font-weight: 500;
              margin-right: 2px;
              color: @grey6;
            }
          }
        }
        >button {
          margin-left: auto;
          font-size: @13px;
          color: @grey4;
          >img {
            margin-right: 10px;
            filter: brightness(160%) saturate(27%);
            width: 14px;
          }
        }
      }
      @media screen and (max-width: @mobileWidth) {
        .visit-section {
          flex-direction: column;
          align-items: flex-start;
          .property-photo {
            margin-right: 0;
            margin-bottom: 20px;
          }
          >button {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }

      .share-section {
        display: flex;
        flex-direction: column;
        >div:first-child {
          margin-bottom: 16px;
        }
        .share-buttons {
          display: flex;
          >div:first-child {
            width: 60%;
          }
          >div:last-child & div:not(:only-child) {
            width: 40%;
          }
          >div {
            display: flex;
            align-items: center;
            >img {
              margin-right: 10px;
            }
            >strong {
              font-size: @13px;
              color: @grey6;
              width: 35%;
            }
            >div {
              display: flex;
              align-items: center;
              overflow: auto;
              >input {
                font-size: @13px;
                width: 310px;
                margin-right: 5px;
              }
              >button{
                margin-right: 5px;
              }
            }
            .btn_white:not(:last-child) {
                margin-right: 5px;
            }
            .btn_white {
              font-size: @13px;
              color: @grey6;
              >img {
                margin-right: 10px;
              }
            }
          }
        }
      }

      @media screen and (max-width: @mobileWidth) {
        .share-section {
          .share-buttons {
            flex-direction: column;
            align-items: unset;
            >div:first-child {
              width: 100%;
            }
            >div {
              margin-bottom: 20px;
              >div {
                width: 65%;
                >input {
                  width: 80%;
                }
              }
              .btn_white {
                min-width: fit-content;
                margin-right: 15px;
              }
            }
          }
        }
      }

    }
  }
}

.VisitCard__schedule-dialog {
  .MuiDialog-container .MuiPaper-root {
    max-width: 900px;
    padding: 30px 30px 0 30px;
    @media screen and (max-width: @mobileWidth) {
      height: unset;
    }

    .schedule-dialog-container {
      .title {
        margin: auto;
        text-align: center;
        max-width: 450px;
        font-size: 14px;
        font-weight: 700;
        color: @grey6;
      }

      > span {
        text-align: center;
        font-size: 11px;
        color: @grey4;
        display: block;
        padding: 15px;
        @media screen and (max-width: @mobileWidth) {
          padding: unset;
          margin: 1rem 0;
        }

        >a {
          font-size: 11px;
        }
      }

      .schedule-dialog-body {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 20px;
        @media screen and (max-width: @mobileWidth) {
          flex-direction: column;
        }

        .left {
          flex: 3;
          > p {
            font-weight: 700;
            color: @grey6;
            margin-bottom: 10px;
          }

          .day-picker-container {
            .date-box {
              background-color: #f8f9fc;
              border-radius: 8px;
              height: 40px;
              width: 100%;
              padding: 0 15px;
              font-size: @12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              @media screen and (max-width: @mobileWidth) {
                min-height: fit-content;
                height: fit-content;
                flex-direction: column;
                padding: unset;
              }


              &:hover {
                background-color: @grey2;
              }

              &.active {
                background-color: #dbf0ff;
              }

              &:not(:last-child) {
                margin-bottom: 10px;
              }

              > span {
                color: @grey4;
              }

              > strong {
                color: @grey6;
                font-weight: 400;
              }
            }
          }
        }

        .div {
          flex: 0.1;
          border-right: 1px dashed @grey3;
          height: calc(100vh - 315px);
          margin: 0 20px;
        }

        .right {
          flex: 5;
          .select-date-time-container {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: calc(100% - 30px);
            margin: 10px 15px 0 15px;

            > p {
              font-weight: 700;
              color: @grey6;
              margin: 10px 0;
            }
            >div:last-child {
              display: flex;
              font-size: @12px;
              color: @grey5;
              font-weight: 400;
              margin-top: 20px;
              >img {
                margin-right: 9px;
              }
            }

            .date-box-title {
              width: 100%;
              height: 32px;
              background-color: @grey1;
              color: @grey4;
              font-size: @12px;
              font-weight: 700;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              @media screen and (max-width: @mobileWidth) {
                font-weight: normal;
              }
            }

            .div-horiz {
              border-top: 1px dashed @grey3;
              width: 100%;
              margin: 20px 0;
            }

            .TimesPickers {
              max-width: 200px;
            }
          }
        }
      }

      .schedule-dialog-buttons {
        display: flex;
        justify-content: center;
        @media screen and (max-width: @mobileWidth) {
          margin-bottom: 1rem;
        }

        button {
          width: 300px;
          justify-content: center;
          height: 22px;
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}



.DateTooltip {
  margin-bottom: 0px !important;
}
