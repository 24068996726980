@import "../../_variables";

.RichTextEditor {
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  .btnContainer {
    margin-top: 1rem;
    display: inline-flex;

    > .btn {
      display: flex;
      justify-content: center;
      margin-right: 1rem;
      max-width: 2rem;
      background-color: @blue !important;
      min-width: unset;

      img {
        margin-right: unset;
      }
    }
  }

  .editorContainer {
    border: @grey3 solid 1px;
    width: 100%;
    padding: 0.5rem;
    margin-top: .5rem;
    box-shadow: @box_shadow;
    border-radius: 15px;
    overflow-y: scroll;
    margin-bottom: 1rem;
    flex: 1;
    min-height: 300px;
  }
}



