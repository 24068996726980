@import '../../../../../../_variables';

.ChooseDatesComponent {
  margin-top: 0;
  @media screen and (max-width: @mobileWidth) {
    //padding-bottom: 10rem;
  }

  &:not(.type-summary) {
    margin-bottom: 20px;
  }

  .ChooseDatesComponent__dates {
    background-color: @white;
    border-radius: 12px;
    display: inline-flex;
    justify-content: space-between;
    margin: 25px 0 15px 0;
    width: 100%;
    @media screen and (max-width: @mobileWidth) {
      flex-direction: column;
    }

    .ChooseDatesComponent__dates-date {
      align-items: center;
      border: 1px solid @grey3;
      border-radius: 10px;
      cursor: pointer;
      display: inline-flex;
      margin: 15px;
      padding: 2px 15px;
      height: 72px;
      width: 33%;

      >*:not(:last-child) {
        margin-right: 10px;
      }
      @media screen and (max-width: @mobileWidth) {
        width: unset;
      }

      &.disabled {
        cursor: default;
        opacity: 0.4;
      }

      &:not(:first-child) {
        margin-left: 0;
        @media screen and (max-width: @mobileWidth) {
          margin-left: 15px;
          margin-top: 0;
        }
      }

      > div {
        display: flex;
        flex-direction: column;

        label {
          font-size: @14px;
          font-weight: 700;
          color: @grey6;
          cursor: pointer;
        }

        > :first-child {
          color: @blue1;
          margin-bottom: 2px;
        }
      }
    }
  }
}

#choose-dates-times-dialog {


  .MuiPaper-root {
    max-height: 90vh!important;
    height: unset;
    border-radius: 16px;
    width: unset;
    @media screen and (max-width: @mobileWidth) {
      margin: 0;
    }
  }

  .ChooseDatesTimesDialog {
    background-color: @white;
    border-radius: 16px;
    height: 650px;
    overflow: hidden;
    padding: 25px 0;
    width: 360px;
    @media screen and (max-width: @mobileWidth) {
      width: 100%;
    }

    >p{
      color:@grey4;
      @media screen and (max-width: @mobileWidth) {
        margin-bottom: 8px;
      }
      &.caption {
        text-align: center;
      }
    }

    >*{
      margin-bottom: 20px;
      padding: 0 25px;
    }

    .ChooseDatesTimesDialog__chooseDay {
      display: inline-flex;
      justify-content: space-around;
      margin-top: 10px;
      width: 100%;

      .ChooseDatesTimesDialog__chooseDay-day {
        align-items: center;
        display: flex;
        flex-direction: column;

        >p{
          color:@grey4;
        }

        > div {
          border-radius: 50%;
          cursor: pointer;
          height: 36px;
          width: 36px;
          color:@grey5;

          &.active {
            background-color: @blue2;
            color: @white;
          }
        }

        &.disabled{
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }

    .ChooseDatesTimesDialog__chooseTime{
      display: flex;
      flex-direction: column;
      max-height: 375px;
      height: calc(90vh - 275px);
      overflow-y: scroll;

      >*:not(:last-child) {
        margin-bottom: 10px;
      }

      .ChooseDatesTimesDialog__chooseTime-slot{
        border: 2px solid @grey1;
        border-radius: 8px;
        cursor: pointer;
        min-height: 97px;
        width: 100%;

        &.active{
          background-color: @blue2;
          color:@white;
        }

        &.disabled {
          cursor: default;
          background-color: @grey1;
          opacity: 0.4;
        }
      }
    }
  }

  .ChooseDatesTimesDialog__buttons {
    display: inline-flex;
    justify-content: space-between;
    height: 45px;
    width: 100%;
    margin-top: 43px;
    @media screen and (max-width: @mobileWidth) {
      display: flex;
    }

    > button {
      width: 150px;
      @media screen and (max-width: @mobileWidth) {
        width: unset;
      }
    }

    .btn {
      border-radius: 40px;
      padding: 8px 20px;
      border: solid #2f9bf8 1.5px;
      font-weight: normal;
      color: #5b6872;
      display: inline-block;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;

      &.btn-secondary {
        background-color: #f6fcff;
        color: #2f9bf8
      }

      &.btn-special {
        background-color: #2f9bf8;
        color: white;
      }

      &:hover {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.flexInlineCenter{
  display: inline-flex;
  justify-content: center;
  align-items: center;

}