@import "../../../../../../../../../../../../../_variables";

.AddressAutocomplete {

  .Select {
    &.input_error {
      border: none;
      background-color: transparent;
    }
  }
}
