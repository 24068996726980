@import "../../../../../../../../../_variables";

.Resume{

  .scrollable{
    max-height: 90%;
  }

  .Resume__header{
    border-bottom: @dashed_bottom;
    padding-bottom: 15px;
    margin-bottom: 40px;
    padding-right: 100px;
  }
}
