@import "../../../../../../_variables";

.AccountDetails__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.noWidth {
    min-width: unset !important;
    @media screen and (max-width: @mobileWidth) {
      display: none;
    }

    .endSession {
      cursor: pointer;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      text-decoration: none;
      align-items: center;
      font-size: 0.875rem;
      color: #FA392E;
      font-weight: 500;
      background: white;
      border-radius: 0.5rem;

      &:hover{
        background-color: @redBackGround;
      }

      @media screen and (max-width: 1024px) {
        p {
          display: none;
        }
      }
    }
  }
}
