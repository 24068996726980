@import "../../_variables";

.ProgressBarComponent{
  display: flex;
  flex-direction: column;

  label{
    width: 100%;
    text-align: center;
    color:@grey4;
  }
}