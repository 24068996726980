@import "../../../../../../../../../../../../../_variables";

.UnavailabilityPeriodTable {
  height: fit-content;
  padding: 16px 18px;
  display: flex;
  flex-direction: column;

  p {
    font-size: @14px;
  }

  .UnavailabilityPeriodTable__selectors {
    display: flex;
    flex-direction: column;
    width: 100%;


    .UnavailabilityPeriodTable__selector {
      display: inline-flex;
      align-items: flex-end;
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }

      p {
        font-weight: bold;
        margin-bottom: 5px;
      }

      >:not(:last-child) {
        margin-right: 10px;
      }


      .UnavailabilityPeriodTable__selector__container--datepicker {
        flex: 1;
      }

      .UnavailabilityPeriodTable__selector__container--reactselect {
        &.input_field {
          border: none;
        }

        .value-display {
          font-size: 11px;
          line-height: 36px;
          white-space: nowrap;
        }

        > .Select--is-disabled {
          font-size: 11px;

          & > .Select__control.Select__control--is-disabled > .Select__indicators {
            display: none;
          }
        }

        flex: 1.5;
      }

      .btn {
        width:90px;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .error_message {
    width: 100%;
    margin-top: 16px;
  }

  .success_message {
    margin-top: 16px;
  }

  .UnavailabilityPeriodTable__add-unavailability{
    display: flex;
    margin-top: 20px;
    gap: 10px;

    &:hover{
      cursor: pointer;
      opacity: 0.7;
    }
  }


}
