@import "../../_variables";

.CountryAndPhoneSelect {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  column-gap: 15px;

  >div{
    width: 48.5%;
  }

  .input_error {
    border: 1px solid @redError !important;

    input {
      border-radius: 3px;
    }
  }

  .css-2b097c-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CountrySelect__value-container {
    display: inline-flex;
    align-items: center;
  }

  .CountrySelect__placeholder {
    min-width: 120px;
    margin: 0px;
  }

  .CountrySelect__control {
    height: 50px;
  }

  .input_field {
    box-shadow: 0 4px 40px rgba(196, 196, 196, 0.25);
  }

  .PhoneInput {
    padding-left: 0px;
    height: 36px;

    &.input_field{
      border: 1.9px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0;
      padding-top: 0;

      input {
        background-color: transparent;
      }
    }

    &.input_error {

      input {
        background: @redBackGround;
      }

    }

    .PhoneInputCountry {
      padding-left: 0px;
      width: 35px;
      height: 36px;
      display: none;

      .PhoneInputCountrySelect {
        position: relative;

      }

      .PhoneInputCountryIcon {
        display: none;
      }

      .PhoneCountry {
        position: absolute;
      }

    }

    .PhoneInputInput {
      border-radius: 4px;
      width: 98%;
      left: 0px;
      padding-left: 10px;
      position: relative;
      margin-bottom: 0px;
      height: 36px;
      border: none;
    }

  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding: 0px;
    height: 36px;
    border: 1.9px solid rgba(0, 0, 0, 0.1);

    .ReactFlagsSelect-module_selectBtn__19wW7 {
      height: 36px;
      overflow: hidden;
      padding: 0;
      border: none;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7:after {
      margin-right: 5px;
    }

    .ReactFlagsSelect-module_label__27pw9 {
      font-size: 0.9rem;
    }

    .ReactFlagsSelect-module_selectOptions__3LNBJ {
      min-width: 230px;
      width: 70%;
      overflow-x: hidden;
    }

  }

  .ReactFlagsSelect-module_filterBox__3m8EU {
    input {
      border: none;
      border-radius: 3px;
      width: 200px;
      height: 30px;
      margin-bottom: 0px;
    }
  }

  .error_message{
    text-align: left;
  }
}
