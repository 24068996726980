@import "../../_variables";

.PopupDialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, .55);
  overflow-x: hidden;
  overflow-y: auto;

  &:not(.open) {
    pointer-events: none;
  }

  > div {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;

    > div {
      display: table-cell;
      vertical-align: middle;
      padding: 25px 0;

      > * {
        pointer-events: all;
      }
    }
  }

  .popup_dialog_modal {
    background: white;
    width: 85%;
    max-width: 500px;
    border-radius: 0.5rem;
    margin: auto;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    border: 1px solid whitesmoke;

    .dialog-content {
      padding: 20px 30px;

      >p{
        min-height: 30px;
      }

      &.error, &.success, &.progress {
        svg {
          width: 50px;
          height: 50px;
          margin-bottom: 5px;
        }

        h3 {
          font-size: 25px;
        }

        > div:first-child {
          text-align: center;
        }

        p {
          text-align: center;
        }

        &.progress {
          p {
            margin-top: 10px;
          }

          svg {
            margin-top: 10px;
          }
        }
      }

      &.error > div {
        color: #e60000;
      }

      &.success > div {
        color: #4ead79;
      }

      .svg-inline--fa {
        width: 80px;
        height: 80px;
      }
      .action-dialog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >img {
          margin-bottom: 15px;
        }
      }
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
      @media screen and (max-width: @mobileWidth) {
        font-size: @12px;
      }
    }

    .actions {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      margin-top: 20px;

      @media screen and (max-width: @mobileWidth) {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      button {
        margin-left: 10px;

        @media screen and (max-width: @mobileWidth) {
          margin-left: unset;
          width: 100%;
        }
      }
    }
  }
}
