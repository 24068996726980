@import "../../../../../../../../_variables";

.ChooseOnePhoto {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 215px;
  height: 215px;
  border-radius:5px;

    .ChooseOnePhoto__dropzone-input{
      display: none;
    }


    .ChooseOnePhoto__change-image {
      width: 213px;
      height: 213px;
      display: flex;
      flex-direction:column;
      padding:20px;
      justify-content: space-between;
      align-items: center;
      background: white;
      border-radius:5px;
      font-weight: 600;
      color: @grey5;
      border: 1px dashed @blue;

      p {
        margin-left: 5px;
      }

      .ChooseOnePhoto__change-image__title{
        font-size:@16px;
        margin-bottom:10px;
        font-weight:700;
      }

      .ChooseOnePhoto__change-image__placeholder{
        flex:1;
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;



        .ChooseOnePhoto__change-image__placeholder--icon{
          display: inline-flex;
          flex: 1;

          > div {
            cursor: unset;
          }
        }

        &:hover{
          //img{
          //  filter:invert(0.1);
          //}
        }

        .ChooseOnePhoto__change-image__info{
          label{
            font-size:0.9rem;
            font-weight:700;
          }

          p{
            font-weight:400;
            font-size:0.82rem;
            margin-top: 10px;
          }
        }
      }
    }

  .icon {
    align-items: center;
    background: #f0f3f6;
    border-radius: 50%;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    width: 50px;

    &:hover {
      cursor: default;
    }

    .drag_and_drop {
      width: 35px;
      height: 35px;
    }
  }


  /* Images */

  .file-img {
    width: 80px;;
    height: 80px;;
    display: block;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 5px 1px #7272722b;
    border-radius: 3px;
  }

  &.ChooseOnePhoto__blue{
    .ChooseOnePhoto__change-image {
      background: @blueSoft;
    }

    .file-img{
      width: 70px;
      height: 70px;
      margin-left: -10px;
    }
  }

  &.error_container{

    .ChooseOnePhoto__change-image {
      border: 1px dashed @redBorder;
      background: @redBackGround;

    }

  }

}
