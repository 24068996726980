@import "../../../../../../../../_variables";

.RadioButtons {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  &.info {
    > .MuiFormGroup-root {
      display: flex;
      flex-direction: column;

      .info {
        .MuiFormControlLabel-label {

          width: 50px;
        }
      }

      .longLabel{
        .MuiFormControlLabel-label {
          width: 160px;
        }
      }
    }
  }

  > .MuiFormGroup-root {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    >div{
      &.margin_top{
        margin-top: 10px;
      }
    }
    .single {
      width: 100%;
    }

    .double {
      width: 47%;
    }

    .triple {
      width: 30%;
    }

    .inline{
      width: 100%;
      display: inline-flex;

      .PriceComponent{
        flex:0.7;
      }
    }

    .right{
      display: inline-flex;

      .MuiFormControlLabel-root{
        width: 100%;
        margin-right: 4px;
      }

      .MuiButtonBase-root{
        order:2;
      }

      .MuiTypography-root{
        flex:1
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div {
        display: inline-flex;
        height: 21px;
      }

    }

    .margin_bottom {
      margin-bottom: 15px;
    }

    .MuiFormControlLabel-label {
      font-size: @14px;
    }

  }

  .MuiButtonBase-root {
    padding-left: 0px;
  }

  .RadioButton_info {
    display: inline-flex;
    margin-left: 20px;

    svg {
      margin-top: 5px;
      width: 12px;
      min-width: 12px;
    }

    p {
      margin-left: 5px;
      margin-top:5px;
      color: @grey;
      font-size: 0.6rem;
      line-height: 1.5;
    }
  }

}

