@import "../../../../../../../../../_variables";

.PropertyDetails{
  margin-left: 5px;
  font-size:0.9rem;

  h4{
    font-size: 1rem;
  }

}