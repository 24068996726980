@import "../../../../../../../../_variables";


.PropertyType{
  column-gap: 30px;
  width: 100%;
  max-width: calc(100vw - 120px);
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  .PropertyType__container{
    flex: 1;
    height: 314px;
    margin-bottom: var(--gap);
    padding: 20px 60px;

    .PropertyType__content {
      height: 58%;
      display: flex;
      flex-direction: column;

      h3{
        font-size: 1.0rem;
        color:@blue;
        line-height:24px;
        line-height:120%;
        align:left;
      }


      >*{
        margin-bottom:15px;
      }

      >.Property__Select{
        flex:1;
        display: flex;
        align-items: center;

        .Select{
          width: 100%;
        }
      }
    }

    >*{
      margin-bottom:15px;
    }

    p{
      font-size:0.85rem;
    }


    button{
      font-weight:700;
      font-size:0.85rem;
      padding-right: 15px;

      p{
        margin-left:3px;
      }
    }
  }
}
