@import "../../../../../../../../../../../_variables";

.RoomAmenities {


  .scrollable {
    min-width: 700px;
    max-height: 880px;

    > div {
      &:not(.RoomDetailsHeader) {
        width: 90%;
      }
    }
  }

  form {
    .commodities_checkboxes {
      width: 80%;
    }

    .FieldSetCheckBoxes {
      //setting the FieldSetCheckBoxes labels to the left

      label {
        &.right {
          padding-left: 0px;
          margin-top: 20px;

          .checkbox__label {
            margin-left: 0px;
          }
        }
      }
    }
  }
}