@import "../../../../../../../../../../../../../_variables";

.RentByMonth{
  border: none;
  margin-bottom: 30px;

  .RentByMonth__months{
    border: none;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 0;

    .RentByMonth__months-month{
      align-items: center;
      display: inline-flex;
      justify-content: space-between;
      margin-top: 8px;
      width: 45%;

      label{
        font-weight: 700;
        min-width: 50px;
        size:@14px;
        text-align: center;
      }

      input{
        width: 100%;
        text-align: center;
      }
    }
  }

  .RentByMonth__info{
    display: inline-flex;
    align-items: center;

    svg {
      color:@grey6;
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }
}
