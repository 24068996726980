.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  &__range {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: white;

    .btn {
      font-weight: 400;
      width: 40px;
      background: white;

      &:hover {
        color: #1D9AFA;
        font-weight: 700;
      }
    }
  
    .selected {
      color: white;
      font-weight: 700;
      background-color: #1D9AFA;
      box-shadow: 0px 0px 10px rgba(24, 160, 251, 0.3);

      &:hover {
        color: white;
      }
    }
  }

  .dots {
    pointer-events: none;
    cursor: default;
  }
}
